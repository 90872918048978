%ico_default {
  display: inline-block;
  @include hide_text;
}

.ico_search {
  @include retina-sprite($sp-button-btn-gnb-search-nor-group);
}

.ico_new {
  @extend %ico_default;
  @include retina-sprite($sp-icon-ic-thumb-badge-new-group);
}

.ico_update {
  @extend %ico_default;
  @include retina-sprite($sp-icon-ic-thumb-badge-update-group);
}

.ico_chart_new {
  @extend %ico_default;
  @include retina-sprite($sp-icon-ic-main-new-group);
}

.ico_main_new {
  @extend %ico_default;
  @include retina-sprite($sp-icon-icon-main-new-group);
}

.ico_main_update {
  @extend %ico_default;
  @include retina-sprite($sp-icon-icon-main-up-group);
}
