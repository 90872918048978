
          @import '@/assets/scss/common/color.scss';
        

svg {
  &:hover {
    path {
      fill: currentColor;
    }
  }
}
