
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.music-list {
  &__simplebar {
    @include respond-to(mediaquery-large) {
      overflow: auto;
    }

    overflow: hidden;
  }

  &__track {
    position: relative;
    margin-right: 13px;
  }
}
