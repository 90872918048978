
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.player-action {
  position: relative;

  &__action {
    width: 100%;
    height: 40px;
  }

  &__button {
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      color: white;
    }
  }

  &__more {
    bottom: 0;
    left: 90px;
  }
}
