
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.track_info {
  &__empty {
    @include ellipsis1;

    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #989898;
  }
}
