/*
 * base.status.scss
 * page 의 상황에 따라 body 에 아래 class 가 추가됨
*/

// Popup Open 일 때
.openPopup {
  @include show_dimd;
}

// 미니 플레이어가 활성화 되었을 때
.miniplayer_on {
  #wrap {
    padding-bottom: 80px;
  }
}

// 전체 플레이어가 활성화 되었을 때
.show_full {
  overflow: hidden;

  .snack_bar {
    bottom: 80px;
  }
}
