%btn_bg_error_g_style {
  box-sizing: border-box;
  display: inline-block;
  min-width: 137px;
  height: 62px;
  padding: 21px 55px;
  font-size: 18px;
  color: #fff !important;
  text-align: center;
  background-color: #7f7f7f;
  border-radius: 5px;

  &:hover,
  &.hover {
    background-color: #505050;
  }

  &:active,
  &.active {
    background-color: #505050;
  }

  &:disabled,
  &.disabled {
    background-color: #2d2d2d;
  }
}

@mixin btn_style_bg_blue($size: 'S') {
  min-width: if($size == 'S', 85px, 115px);
  height: if($size == 'S', 36px, 39px);
  padding: 0 15px;
  font-size: if($size == 'S', 14px, 18px);
  line-height: if($size == 'S', 36px, 39px);
  color: #fff;
  text-align: center;
  background-color: #3f3fff;
  border-radius: 5px;

  &:hover,
  &.hover {
    background-color: #2f2fae;
  }

  &:active,
  &.active {
    background-color: #1a1a86;
  }

  &:disabled,
  &.disabled {
    opacity: 0.2;
  }
}

@mixin btn_style_bg_white($size: 'S') {
  min-width: if($size == 'S', 85px, 115px);
  height: if($size == 'S', 36px, 39px);
  padding: 0 15px;
  font-size: if($size == 'S', 14px, 18px);
  line-height: if($size == 'S', 36px, 39px);
  color: #484848;
  text-align: center;
  border: 1px solid #bebebe;
  border-radius: 5px;

  &:hover,
  &.hover,
  &:active,
  &.active {
    color: #3f3ffe;
    border-color: #3f3ffe;
  }

  // foucs 중복 적용시 disabled케이스가 우선되어야함
  &:disabled,
  &.disabled {
    color: #484848 !important;
    cursor: default !important;
    border-color: #bebebe !important;
    opacity: 0.3 !important;
  }
}

.btn_delete_input {
  @include retina-sprite($sp-button-btn-gnb-search-close-nor-group);
  @include hide_text;
}

.btn_bg_blue_s {
  @include btn_style_bg_blue(S);
}

.btn_bg_white_s {
  @include btn_style_bg_white(S);
}

.btn_bg_error_g {
  @extend %btn_bg_error_g_style;
}

.btn_bg_error_b {
  @extend %btn_bg_error_g_style;

  background-color: #3f3fff;

  &:hover,
  &.hover {
    background-color: #2f2fae;
  }

  &:active,
  &.active {
    background-color: #2f2fae;
  }

  &:disabled,
  &.disabled {
    background-color: #1a1a86;
  }
}

.btn_close_popup {
  @include retina-sprite($sp-button-btn-popup-close-nor-group);

  position: absolute;
  top: -30px;
  right: 0;
}

// 탭 버튼 스타일 - 트랙 리스트 (실시간 차트, 최신 음악)
@mixin btn_style_tab() {
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  vertical-align: top;
  border: 1px solid rgba($color: #000, $alpha: 0.2); // 제플린 가이드 두께 0.5px
  border-radius: calc(32px / 2);

  &:hover,
  &.hover {
    color: $key_color;
    border-color: $key_color;
  }

  &:active,
  &.active {
    @extend %btn_style_tab_active;
  }
  @at-root {
    .on & {
      @extend %btn_style_tab_active;
    }
  }

  // TODO: disabled의 경우 스타일 가이드 없음
  // &:disabled, &.disabled {
  //     opacity: 0.2;
  // }
}

%btn_style_tab_active {
  color: #fff;
  background-color: $key_color;
  border-color: $key_color;

  &:hover {
    color: #fff;
  }
}

.btn_tab_tracklist {
  @include btn_style_tab;

  display: inline-flex;
  align-items: center;
}

// TODO: 탭 버튼 스타일 - 컨텐츠 (정보관리, 고객지원, 이용권)
.btn_tab_content {
  @include btn_style_tab;
}

.btn_tab_normal {
  @include btn_style_tab;

  font-size: 16px;
  border: 0;

  .ico_like {
    display: inline-block;
    @include retina-sprite($sp-button-btn-tab-like-nor-group);
    @include hide_text;
  }

  &:hover {
    .ico_like {
      @include retina-sprite($sp-button-btn-tab-like-hover-group);
    }
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      color: #333;

      .ico_like {
        @include retina-sprite($sp-button-btn-tab-like-nor-group);
      }
    }

    &:active {
      color: #333;
      background: none;
      border-color: #fff;
    }
  }
}

// 차트 더보기 버튼
@mixin btn_style_more() {
  display: inline-flex;
  align-items: center;
  height: 36px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 37px;
  color: #000;
  border: 1px solid #ebebeb;
  border-radius: 18px;

  &:active,
  &.active {
    color: rgba($color: #000, $alpha: 0.3);
  }

  &:hover,
  &.hover {
    color: $key_color;
    border-color: $key_color;

    &::after {
      @include retina-sprite($sp-icon-btn-chart-arrow-open-over-group);
    }
  }

  &::after {
    @include retina-sprite($sp-icon-btn-chart-arrow-open-nor-group);

    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
    content: '';
  }

  &.unfolded {
    &::after {
      @include retina-sprite($sp-icon-btn-chart-arrow-close-nor-group);

      margin-top: -3px;
    }

    &:hover,
    &.hover {
      &::after {
        @include retina-sprite($sp-icon-btn-chart-arrow-close-over-group);
      }
    }
  }
}

.btn_list_more {
  @include btn_style_more;
}

.btn_more_area {
  margin-top: 20px;
  text-align: center;
}
