
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    min-height: 460px;
    text-align: center;

    @include respond-to(mediaquery-large) {
      padding-bottom: 90px;
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    color: #fff;
  }

  &__name {
    @include ellipsis1;

    display: block;
    align-items: center;
    max-width: 500px;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    color: #989898;
  }

  &__thumbnail {
    @include respond-to(mediaquery-large) {
      width: 360px;
      height: 360px;
    }

    position: relative;
    width: 240px;
    height: 240px;
    margin-bottom: 25px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    outline: none;

    &--icon {
      position: absolute;
      top: 25px;
      left: 25px;
    }
  }

  &__lyrics {
    width: 100%;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
  }

  &--highlight {
    color: white;
  }

  &--small {
    font-size: 10px;
    line-height: 16px;
  }
}
