footer {
  position: relative;
  padding-top: 32px;
  margin-top: 60px;
  clear: both;
  background: #fff;
  border-top: 1px solid #e0e1e5;

  .footer_inner {
    max-width: 1440px;
    padding: 0;
    margin: 0 auto;
  }

  .flo_fnb {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    font-size: 13px;

    ul {
      width: 191px;
    }

    li {
      padding: 8px 0;

      .icon {
        padding-bottom: 2px;
      }
    }

    a,
    div,
    button {
      color: #8e8e93;
    }

    .footer_title {
      font-weight: bold;

      a {
        color: #000;

        &:hover {
          color: $key_color;
        }
      }
    }
  }

  .policy_area {
    ul {
      font-size: 0;
    }

    li {
      position: relative;
      display: inline-block;
      padding: 0 8px;

      &::before {
        position: absolute;
        top: 1px;
        left: 0;
        display: block;
        width: 1px;
        height: 12px;
        content: '';
        background: #d8d8d8;
      }

      a {
        font-size: 12px;
        color: #8e8e93;

        em {
          font-style: normal;
          font-weight: bold;
          color: #8e8e93;

          &:hover {
            color: $key_color;
          }
        }
      }

      &:first-child {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  .inquiry_area {
    li {
      a {
        &:hover {
          path {
            fill: $key_color;
          }
        }
      }

      svg {
        padding-left: 2px;
      }
    }
  }

  .social_area {
    position: absolute;
    top: 40px;
    right: 0;

    ul {
      font-size: 0;
    }

    li {
      position: relative;
      display: inline-block;
      padding-left: 16px;

      &:nth-child(2) {
        padding-left: 14px;
      }

      a {
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }

      .btn-facebook {
        background: url('~@/assets/img/icon_footer_facebook.png') no-repeat 0 0;
        background-size: 100%;
      }

      .btn-instagram {
        background: url('~@/assets/img/icon_footer_instagram.png') no-repeat 0 0;
        background-size: 100%;
      }

      .btn-youtube {
        background: url('~@/assets/img/icon_footer_youtube.png') no-repeat 0 0;
        background-size: 100%;
      }

      .btn-twitter {
        background: url('~@/assets/img/icon_footer_twitter.png') no-repeat 0 0;
        background-size: 100%;
      }
    }
  }

  address {
    padding-top: 16px;
    overflow: hidden;

    .inner {
      margin-left: -8px;
    }

    span {
      position: relative;
      display: inline-block;
      float: left;
      padding-right: 7px;
      padding-left: 8px;
      font-size: 11px;
      font-style: normal;
      color: #bcbcc0;

      + span {
        &::before {
          position: absolute;
          top: 1px;
          left: 0;
          display: block;
          width: 1px;
          height: 11px;
          content: '';
          background-color: #d8d8d8;
        }
      }
    }

    a {
      color: #bcbcc0;
    }

    [class*='address_'] {
      overflow: hidden;
    }

    .address_bottom {
      margin-top: 4px;
    }
  }

  .copyright {
    padding-top: 16px;
    padding-bottom: 0;
    font-size: 11px;
    color: #8e8e93;

    span {
      padding-right: 10px;
    }
  }

  .browser_support {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 11px;
    line-height: 20px;
    color: #cacace;
  }

  // 예외화면 footer 타입
  &.simple {
    text-align: center;

    .policy_area {
      a {
        color: #666;

        em {
          color: #666;
        }
      }
    }

    .copyright {
      padding-top: 32px;

      span {
        font-size: 12px;
        color: #989898;
      }
    }
  }
}
