%multi_title {
  h4 {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .sub_text {
    margin-bottom: 20px;
    @include ellipsis_multi(2, '');
  }
}

.me_album_tracklist {
  position: absolute;
  top: 30px;
  left: 324px;
  z-index: 4;

  // width:auto;
  .home_recommend_track_w {
    position: relative;
    height: 260px;

    // padding:0 30px;
    overflow: hidden;

    .recommend_album_tracklist {
      float: left;

      // width:285px;

      & + .recommend_album_tracklist {
        margin-left: 30px;
      }

      li {
        display: block;
        width: auto !important;
        height: 45px;
        padding: 10px 0;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;

        .thumbnail_chart {
          .chart_thumbnail {
            width: 45px;
            height: 45px;
          }
        }
      }
    }

    .chart_title {
      width: 210px;
      max-width: 210px;
      margin: 5px 0 0 20px;
    }
  }

  &.chart_album_tracklist {
    left: 286px;

    .home_recommend_track_w {
      .recommend_album_tracklist {
        & + .recommend_album_tracklist {
          margin-left: 40px;
        }
      }

      .chart_ranking {
        margin: 5px 0 0 6px;
      }

      .chart_title {
        margin: 5px 0 0 6px;
      }
    }
  }
}

.personal_recommend_w {
  position: relative;
  display: block;
  width: 100%;
  height: 320px;
  white-space: normal;
  background-repeat: no-repeat;

  // border-radius:6px;
  background-position: center;
  background-size: cover;

  .personal_recommend_head {
    position: absolute;
    z-index: 4;
    width: 230px;
    padding: 40px 0 0 40px;
    font-size: 28px;
    font-weight: 800;
    line-height: 40px;
    color: $font_white;

    h4 {
      @include ellipsis_multi(3, '');

      margin-bottom: 20px;

      &.kids_tit {
        color: #fff;
      }
    }

    &.multi_title {
      @extend %multi_title;
    }

    &.full_title {
      @extend %multi_title;

      width: 340px;
    }

    &.type-box {
      top: 52px;
      box-sizing: border-box;
      width: auto;
      min-width: 210px;
      max-width: 280px;
      min-height: 215px;
      padding-top: 26px;
      padding-right: 25px;

      &::after {
        position: absolute;
        right: 24px;
        bottom: 25px;
        width: 26px;
        height: 9px;
        content: '';

        @include retina-sprite($sp-button-btn-personal-header-arrow-group);
      }

      & ~ .me_album_tracklist {
        // left:
      }

      .sub_text {
        display: inline-block;
        text-overflow: initial;
        -webkit-box-orient: initial;
        -webkit-line-clamp: initial;
      }
    }
  }

  .total_status_data {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $font_white;

    .date {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      vertical-align: middle;
      opacity: 0.7;
    }

    .bar_normal {
      padding: 0 10px;
      font-size: 9px;
      color: $font_white;
      opacity: 0.3;
    }

    [class^='ico_'] {
      margin-right: 6px;
      margin-left: 5px;
      vertical-align: -2px;
    }
  }

  .artist_thumbnail_box {
    position: absolute;
    top: 0;
    right: 70px;
    height: 100%;
  }

  .album_thumbnail_box {
    position: absolute;
    top: 40px;
    right: 70px;
    bottom: 80px;

    .recommend_album_title {
      max-width: 452px;
      margin-top: 25px;
      font-size: 15px;
      color: $font_white;

      > p {
        @include ellipsis1;
      }

      .text_center {
        text-align: center;
      }

      .text_ellipsis {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  + .btn_play_type1 {
    top: auto;
    bottom: 40px;
    left: 40px;
  }

  // 반응형추천패널이 부모인
  .type_react & {
    // 하트아이콘
    .ico_heart {
      display: inline-block;
      width: 98px;
      height: 98px;
      vertical-align: top;
      background: url('~@/assets/img/img_heart.png') 0 0 / 100% auto no-repeat;
    }
  }
}

// slide active 일 때 animation 실행
.swiper-slide-active {
  &.type_react {
    .personal_recommend_w .ico_heart {
      animation: reactPanelHeart 2s 0.4s ease-in forwards;
    }
  }
}

// 반응형 추천패널 하트아이콘 애니매이션

@keyframes reactPanelHeart {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  25% {
    opacity: 0.9;
    transform: scale(0.85);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  75% {
    opacity: 0.9;
    transform: scale(0.85);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// 추천 패널별 배경색 - TODO:: for문, mixin 등으로 개선 필요
.swiper-slide {
  // 실시간 차트
  &.type_chart {
    .personal_recommend_w {
      background-color: #0f0649;
    }
  }

  // 키즈 차트
  &.type_kids {
    .personal_recommend_w {
      background-color: #01b5e0;
    }
  }

  // 플레이리스트
  // &.type_theme{
  //     $color-list: (#4c4863, #444e62, #465550, #674d45, #61484b);
  //     @for $i from 0 to length($color-list) {
  //         // .personal_recommend_w{
  //         //     border: 1px solid red;
  //         //     background-color: nth($color-list, $i + 1);
  //         // }
  //     }
  // }
  &.type_theme {
    .personal_recommend_w {
      background-color: nth($color-list-theme, 1);
    }

    & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 2);
      }
    }

    & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 3);
      }
    }

    & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 4);
      }
    }

    & ~ & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 5);
      }
    }
  }

  // 추천 - 오늘의 FLO
  &.type_flo_today {
    .personal_recommend_w {
      background-color: nth($color-list-today, 1);
    }

    & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-today, 2);
      }
    }

    & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-today, 3);
      }
    }

    & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-today, 4);
      }
    }

    & ~ & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-today, 5);
      }
    }
  }

  // 추천 - 나를 위한 FLO
  &.type_flo_me {
    .personal_recommend_w {
      background-color: nth($color-list-me, 1);
    }

    & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-me, 2);
      }
    }

    & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-me, 3);
      }
    }

    & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-me, 4);
      }
    }

    & ~ & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-me, 5);
      }
    }
  }

  // 추천 - 아티스트 FLO
  &.type_flo_artist {
    .personal_recommend_w {
      background-color: nth($color-list-artist, 1);
    }

    & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-artist, 2);
      }
    }

    & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-artist, 3);
      }
    }

    & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-artist, 4);
      }
    }

    & ~ & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-artist, 5);
      }
    }
  }

  // 반응형 추천
  &.type_react {
    .personal_recommend_w {
      background-color: nth($color-list-react, 1);
    }

    & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-react, 2);
      }
    }

    & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-react, 3);
      }
    }

    & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-react, 4);
      }
    }

    & ~ & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-react, 5);
      }
    }
  }

  // 보관함 추천
  &.type_storage_recommend {
    .album_thumbnail_box {
      top: 65px;
      bottom: auto;
      width: 442px;
    }

    // defautl 색상 theme 사용중(란마랑 협의)
    // 혹시 변경요청이 온다면 새로 추가하여 변경할 것
    .personal_recommend_w {
      background-color: nth($color-list-theme, 1);
    }

    & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 2);
      }
    }

    & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 3);
      }
    }

    & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 4);
      }
    }

    & ~ & ~ & ~ & ~ & {
      .personal_recommend_w {
        background-color: nth($color-list-theme, 5);
      }
    }
  }

  // 타켓팅 인벤토리
  &.type-targeting-inventory {
    .personal_recommend_head {
      .sub_text {
        display: inline-block;
        text-overflow: initial;
        -webkit-box-orient: initial;
        -webkit-line-clamp: initial;
      }
    }

    &.bg-align-right {
      .btn_play_type1 {
        top: auto;
        right: 45px;
        bottom: 30px;
        left: auto;
      }
    }
  }

  // 배경 이미지 오른쪽 정렬
  &.bg-align-right {
    .personal_recommend_w {
      background-position: 100% 50%;
      background-size: auto 100%;
    }
  }

  // 배경 이미지 왼쪽 정렬
  &.bg-align-left {
    .personal_recommend_w {
      background-position: 0 50%;
      background-size: auto 100%;
    }
  }
}
