@import 'main.banner'; // 메인 배너

.main,
.section_home {
  padding: 95px 0 40px 80px;
  margin: 0 auto;
  background-color: $font_white;
  @include respond-to(mediaquery-small) {
    // padding: 50px 0 40px 30px; // TODO: padding-top 확인 필요
    padding-right: 40px;
    padding-bottom: 30px;
    padding-left: 40px;
  }

  a {
    color: $font_default;

    &:hover {
      color: $font_purple;
    }

    &:active {
      color: #c2c2c2;
    }
  }
}

.section_home {
  &.w955 {
    width: 955px;
    padding-right: 80px;

    .section_inner {
      padding-top: 10px;
    }

    .sort_type_wrap {
      top: 5px;
      right: 62px;

      button {
        padding-right: 10px;
      }
    }

    .paging_slide_arrow_w {
      top: 62px;
      right: 0;
    }
  }
}

.section_content_wrap {
  position: relative;
  padding: 42px 0 0;

  .paging_slide_arrow_w {
    position: absolute;
    top: 45px;
    right: 0;
    z-index: 30;
  }

  &.personal {
    padding-top: 0 !important;

    .section_content {
      margin-bottom: 27px;
      border-radius: 6px;
    }

    .paging_slide_arrow_w {
      top: 53px;
    }

    // 70px 공통 간격에서 box-shadow 35px 영역을 제외한 padding-top
    & + .section_content_wrap {
      .paging_slide_arrow_w {
        top: 37px;
      }

      // 컨텐츠 오른쪽 최상단 링크
      .link_default {
        top: 40px;
      }
    }
  }

  &.browse_content {
    padding: 50px 0 0;
  }

  // 메인에서 상황별 음악 여백
  @at-root .section_home .section_content_wrap {
    padding-top: 60px;
  }

  &.badge_thumbnail_list {
    padding: 42px 0 0;
  }

  // detail & search에서 탭과 컨텐츠 사이 여백
  @at-root .tab {
    + .section_content_wrap {
      padding-top: 40px;
    }
  }

  // 보관함 컨텐츠 여백
  @at-root .section_storage {
    .section_content_wrap {
      padding-top: 0;
    }
  }

  // 컨텐츠 오른쪽 최상단 링크
  // 지금은 style.scss에 컴파일되기따문에 main.scss에 작성(section_content_wrap은 타페이지에서 공통으로 쓰임)
  .link_default {
    position: absolute;
    top: 65px;
    right: 0;
    z-index: 20;
  }

  .link_default_left {
    top: 66px;
    right: 65px;
  }
}

.section_browse {
  .section_inner {
    h2 + .section_content_wrap {
      padding-top: 50px;

      .paging_slide_arrow_w {
        top: 50px;
      }
    }
  }
}

.section_content_head {
  position: relative;
  z-index: 20;
  min-height: 20px; // 보관함에서 최소값 필요
  margin-bottom: 20px;

  h3 {
    font-size: 22px;

    a {
      display: inline-flex;
      align-items: center;

      &::after {
        display: inline-block;
        margin-left: 5px;
        content: '';
        @include hide_text;
        @include retina-sprite($sp-button-btn-title-arrow-nor-group);
      }

      &:hover {
        &::after {
          @include retina-sprite($sp-button-btn-title-arrow-hover-group);
        }
      }

      &:active {
        &::after {
          @include retina-sprite($sp-button-btn-title-arrow-press-group);
        }
      }
    }
  }

  // 타이틀 바로 오른쪽에 붙는 설명
  .head_desc {
    display: inline-block;
    margin-left: 18px;
    font-size: 13px;
    font-weight: 400;
    color: #a0a0a0;
    vertical-align: middle;
  }

  // 타이틀 아래 붙는 설명
  .head_desc_bottom {
    display: block;
    margin-top: 6px;
    font-size: 15px;
    line-height: 1;
    color: #666;
  }
}

.btn_allchoice {
  position: relative;
  top: 42px;
  padding-left: 17px;
  margin-left: 10px;
  font-size: 13px;
  line-height: 17px;
  color: #222;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    @include retina-sprite($sp-button-btn-list-top-check-nor-group);
  }

  &:hover {
    color: $font_blue;

    &::after {
      @include retina-sprite($sp-button-btn-list-top-check-hover-group);
    }
  }

  &:active {
    color: rgba(51, 51, 51, 0.3);

    &::after {
      @include retina-sprite($sp-button-btn-list-top-check-press-group);
    }
  }
}

.section_content {
  margin-top: 6px; // title과의 여백 20px

  .sm_size {
    // margin-top: 0; // title과의 여백 20px
    li {
      width: 157px;
      margin-bottom: 0;
    }
  }

  // @include respond-to(medium-screens) {
  //     .thumbnail_list {
  //         margin-top: -40px;

  //         li {
  //             margin-top: 40px;
  //         }
  //     }
  // }
  // @include respond-to(mediaquery-small) {
  //     .thumbnail_list {
  //         margin-top: -40px;

  //         li {
  //             margin-top: 40px;
  //         }
  //     }
  // }
}

.swiper_horizon {
  min-height: 189px;
  margin-top: 6px; // title과의 여백 20px
  overflow: hidden hidden;
  white-space: nowrap;

  // -ms-overflow-style: none;
  // overflow: -moz-scrollbars-none;
  @include scroll_style;
}

%thumbnail_list_style {
  margin-top: -40px; // 두줄일때 위아래 여백용 마진
  margin-left: -20px;

  // 둘러보기 - 디테일에서는 타이틀과의 여백 필요
  @at-root .section_browse,
    .section_editors-pick {
    .section_inner {
      > .thumbnail_list {
        margin-top: 0;
      }
    }
  }

  @at-root .section_home {
    .section_content {
      .thumbnail_list {
        margin-top: -16px;

        > li {
          margin-top: 16px;
        }
      }

      // home 장르 콜렉션 swiper
      &.HOME_GENRE_CHART_swiper_container {
        &.swiper-wrapper {
          // swiper일 경우 오른쪽 margin 여백이 생기므로 0으로 준다
          margin-left: 0;

          li {
            margin-right: 20px;
            margin-left: 0;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .newlist_panel {
      white-space: normal;
    }
  }

  @at-root .editors-pick-content {
    .thumbnail_list {
      margin-top: -16px;

      > li {
        margin-top: 16px;
      }
    }
  }

  li {
    display: inline-block;
    width: 175px;
    margin-top: 28px; // 두줄일때 위아래 여백용 마진
    margin-left: 20px;
    overflow: hidden;
    vertical-align: top;

    .album_title_text {
      display: block;
      margin: 0 3px;
      font-size: 15px;
      line-height: 21px;
      white-space: normal;

      span {
        @include ellipsis_multi(2, '');
        @include ie_multiline(42px, 2);
      }

      .album_title_sub {
        // 서브타이틀 태그타입이 한개가 아닐때 한줄처리 (서브타이틀 + 타이틀일 경우)
        &:not(:only-of-type) {
          @include ellipsis1;
        }

        // 서브타이틀 + 타이틀일 경우 한줄처리
        & + span {
          @include ellipsis1;

          // 타이틀 2줄 말줄임 ie대응 제거
          &::after {
            display: none;
          }
        }
      }
    }

    .artist_title_text {
      display: block;
      margin: 5px 3px 0;
      font-size: 13px;
      color: #969696;
      white-space: normal;

      span {
        @include ellipsis1;
        @include ie_multiline(16px, 1);
      }

      em {
        font-style: normal;
        color: #969696;

        &.artist_subtext_2nd {
          &::before {
            position: relative;
            margin: 0 5px 0 3px;
            content: '|';
          }
        }
      }
    }

    .genre_title_text {
      position: relative;
      top: -177px;
      left: 36px;
      z-index: 4;
      display: block;
      width: 116px;
      font-size: 27px;
      font-weight: 500;
      line-height: 1.19;
      color: $font_white;
      text-align: center;
    }

    .align-center {
      text-align: center;
    }

    .br {
      display: block;
      color: $font_white;
    }
  }

  &.wide {
    margin-left: 0;
    overflow: visible visible;

    > li {
      position: relative;
      width: 955px;

      // margin-bottom: 27px;
      margin-left: 0;
      overflow: visible;
    }
  }

  &.list_mode_edit {
    .make_play_list {
      display: none;
    }
  }

  // thumbnail list가  swiper-wrapper일 경우
  &.swiper-wrapper {
    margin-left: 0;

    .swiper-slide {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
    }
  }
}

.thumbnail_list {
  @extend %thumbnail_list_style;
}

.artist_thumbnail_list {
  @extend %thumbnail_list_style;

  // margin-top: 23px;
  margin-left: -27px;

  &.mgt52px {
    margin-top: 52px;
  }

  li {
    width: 157px;

    // margin-bottom: 50px;
    margin-left: 27px;
  }

  &.sm_size {
    .album_thumbnail {
      width: 157px;
      height: 157px;
    }
  }
}

// main_content > symbol_list  , 공통
.symbol_list {
  margin-top: -16px; // title과의 여백 20px
  margin-left: -37px;

  li {
    display: inline-block;
    width: 175px;
    margin-top: 30px;
    margin-left: 36px;

    a {
      img {
        vertical-align: middle;
        border-radius: 6px;
      }
    }

    .symbol_area {
      a {
        display: block;
        padding: 32px 0 51px;
        font-size: 15px;
        color: #181818;
        text-align: center;
        background-color: #f6f6f6;
        border-radius: 6px;

        img {
          display: block;
          margin: 0 auto 9px;
        }

        &:hover {
          background-color: #ebebeb;
        }
      }
    }
  }

  // @include respond-to(mediaquery-small) {
  //     li {
  //         width: 226px;
  //         max-width: 226px;

  //         &:nth-child(3n) {
  //             margin-right: 0;
  //         }
  //     }
  // }
  // 메인 상황별 음악 여백
  @at-root .section_home .symbol_list {
    margin-left: -20px;

    li {
      margin-left: 20px;
    }
  }
}

// 검색 main >badge 앨범
.album_badge_type {
  min-width: 1600px;
  min-height: 416px;
}

.badge_thumbnail_list {
  margin-left: -22px;

  &.storage_top_normal {
    padding-top: 38px;
    margin-top: -20px;

    li {
      margin-top: 40px;
    }
  }

  &.album_top_normal {
    padding-top: 23px;

    li {
      margin-top: 26px;
    }
  }

  // 검색결과 상하 여백
  // @at-root .section_content {
  //     > .badge_thumbnail_list {
  //         margin-top: -20px;
  //         li {
  //             margin-top: 26px;
  //         }
  //     }
  // }

  &.search_album_top {
    margin-top: -20px;

    li {
      margin-top: 26px;
    }
  }

  &.detail_album_top {
    margin-top: -26px;
  }

  li {
    display: inline-block;

    // overflow: hidden;
    width: 412px;
    padding-right: 12px;
    margin-right: -12px; // 뱃지 선택 시 배경 이미지의 넓이가 12px 넓어지므로 해당 간격만큼 margin, padding을 줌
    margin-left: 22px;
    vertical-align: top;
  }

  // 보관함 상하 여백
  @at-root .section_storage {
    .badge_thumbnail_list {
      padding-top: 57px;

      li {
        // 뱃지 상하간격 : 40 -> 32(트랙리스트 상단여백 5px 포함);
        margin-top: 27px;
      }
    }
  }

  @include respond-to(mediaquery-large) {
    margin-left: -102px;

    li {
      margin-left: 102px;
    }
  }
}

// main_content > flo 추천
.recommend_list {
  margin-top: 14px;

  > li {
    display: inline-block;
    width: 468px;
    margin-left: 19px;
    vertical-align: top;

    &:first-child {
      margin-left: 0 !important;
    }

    .album_title_text {
      display: block;
      margin: 0 3px;
      font-size: 15px;
      line-height: 21px;
      white-space: normal;

      span {
        @include ellipsis_multi(2, '');
      }
    }

    .artist_title_text {
      display: block;
      margin: 5px 3px 0;
      font-size: 13px;
      color: #969696;
      white-space: normal;

      em {
        font-style: normal;
        color: #969696;

        &.artist_subtext_2nd {
          &::before {
            position: relative;
            margin: 0 5px 0 3px;
            content: '|';
          }
        }
      }
    }

    .artist_title_list {
      margin: 0 3px;
      font-size: 15px;
      line-height: 21px;
      white-space: normal;
      @include ellipsis_multi(2, '');
    }
  }

  &.width_small {
    > li {
      width: 370px;
      margin-left: 20px;
    }
  }
}

// 썸네일 리스트 (둘러보기 상황, 장르, 분위기)
.list_thumb_category {
  font-size: 0;

  > li {
    position: relative;
    display: inline-block;
    width: 215px;
    height: 100px;
    margin-top: 30px;
    margin-left: 30px;
    word-break: break-word;
    word-break: break-all;
    vertical-align: top;

    a {
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      padding-right: 70px;
      overflow: hidden;
      font-size: 15px;
      color: #fff;
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 300% auto;
      border-radius: 6px;

      &:hover,
      &:visited,
      &:active {
        color: #fff;
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 100%;
        vertical-align: middle;
      }
    }
  }

  .symbol_title {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    margin: 22px 0 0 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  // symbol_list내부에 있을때
  .symbol_list & {
    margin-top: 3px;
    margin-left: 7px;
  }
}

// 홈 취향관리 배너
.home-discovery-banner {
  display: block;
  width: 100%;
  height: 172px;
  background: url('~@/assets/img/img_banner_home_discovery.png') 0 0 / 100% 100% no-repeat;
  border-radius: 6px;
}
