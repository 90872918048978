%notfound_section_style {
  padding: 264px 0;
  font-size: 17px;
  color: #bebebe;
  text-align: center;

  .sub_text {
    padding-top: 12px;
    padding-bottom: 30px;
    font-size: 15px;
    color: #bebebe;
  }
}

.notfound_section {
  @extend %notfound_section_style;
}

.notfound_section_detail {
  @extend %notfound_section_style;

  padding: 140px 0;
}

.notfound_section_info {
  @extend %notfound_section_style;

  padding: 190px 0;
}

// 보관함 추천 영역
.storage_recommend {
  margin: 22px 0 45px;

  @mixin resetMarginLeft {
    margin-left: 0;
  }

  .list_thumbnail_stretch {
    flex-wrap: nowrap;
    overflow: visible;
    @include resetMarginLeft;

    @media screen and (max-width: 955px) {
      @include resetMarginLeft;
    }

    .thumbnail_stretch {
      @include resetMarginLeft;

      @media screen and (max-width: 955px) {
        @include resetMarginLeft;
      }
    }
  }

  & + .paging_slide_arrow_w {
    top: 0;
  }
}
