.character_section {
  padding-top: 60px;

  .info_area {
    padding-top: 15px;
    text-align: center;

    .title_modify {
      height: 37px;
      padding-top: 21px;
      font-size: 24px;

      span {
        position: relative;

        .btn_edit {
          position: relative;
          top: -2px;
          @include retina-sprite($sp-button-btn-edit-nor-group);
          @include hide_text;

          &:hover,
          &:active {
            @include retina-sprite($sp-button-btn-edit-hover-group);
            @include hide_text;
          }

          &:disabled,
          &.disabled {
            @include retina-sprite($sp-button-btn-edit-press-group);
            @include hide_text;
          }
        }
      }
    }

    .comp_inc_btn {
      width: 320px;
      margin: 0 auto;

      input {
        font-size: 24px;
        text-align: center;
      }
    }

    dl {
      width: 300px;
      margin: 7px auto 0;

      dd {
        display: inline-block;
        padding-bottom: 2px;
        font-size: 14px;
        color: #999;

        .ic_like {
          display: inline-block;
          @include retina-sprite($sp-icon-ic-my-genre-like-group);
          @include hide_text;

          padding-right: 0;
          margin-top: -5px;
        }

        i {
          padding-right: 3px;
        }

        &:last-of-type {
          i {
            @include hide_text;
          }
        }
      }
    }

    .btn_character_del {
      margin-top: 185px;
      font-size: 14px;
    }

    .btn_pw_del {
      @include retina-sprite($sp-button-btn-input-edit-delete-nor-group);
      @include hide_text;
    }
  }

  .btn_area_manage {
    margin-top: 25px;

    button,
    a {
      & + button,
      & + a {
        margin-left: 10px;
      }
    }
  }
}

.my_character_info {
  // 내 캐릭터 설정 pop
  .pop_user_character_area {
    display: table;
    text-align: left;

    .character_pop_thumbnail {
      position: relative;
      display: table-cell;
      border: none;
      outline: none;

      img {
        @include thumb_round(50px);
      }

      .thumb {
        @include thumb_round(50px);

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        outline: 0.5px solid rgba(0, 0, 0, 0.1);
      }

      .ico_checked {
        position: absolute;
        top: 33px;
        left: 33px;
        z-index: 3;
        @include hide_text;
        @include retina-sprite($sp-icon-ic-character-check-group);
      }

      &.newlist_plus {
        width: 50px;
        height: 50px;
        text-align: center;
        vertical-align: middle;
        background-color: #f3f3f3;
        border: solid 1px #e9e9e9;
        border-radius: 50px;

        &::before {
          display: inline-block;
          content: '';
          @include retina-sprite($sp-button-btn-character-add-group);
          @include hide_text;
        }
      }
    }

    .character_info {
      display: table-cell;
      padding-left: 15px;
      vertical-align: middle;

      .character_w {
        width: 170px;
        margin-top: -7px;

        p {
          display: block;
          margin-top: 7px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.playlist_title {
            font-size: 14px;
            font-weight: bold;
          }

          &.playlist_add_text {
            font-size: 14px;
          }

          &.taste_text {
            font-size: 13px;
            color: #999;

            .ic_taste_like {
              display: inline-block;
              @include retina-sprite($sp-icon-ic-my-genre-like-group);
              @include hide_text;

              padding-right: 0;
              margin-top: -5px;
            }
          }
        }
      }
    }
  }

  .user_info {
    padding: 16px 25px;
    border-top: 1px solid #eee;

    .info_email {
      margin-top: 5px;
      font-size: 14px;
      color: #999;
      word-wrap: break-word;
      white-space: normal;
    }

    dl {
      padding-top: 4px;
      overflow: hidden;

      dd {
        padding-top: 3px;
        font-size: 13px;
        color: #999;

        i.number {
          font-style: normal;
          color: $font_blue;
        }
      }
    }

    .info_setting {
      display: inline-flex;
      align-items: center;
      font-size: 13px;
      color: #333 !important;

      &::after {
        content: '';
        @include retina-sprite($sp-button-btn-imfo-arrow-nor-group);
      }
    }
  }

  .btn_character_logout {
    display: block;
    width: 76px;
    height: 30px;
    padding-top: 2px;
    margin: 0 auto 16px;
    font-size: 14px;
    line-height: 1.2;
    color: $font_blue;
    text-align: center;
    border: solid 1px $font_blue;
    border-radius: 16px;
  }
}
