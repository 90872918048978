@import 'playlist.tab.artist';
@import 'playlist.tab.similar';
@import 'playlist.header.tab';
@import 'playlist.header.util';

$track-item-left-padding: 20px; // 트랙 아이템 왼쪽 padding
$single-view-padding: 40px;

// 썸네일 공통 dimmed
%thumb_before_dimmed {
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 45px;
    content: '';
    background: rgba(0, 0, 0, 0.8);
  }
}
%thumb_after_icon {
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 20;
  display: block;
  content: '';
}

// 재생목록 독립창
.playlist_ct {
  @include scroll_style;

  .playlist_scroll {
    overflow-x: hidden;
    @include scroll_style;
  }

  .playlist_section {
    margin-top: -10px;

    // 하위 drop zone 확보 위해 추가
    > span {
      display: block;
      padding-bottom: 10px;
    }
  }

  // 그룹핑된 재생목록
  .group_area {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #323232;
    border-radius: 7px;

    .list_title {
      display: block;
      width: 100%;
      padding-left: $track-item-left-padding;
      text-align: left;

      span {
        @include ellipsis1;

        width: 342px;
        font-size: 16px;
        font-weight: bold;
        line-height: 60px;
        color: #fff;
      }
    }

    .list_track {
      display: none;
    }

    // 재생목록 열기
    &.open {
      background-color: #181818;

      .btn_list_close {
        @include retina-sprite($sp-button-btn-playlist-close-arrow-nor-group);
      }

      .list_track {
        position: relative;
        display: block;
        padding-top: 10px;

        &::before {
          position: absolute;
          top: 0;
          right: $track-item-left-padding;
          left: $track-item-left-padding;
          height: 1px;
          content: '';
          background-color: rgba(216, 216, 216, 0.1);
        }
      }
    }

    &.now,
    &.pause {
      .list_title {
        span {
          color: $key_color;
        }
      }
    }

    &.dragElement {
      height: 60px;
      overflow: hidden;
    }
  }

  // 트랙 정보
  .track_area {
    position: relative;
    height: 65px;

    .track_item {
      position: relative;
      height: 100%;
    }

    .thumb {
      position: absolute;
      top: 10px;
      left: $track-item-left-padding;
      display: block;
      width: 45px;
      height: 45px;
      overflow: hidden;
      background-size: 100% 100%;
      border-radius: 4px;
    }

    .btn_track_select {
      width: 100%;
      height: 100%;
      padding-left: 85px;
      text-align: left;

      .track_info {
        width: 320px;
      }

      .title {
        // padding-top: 15px;
        font-size: 15px;
        color: #fff;
        @include ellipsis1;

        .ico_adult {
          display: inline-block;
          margin-right: 4px;
          vertical-align: top;
          @include retina-sprite($sp-icon-ic-list-badge-19-group);
        }
      }

      .artist {
        padding-top: 4px;
        font-size: 12px;
        color: #666;
        @include ellipsis1;
      }

      .ico_free {
        @include hide_text;
        @include retina-sprite($sp-icon-icon-free-group);

        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    &.now {
      .thumb {
        @extend %thumb_before_dimmed;

        &::after {
          @extend %thumb_after_icon;

          width: 17px;
          height: 17px;
          background-image: url('../../assets/img/ic-playlist-music-thumb-eq.gif');
          background-size: 100% 100%;
        }
      }
    }

    // 현재 재생중인 곡 일시 정지
    &.pause {
      .thumb {
        @extend %thumb_before_dimmed;

        &::after {
          @include retina-sprite($sp-icon-ic-playlist-music-thumb-eq-group);
          @extend %thumb_after_icon;
        }
      }
    }

    &.now,
    &.pause {
      .title {
        color: #7286ff;
      }

      .artist {
        color: #7286ff;
      }

      .ico_free {
        @include retina-sprite($sp-icon-icon-free-playing-group);
      }
    }

    &.disabled {
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 30;
        display: block;
        content: '';
        background-color: rgba($color: $player-wrapper-background-color, $alpha: 0.6);
      }
    }

    // trackMore 열렸을 때 z-index 위해 추가
    &.open_track_more {
      z-index: 1001;
    }

    &.marquee {
      .btn_track_select {
        .track_info {
          .title {
            text-overflow: inherit;

            span {
              // @include vendor_prefix('animation', 'marquee 10s linear infinite');
              display: inline-block;

              &:hover {
                @include vendor_prefix('animation-play-state', 'paused');
              }
            }
          }
        }
      }
    }

    // @include keyframes(marquee) {
    //     0% {
    //         transform: translate(0, 0);
    //     }
    //     10% {
    //         transform: translate(0, 0);
    //     }
    //     100% {
    //         transform: translate(-100%, 0);
    //     }
    // }
  }

  .check_area {
    .inp_check_square + label::before {
      vertical-align: top;
    }

    @at-root {
      .playlist_item {
        &.track_area {
          .check_area {
            float: left;
            padding-top: 27px;
            padding-left: $track-item-left-padding;

            & + .track_item {
              float: left;
            }

            & + .list_title {
              width: auto;
            }
          }
        }

        &.group_area {
          > .check_area {
            position: absolute;
            top: 24px;
            left: $track-item-left-padding;

            & + .track_item {
              .list_title {
                display: inline-block;
                width: auto;
                margin-left: 32px;
              }
            }
          }
        }
      }
    }
  }

  .playlist_util {
    position: absolute;
    top: 15px;
    right: 23px;

    button[class^='btn_'] {
      @include hide_text;

      float: left;
      margin-left: 20px;
    }

    .layer_dropdown {
      top: 48px;
      right: 0;
      z-index: 200;
    }

    // TODO: select popup 충돌을 피하기 위하여 위치 변경 (수정 필요)
    .btn_add_mylist {
      @include retina-sprite($sp-button-btn-playlist-music-add-nor-group);
    }

    .btn_delete {
      @include retina-sprite($sp-button-btn-playlist-del-nor-group);
    }
  }

  .btn_list_like {
    @include retina-sprite($sp-button-btn-playlist-like-nor-group);
  }

  .btn_list_listen {
    @include retina-sprite($sp-button-btn-playlist-repeat-nor-group);
  }

  .btn_list_listen_active {
    @include retina-sprite($sp-button-btn-playlist-repeat-on-nor-group);
  }

  .btn_list_close {
    @include retina-sprite($sp-button-btn-playlist-open-arrow-nor-group);
  }

  .btn_playlist_more {
    @include retina-sprite($sp-button-btn-playlist-music-more-nor-group);
  }

  .ico_nowplay {
    float: left;
    margin-top: 6px;
    margin-left: 20px;
  }

  .now {
    .ico_nowplay {
      width: 17px;
      height: 17px;
      background-image: url('../../assets/img/ic-playlist-music-thumb-eq.gif');
      background-size: 100% 100%;
    }
  }

  .pause {
    .ico_nowplay {
      @include retina-sprite($sp-icon-ic-playlist-music-thumb-eq-group);
    }
  }

  .btn_recommend {
    @include retina-sprite($sp-button-btn-playlist-pin-nor-group);
  }

  .btn_delete_inactive {
    @include retina-sprite($sp-button-btn-playlist-del-dim-group);
  }

  .btn_move {
    @include retina-sprite($sp-button-btn-playlist-change-nor-group);
  }

  // 플레이리스트만 보기 일 때
  &.show_playlist_only {
    .track_area {
      display: none;
    }

    .group_area {
      .track_area {
        display: block;
      }
    }
  }

  &.single_view {
    position: fixed;
    top: 0;
    right: 0;
    bottom: $miniplayer-height;
    z-index: 200;
    width: 575px;

    // height:100%;
    padding: 0 $single-view-padding;
    overflow: hidden;
    background-color: $player-wrapper-background-color;

    .tit_playlist {
      position: relative;
      display: inline-block;
      padding-top: 50px;
      font-size: 20px;
      color: #fff;

      .btn_playlist_edit {
        @include retina-sprite($sp-button-btn-playlist-setting-nor-group);
        @include hide_text;

        position: absolute;
        right: -33px;
        bottom: -2px;
        display: inline-block;
        vertical-align: middle;
        content: '';
      }
    }

    .playlist_scroll {
      position: absolute;
      top: 157px;

      // right: -$is-scroll-space;
      right: 0;

      // bottom: $miniplayer-height;
      bottom: 0;
      left: 0;

      // padding: 0 ($single-view-padding + $is-scroll-space) 0 $single-view-padding;
      padding: 0 $single-view-padding;
      overflow-y: auto;
    }

    .btn_playlist_close {
      @include hide_text;
      @include retina-sprite($sp-button-btn-playlist-close-nor-group);

      position: absolute;
      top: 53px;
      right: $single-view-padding;

      &:hover {
        @include retina-sprite($sp-button-btn-playlist-close-press-group);
      }
    }
  }

  // 플레이리스트 체크박스 공통 스타일
  .inp_check_square {
    &:not(:checked) + label::before {
      @include retina-sprite($sp-button-btn-player-check-nor-group);
    }
  }
}

// 플레이어 내 재생목록
.playlist_wrap {
  position: relative;
  width: $player-ct-width;
  margin: 0 auto;

  .playlist_inner {
    width: 100%;
    overflow: hidden;
  }

  // 1440px 이상
  @include respond-to(mediaquery-large) {
    position: relative;
    float: right;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    // -ms-overflow-style: hidden;
    @include scroll_style;

    .playlist_inner {
      position: absolute;
      top: 85px;
      bottom: 0;
      left: 45px;
      width: $player-ct-width;
    }

    .playlist_ct {
      position: absolute;
      top: $playlist-tab-height;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden auto;

      .playlist_scroll {
        position: absolute;
        top: 82px;
        right: 0;

        // right: $is-scroll-space;
        bottom: 0;
        left: 0;
        overflow-y: auto;
      }
    }

    .notfount_txt {
      @include align_middle(0, 50%, 0, -50%);

      width: $player-ct-width + ($single-view-padding * 2);
      margin: 0;
    }
  }
}

// 목록, 검색 결과 없을 경우
.notfount_txt {
  width: 100%;
  margin: 150px auto;
  font-size: 15px;
  color: #646464;
  text-align: center;

  em {
    display: block;
    padding-bottom: 12px;
    font-size: 17px;
    font-style: normal;
    color: #a0a0a0;
  }

  .btn_area {
    padding-top: 32px;
  }

  .btn_search_all {
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 32px;
    color: #fff;
    border: 1px solid #505050;
    border-radius: 16px;
  }
}
