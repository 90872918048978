
          @import '@/assets/scss/common/color.scss';
        

.empty-section {
  padding: 219px 0;
  text-align: center;

  &__image {
    display: block;
    margin: 0 auto 24px;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
    color: #333;
  }

  &__request {
    font-size: 14px;
    color: #fff;
  }

  &__sub-text {
    margin: 10px 0 32px;
    font-size: 15px;
    color: #989898;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    vertical-align: top;
    border: 1px solid #ebebeb;
    border-radius: calc(32px / 2);

    &:hover,
    &.hover {
      color: #3f3fff;
      border-color: #3f3fff;
    }
  }
}
