
          @import '@/assets/scss/common/color.scss';
        

.player-time {
  display: flex;
  gap: 5px;
  margin-left: 24px;
  font-size: 11px;
  font-weight: 400;
  color: #989898;

  &__current {
    color: #fff;
  }
}
