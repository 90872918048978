
          @import '@/assets/scss/common/color.scss';
        
@import '../common/helpers.variable'; // 이미지 url 변수

.transitionWrap {
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translateY(0);
}

.transitionLayer-enter-active,
.transitionLayer-leave-active {
  transition: all 0.3s;
}

.transitionLayer-enter,
.transitionLayer-leave-to {
  transform: translateY(100%);
}

.transitionWrap,
.noTransition {
  transition: none;
}

.desktopFull {
  width: 960px;
  height: 650px !important;

  /* SCSS 파일 내 ::v-deep pseudo-class는 에러로 출력됨. 추후 vue 파일 내로 migration 예정 */
  /* stylelint-disable-next-line selector-pseudo-element-no-unknown */
  ::v-deep .iscrollScroller {
    // height:550px;
    min-height: auto;
    overflow: hidden;
    overflow-y: auto;
  }

  /* SCSS 파일 내 ::v-deep pseudo-class는 에러로 출력됨. 추후 vue 파일 내로 migration 예정 */
  /* stylelint-disable-next-line selector-pseudo-element-no-unknown */
  ::v-deep .type-policy {
    height: auto;
    padding: 30px;
  }
}

/* account change */
.layerAccountChange_Wrapper {
  max-width: 650px !important;
  height: auto !important;

  .layer-content-inner {
    max-height: 450px;
    overflow: hidden;
    overflow-y: auto;
  }

  .btn-wrap {
    a {
      width: 50%;
    }
  }
}

.lyr-accountchange {
  .cnt-change {
    box-sizing: border-box;
    padding: 0 40px 40px;
    margin-top: -25px;
  }

  .block-top {
    padding-bottom: 27px;
    text-align: center;
    border-bottom: 1px solid #f3f3f3;

    h3 {
      padding-top: 22px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.4;
      color: #181818;

      span {
        display: inline-block;
        margin-left: 5px;
      }
    }

    p {
      display: block;
      margin-top: 10px;
      font-size: 15px;
      color: #484848;
    }
  }

  .block-list {
    padding: 28px 0 20px;

    li {
      padding-bottom: 8px;
      padding-left: 16px;
      font-size: 15px;
      font-weight: 600;
      text-indent: -16px;
      word-break: keep-all;
    }
  }

  .tail-text {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    color: #989898;
    text-align: left;
    word-break: keep-all;
  }
}

.BusinessInformation_Wrapper {
  top: 50%;
  left: 50%;
}

/* channel description */
.channel-description_Wrapper {
  .btn-wrap {
    a {
      width: 50%;
    }
  }
}

.lyr-chnldescription {
  .cnt-change {
    box-sizing: border-box;
    padding: 0 10px;
  }

  .block-top {
    padding-bottom: 20px;
    text-align: left;
    border-bottom: 1px solid #f3f3f3;

    h3 {
      padding-top: 10px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.4;
      color: #181818;

      span {
        display: inline-block;
        margin-left: 5px;
      }
    }

    p {
      display: block;
      margin-top: 10px;
      font-size: 15px;
      color: #484848;
    }
  }

  .block-list {
    padding: 20px 0;
    white-space: pre-line;

    a {
      color: $key_color;
      text-decoration: underline;

      &:hover {
        color: $key_color;
        cursor: pointer;
      }
    }

    span {
      line-height: 1.5;
      word-break: break-word;

      a {
        display: inline;
      }
    }

    .link_area {
      padding: 14px 0;

      a {
        display: inline-block;
      }
    }
  }
}
