@import 'common/helpers.variable'; // 이미지 url 변수
@import 'sprite/sp_all';
@import 'common/helpers.mixins';

// iscroll height 고정높이 풀기
@mixin iscrollViewHeight {
  height: calc(100vh - 180px) !important;
}

.vue-progress-path {
  display: inline-block;
}

.vue-progress-path path {
  fill: none;
  stroke-width: 6;
  transition: stroke-dashoffset 0.3s ease-in-out;
}

.vue-progress-path .background {
  stroke: #ddd;
}

.vue-progress-path .progress {
  stroke: #40b883;
}

.vue-progress-path.indeterminate path {
  transition: none;
}

input {
  box-sizing: border-box;
  padding: 0;
  appearance: none;
  border: 0;

  &:focus {
    &::-webkit-contacts-auto-fill-button {
      position: absolute;
      right: 0;
      display: none !important;
      pointer-events: none;
      visibility: hidden;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }
}

.emColor {
  color: #db2359;
}

.emColorBlue {
  color: #4334ff;
}

.emColorPink {
  color: #ff4d78;
}

.hidden {
  display: none;
}

.ico-t {
  display: inline-block;
  width: 1rem;
  height: 0.9375rem;
  margin: -2px 2px 0 0;
  text-indent: -9999px;
  vertical-align: middle;
  background: url('~@/assets/img/icon_skt.svg') 0 0 no-repeat;
  background-size: 100% auto;
}

.dot-list {
  li {
    position: relative;
    padding-bottom: 4px;
    padding-left: 0.625rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #999;

    &::before {
      position: absolute;
      top: -11px;
      left: 0;
      font-size: 24px;
      line-height: 1;
      color: #d0d0d0;
      content: '.';
    }
  }
}

// 로그인/회원가입
.sign-brandlogo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 30px;
  margin: 90px auto 50px;
  cursor: pointer;
}

.sign-container {
  box-sizing: border-box;
  width: 680px;
  padding: 60px 120px;
  margin: 70px auto 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

// 공통 FORM
.form-check {
  position: relative;

  > span {
    position: relative;
    display: block;
    min-height: 25px;
    padding-left: 33px;
    overflow: hidden;
    font-size: 16px;
    line-height: 25px;
    color: #333;

    &::before {
      position: absolute;
      top: 2px;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      content: '';

      // width:25px;
      // height:25px;
      background-image: url('~@/assets/img/btn_selected3.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
    }
  }

  input {
    position: absolute;
    left: -9999em;

    &:checked + span::before {
      background-position: 0 -20px;
    }
  }
}

.form-check-rect {
  position: relative;

  > span {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    font-size: 0.8125rem;
    font-size: 0;
    color: #333;
    color: transparent;
    vertical-align: middle;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 20px;
      height: 20px;
      content: '';
      background-image: url('~@/assets/img/btn_selected3.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100%;
    }
  }

  input {
    position: absolute;
    left: -9999em;

    &:checked + span::before {
      background-position: 0 -20px;
    }
  }
}

.ip-reset {
  width: 1.8125rem;
  height: 1.8125rem;
  text-indent: -9999em;
  background: url('~@/assets/img/btn_input_delete.png') 0 0 no-repeat;
  background-size: 100%;
}

.ip-reset.available {
  display: block !important;
}

// 공통 하단 버튼
.btn-wrap {
  z-index: 2;
  display: table;
  width: 100%;
  height: 3.75rem;

  button,
  a {
    display: inline-block;
    display: table-cell;
    width: auto;
    height: 100%;
    font-size: 1rem;
    font-weight: 600;
    line-height: 3.75rem;
    color: #fff;
    text-align: center;
    background-color: #3f3fff;
    border: 0;

    span {
      color: #fff;
      opacity: 0.3;
    }

    &.active {
      span {
        opacity: 1;
      }

      &:hover {
        background-color: #2f2fae;
      }
    }

    &.bw-50 {
      width: 50%;
    }

    &.bw-100 {
      width: 100%;
    }
  }
}

.bottom-fixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  .btn-cancel-pay {
    display: none;
  }
}

// LAYER
.wrap-layer {
  box-sizing: border-box;
  width: 550px;
  height: auto;
  padding-top: 0 !important;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2);

  &.viewsWrap {
    border-radius: 0;
  }

  header {
    position: relative;
    display: block;
    height: 60px;
    text-align: center;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 60px;
      color: #333;
    }

    &.head_left {
      h2 {
        padding: 0 30px;
        font-size: 18px;
        text-align: left;
      }
    }
  }

  .btn-close {
    @include retina-sprite($sp-button-btn-popup-close-nor-group);

    position: absolute;
    top: -30px;
    right: 0;

    span {
      @include hide_text;
    }
  }

  section.layer-content {
    display: block;
    height: 100%;

    .layer-content-inner {
      padding: 1.25rem 1.25rem 3rem;
    }
  }

  header + section.layer-content {
    height: auto;
    padding-bottom: 10px;
  }

  .bottom-fixed {
    position: static;
    right: auto;
    bottom: auto;
    left: auto;
    width: 89.09%;
    margin: 0 auto 30px;
  }

  &.sm-layer {
    width: 400px;

    header {
      height: auto;

      h2 {
        padding: 16px 40px 20px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .layer-content {
      .layer-content-inner {
        padding: 10px 40px 40px;
      }
    }
  }

  &.small-layer {
    width: 400px;

    header {
      height: 80px;

      h2 {
        padding: 0 30px;
        font-size: 18px;
        line-height: 80px;
        text-align: left;
      }
    }

    .layer-content {
      .layer-content-inner {
        padding: 0 30px 60px;
      }
    }

    // 이용권 결제 상세 내역
    .payment-complete_list {
      padding: 0;

      .list-sum {
        width: 100%;

        li {
          height: auto;
          padding: 13px 0 12px;
          border-top: 1px dotted #e7e7e7;

          &:nth-of-type(1) {
            border-top: 1px solid #e7e7e7;
          }

          &.final {
            height: 56px;
            padding: 0;
            border-top: 1px solid #e7e7e7;

            small {
              vertical-align: top;
            }
          }

          .th,
          .td {
            padding-top: 2px;
            font-size: 15px;
            vertical-align: top;
          }
        }
      }
    }
  }

  // 하단 링크 : 이용약관, 개인정보처리방침, 청소년 보호정책
  // 회원가입시 전문보기 : 이용약관,  개인정보 수집 및 이용 안내, 제 3자 제공 동의, 제 3자 제공, 처리위탁 동의, 광고 수신 동의
  &.TermsDocs_Wrapper,
  &.PrivacyDocs_Wrapper,
  &.JuvenileProtectionDocs_Wrapper,
  &.layerDocsPrivacy_Wrapper,
  &.layerDocsTerms_Wrapper,
  &.layerDocs3rdPrivacy_Wrapper,
  &.layerDocs3rdPrivacyO_Wrapper,
  &.layerDocsAdvertise_Wrapper {
    width: 80% !important;
    max-width: 960px;

    &:not(.desktopFull) {
      z-index: 510;
    }

    .iscrollView {
      @media screen and (max-height: 580px) {
        @include iscrollViewHeight;
      }
    }

    &.desktopFull {
      @media screen and (max-height: 590px) {
        height: auto !important;
      }
    }
  }

  &.channel-description_Wrapper,
  &.artist-list-modal_Wrapper {
    height: auto !important;

    header {
      height: 0;

      h2 {
        width: 0;
        height: 0;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        color: transparent;
      }
    }

    section.layer-content {
      .layer-content-inner {
        padding-bottom: 0.3125rem;
      }
    }

    .iscrollView {
      height: auto;
      min-height: 305px;
      max-height: 500px;

      @media screen and (max-height: 580px) {
        min-height: auto;
        @include iscrollViewHeight;
      }
    }

    @media screen and (max-height: 735px) {
      margin-top: -50px;
    }
  }
}

@media (max-width: 575px) {
  .wrap-layer {
    width: 88%;
    height: 600px !important;

    /* SCSS 파일 내 ::v-deep pseudo-class는 에러로 출력됨. 추후 vue 파일 내로 migration 예정 */
    /* stylelint-disable-next-line selector-pseudo-element-no-unknown */
    ::v-deep .type-policy {
      height: 450px;
      padding: 30px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

.header-web {
  display: block;
}

.header {
  display: none;
}

footer {
  display: block;
}

// 용도 불명
.btnListMore {
  width: 50%;
  height: 3.75rem;
  margin-top: 0.9375rem;
  line-height: 3.75rem;
  background-color: #fff;

  span {
    position: relative;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.1;
    color: #999;

    &::before {
      position: absolute;
      top: -1px;
      left: -25px;
      width: 20px;
      height: 20px;
      content: '';
      background: url('~@/assets/img/btn_more.png') 0 0 no-repeat;
      background-size: 100%;
    }
  }
}

.iscrollView {
  position: relative;
  height: 500px;
  overflow: hidden;
  touch-action: none;
}

.desktopFull {
  z-index: 999;
  height: 590px !important;
}

.sort_type_wrap_coupon {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 15;
  width: auto;
  color: #000;

  button {
    width: 97px;
    height: 32px;

    // padding-right: 15px;
    font-size: 13px;
    color: #000;
    border: solid 0.5px #d2d2d2;
    border-radius: 16px;

    &:hover {
      color: #3f3fff;
      cursor: pointer;
      border: solid 1px #3f3fff;
    }

    &.on {
      color: #000;
      cursor: pointer;
      border: solid 1px #ebebeb;
      opacity: 0.1;
    }
  }
}

// @media (max-width: 650px) {
//	.iscrollView {
//		height:400px
//	}
// }

// @media screen and (min-height:601px) and (max-height: 820px) {
//	.iscrollView {
//		height:300px
//	}
// }

// @media screen and (min-height: 501px) and (max-height: 600px) {
//	.iscrollView {
//		height:200px
//	}
// }

// @media screen and (min-height: 300px) and (max-height: 500px) {
//	.iscrollView {
//		height:130px
//	}
// }

.section_purchase {
  .disabled {
    span {
      opacity: 0.3;
    }
  }
}

.payment-form_membership {
  padding: 20px 20px 0;

  h4 {
    height: 25px;
    font-size: 14px;
    font-weight: 600;
    color: #181818;

    .ico-t {
      width: 16px;
      height: 15px;
      margin-right: 3px;
      background: url('~@/assets/img/img_skt_logo_m2.png') 0 0 no-repeat;
      background-size: 100%;
    }
  }

  .membership-policy {
    position: relative;

    .txtBtn {
      position: absolute;
      top: 2px;

      &:hover {
        span {
          color: #3f3fff;
        }
      }
    }

    .btn-view {
      position: absolute;
      left: 10rem;
      font-size: 13px;
      color: #929292;
    }

    .btn-lookup {
      position: absolute;
      right: 0;
      padding-right: 15px;
      font-size: 13px;
      color: #db2359;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 15px;
        content: '';
        background: url('~@/assets/img/icon_sub_arrow_red.png') 0 0 no-repeat;
        background-size: 100%;
      }
    }
  }
}

.tail-text {
  width: 88.88%;
  padding: 18px 0 0;
  margin: 0 auto;
}

.list-sum {
  width: 100%;

  li {
    display: table;
    width: 100%;
    min-height: 35px;
    color: #333;
    table-layout: auto;

    .th,
    .td {
      display: table-cell;
      vertical-align: middle;
    }

    .td {
      padding-right: 20px;
      font-size: 16px;
      text-align: right;
    }

    .th {
      padding-right: 15px;
      padding-left: 20px;
      font-size: 15px;
      color: #aaa;

      small {
        padding-left: 3px;
        font-size: 12px;
        color: #989898;
      }
    }

    &.final {
      .th {
        color: #333;
      }

      .discounted {
        font-size: 22px;
        font-weight: 600;
        color: #3f3fff;
      }

      .td {
        font-size: 20px !important;
        font-weight: 600;
        color: #3f3fff;
      }
    }

    &.next_payment_start {
      position: relative;
      padding-top: 10px;
      margin-top: 10px;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: block;
        width: 90%;
        height: 1px;
        margin: 0 auto;
        content: '';
        background: #ebebeb;
      }
    }
  }
}

.pass-notice {
  width: 88.88%;
  padding-bottom: 35px;
  margin: 0 auto;
  text-align: left;

  h3 {
    height: 1.4375rem;
    font-size: 0.8125rem;
    color: #181818;
  }

  li {
    font-size: 0.75rem;
    line-height: 1.4;
    color: #929292;
    word-break: keep-all;

    em {
      color: #d83a4d;
    }

    a {
      color: #3f3fff;
      text-decoration: underline;
    }
  }

  ul.dot {
    li {
      position: relative;
      padding: 0 0 9px 10px;

      &::before {
        position: absolute;
        top: -9px;
        left: 0;
        font-size: 20px;
        line-height: 20px;
        color: #929292;
        content: '.';
      }
    }
  }

  ul.no-dot {
    > li {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }

  ol {
    margin-left: 15px;

    &,
    li {
      list-style: initial;
      list-style-type: decimal;
    }

    li {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }
}

/* 쿠폰등록 */
.lyr-regicoupon {
  box-sizing: border-box;
  width: 550px;
  padding: 0 5.45%;
  padding-bottom: 30px;

  .input-number {
    position: relative;
    box-sizing: border-box;
    padding-top: 4px;
    text-align: left;

    input.inputTxt {
      width: 100%;
      height: 4rem;
      font-size: 0.9375rem;
      color: #181818;
      border: 0;
      border-bottom: 1px solid #ebebeb;

      &::placeholder {
        font-weight: 400;
        color: #c4c4c4;
      }
    }

    .msg-incorrect {
      position: absolute;
      bottom: 2px;
      left: 0;
      display: none;
      font-size: 0.625rem;
      color: #ff4d78;
    }

    &.incorrect {
      .msg-incorrect {
        display: block;
      }

      border-bottom: 1px solid #ff4d78;
    }

    &.active {
      .inputTxt {
        border: 0;
        border-bottom: 1px solid #181818;
      }
    }
  }

  .btn-wrap {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 0;

    a {
      width: 100%;
    }
  }

  .tail-notice {
    padding-top: 1.25rem;

    li {
      font-size: 13px;
      color: #999;
    }
  }

  @media screen and (max-width: 575px) {
    width: auto;
  }
}

/* T멤버십 조회 */
.lyr-tmembership {
  .iscrollView {
    height: 470px;

    .layer-content-inner {
      padding-bottom: 100px;
    }
  }

  text-align: left;

  .layer-content-inner {
    width: 89.09%;
    padding: 1.25rem 0 0 !important;
    margin: 0 auto;
  }

  .card-number {
    position: relative;
    height: 1.875rem;

    .card-number_title {
      font-size: 15px;
      color: #666;
    }

    .card-number_num {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0.9375rem;
      font-weight: 500;
      color: #333;
    }
  }

  .input-number {
    position: relative;
    box-sizing: border-box;
    height: 58px;
    padding-top: 1.0625rem;
    margin-bottom: 30px;
    border-bottom: 1px solid #181818;

    .ip-birthday {
      width: 70%;
      height: 1.6875rem;
      font-size: 0.9375rem;
      font-weight: 500;
      line-height: 1.625rem;
      color: #333;

      &::placeholder {
        font-weight: 400;
        color: #c4c4c4;
      }
    }

    .ip-reset {
      position: absolute;
      top: 1.2rem;
      right: 4.375rem;
      display: none;
    }

    .msg-incorrect {
      position: absolute;
      bottom: 2px;
      left: 0;
      display: none;
      font-size: 0.6875rem;
      color: #ff4d78;
    }

    .btn-search {
      position: absolute;
      top: 17px;
      right: 0;
      width: 60px;
      height: 26px;
      font-size: 12px;
      color: #fff;
      background-color: #ccc;
      border: 0;
      border-radius: 13px;
    }

    &.active {
      .btn-search {
        background-color: #3f3fff;
      }

      .ip-reset {
        display: block;
      }
    }

    &.incorrect {
      border-bottom: 1px solid #ff4d78;

      .msg-incorrect {
        display: block;
      }
    }
  }

  .result-tmembership {
    padding-top: 10px;

    h4 {
      height: 34px;
      font-size: 15px;
      font-weight: 600;
      color: #181818;
    }

    .result-tms_count {
      position: relative;
      height: 3.125rem;
      padding-left: 1.25rem;
      margin-bottom: 2.8125rem;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 3.125rem;
      color: #181818;
      background-color: #f8f8f8;

      small {
        font-size: 0.6875rem;
        color: #999;
      }

      em {
        position: absolute;
        top: 0;
        right: 1.25rem;
        font-size: 0.9375rem;
        font-weight: 500;
        color: #db2359;
      }

      .msg-unable {
        position: absolute;
        bottom: -1.0625rem;
        left: 2px;
        font-size: 0.75rem;
        line-height: 1.1;
        color: #db2359;
      }
    }

    .select-deduction {
      label {
        display: inline-block;
        width: 50%;

        span {
          font-size: 0.9375rem;
          color: #333;
        }
      }
    }

    .tail-notice {
      padding: 40px 0 1.875rem;

      .emphasis {
        color: #333;
      }
    }
  }
}

.layerSktDiscount_Wrapper {
  .bottom-fixed {
    z-index: 10;
  }
}

// .layerSktDiscountPolicy_Wrapper {
//     height:420px !important;
// }

.payment-complete_list {
  padding: 20px 40px 45px;

  h4 {
    width: 400px;
    padding: 10px 0;
    margin: 0 auto 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    border-bottom: 1px solid #e7e7e7;
  }

  .list-sum {
    width: 400px;
    margin: 0 auto;

    li {
      height: 35px;
      min-height: 22px;
      padding: 0;
      border: 0;

      &:nth-of-type(1) {
        border-top: 0;
      }

      span {
        padding: 0;
        font-size: 0.8125rem;

        &.emColor {
          color: #db2359;
        }
      }

      .th {
        padding-right: 15px;
        font-size: 15px;
        font-weight: 600;
        color: #aaa;
        white-space: nowrap;
      }

      .td {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        word-break: break-all;

        span {
          font-size: inherit;
        }
      }

      &.final {
        height: 56px;
        padding: 0;
        margin: 10px 0;
        border-top: 1px dashed #ebebeb;
        border-bottom: 1px solid #ebebeb;

        .th {
          font-weight: 600;
          line-height: 56px;
          color: #333;

          small {
            font-size: 12px;
            color: #aaa;
          }
        }

        .td {
          font-size: 24px;
          font-weight: 600;
          line-height: 56px;
          color: #3f3fff;
        }
      }

      &.next-payment {
        height: 20px;
        padding: 10px 0 0;

        .th,
        .td {
          color: #666;
        }
      }

      &.np-date {
        padding-top: 15px;
      }

      &.line-dot {
        border-top: 1px dashed #e7e7e7;
      }

      &.with-vas {
        .th {
          small {
            display: block;
          }
        }

        .td {
          em {
            display: inline-block;
            max-width: 147px;
            margin-right: 12px;
            overflow: hidden;
            font-size: 11px;
            color: #aaa;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.disableVasNextPaymentPrice {
          .td {
            vertical-align: middle;

            em {
              margin-top: -3px;
              vertical-align: middle;
            }
          }
        }
      }

      &.vas-product {
        padding: 0;
        margin-top: -8px;
      }
    }
  }

  .list-nextpayment {
    margin-top: 10px;

    li {
      display: table;
      width: 100%;
      padding: 4px 0;
      font-size: 12px;
      font-weight: 600;

      .th {
        display: table-cell;
        padding-right: 15px;
        color: #333;
        white-space: nowrap;
      }

      .td {
        display: table-cell;
        color: #666;
        text-align: right;
      }
    }
  }

  .payment-complete-next-w {
    margin-top: 10px;

    h4 {
      em {
        float: right;
        margin: 2px 0 0;
        font-size: 0.8125rem;
      }
    }

    .list-sum {
      .with-vas {
        .th {
          padding-top: 16px;
        }
      }

      .vas-product {
        margin-top: -16px;

        .th {
          padding-top: 0;
        }
      }
    }
  }
}

// 용도 불명
.bannerWrap {
  width: 100%;
  max-width: 960px;
  padding-bottom: 10px;
  margin: 30px auto 0;

  a {
    display: block;
    width: 100%;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }
}

.list-msg_nohistory {
  padding-top: 50px;
  font-size: 0.875rem;
  color: #989898;
  text-align: center;
}

// 용도 불명
.block-myvoucher {
  position: relative;
  box-sizing: border-box;
  width: 89%;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: left;
  border: 1px solid #d9d9d9;

  h3 {
    display: inline-block;
    padding-bottom: 18px;
    font-size: 20px;
    font-weight: 600;
    color: #000;

    .voucher-state {
      display: inline-block;
      padding: 0 0 2px 8px;
      font-size: 11px;
      color: #ff4d78;
      vertical-align: bottom;
    }
  }

  ul {
    li {
      position: relative;
      height: 24px;
    }

    .th {
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      font-weight: 600;
      color: #000;
    }

    .td {
      padding-left: 70px;
      color: #999;
    }

    .th,
    .td {
      display: inline-block;
      font-size: 12px;
      vertical-align: top;
    }
  }

  .btn-admin {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: block;
    width: 86px;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
    line-height: 30px;
    color: #fff;
    text-align: center;
    background-color: #3f3fff;
  }
}

body.tid {
  #wrap {
    min-width: unset;

    .sign-brandlogo {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .sign-container {
      padding-bottom: 50px;
      margin-top: 40px;
    }
  }
}

body.terdef {
  #app {
    padding-bottom: 0;

    #wrap {
      min-width: inherit;

      // .PAYMENT_Wrap {
      //     padding-bottom:0;
      // }
    }
  }
}

body.promotion {
  #app {
    padding-bottom: 0;
  }
}

.termination-wrap {
  height: auto !important;
  min-height: 680px !important;
  padding-bottom: 90px;
}

.check-termination {
  text-align: left;

  .form-check span {
    padding-left: 20px;
  }
}

.form-termination,
.payment-refund {
  + .pass-notice,
  + .btn-wrap + .pass-notice {
    padding-top: 30px;
  }
}

.voucher-refund-wrap {
  padding-bottom: 60px;

  .pass-notice {
    padding-bottom: 75px;
  }

  header {
    h2 {
      font-size: 17px;
    }
  }
}

.desktop {
  .check-termination {
    padding-bottom: 50px;

    .form-check span {
      padding-left: 30px;
    }

    + .btn-wrap {
      width: 88.88%;

      a {
        width: 100% !important;
      }
    }
  }

  .voucher-refund-wrap {
    position: relative;
    top: 0;
    left: 0;
    padding-top: 18px !important;
    margin: 0;
    box-shadow: none;
    transform: translate(0, 0);

    header {
      padding-bottom: 18px;
    }

    .btn-close {
      position: absolute;
      top: 0.9375rem;
      right: 1.25rem;
      width: 1.5rem;
      height: 1.5rem;
      text-indent: -1000em;
      background: url('~@/assets/img/icon_popup_close_bk.png') 0 0 no-repeat;
      background-size: 100%;
      border: 0;
    }
  }

  .payment-refund,
  .payment-form {
    + .btn-wrap {
      width: 100%;
      margin-top: 40px;

      a {
        width: 88.88%;
      }
    }
  }

  .pass-notice {
    padding-bottom: 35px;
  }

  .btn-wrap.bottom-fixed {
    .btn_payment_refund {
      width: 100%;
    }
  }
}

.iscrollPaymentRefund {
  .pass-notice {
    position: relative;
    margin-top: 30px;
  }
}

.desktop .iscrollPaymentRefund {
  z-index: 1;
  height: auto !important;

  .pass-notice {
    padding-bottom: 30px;
  }
}

.layerPaymentRefund_Wrapper {
  .iscrollPaymentRefund {
    z-index: -1;
  }
}

.my-voucher_state_cancel {
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 88.88%;
  padding: 40px;
  margin: 0 auto 12px;
  text-align: left;
  background-color: #525cfd;
  border-radius: 10px;

  .voucher-title {
    padding-top: 5px;
    padding-right: 65px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    word-break: break-all;
    word-wrap: break-word;
  }

  .voucher-state {
    position: absolute;
    top: 43px;
    right: 40px;
    box-sizing: border-box;
    display: block;
    min-width: 48px;
    height: 24px;
    padding: 0 7px;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    color: #fff;
    text-align: center;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 24px;
      content: '';
      border: 1px solid #fff;
      border-radius: 15px;
      opacity: 0.5;
    }
  }

  ul {
    margin: 24px 0 0;

    li {
      position: relative;

      span {
        display: inline-block;
        line-height: 22px;

        &.th {
          width: 45px;
          margin-right: 7px;
          font-size: 12px;
          color: #fff;
          opacity: 0.6;
        }

        &.td {
          font-size: 12px;
          font-weight: 500;
          color: #fff;

          a {
            color: #fff;
          }
        }
      }

      .link-layer {
        position: absolute;
        top: 50%;
        right: 0;
        padding: 0;
        transform: translateY(-50%);
      }
    }

    & + .tail-msg {
      margin-top: 30px;
    }
  }

  .link-layer {
    position: relative;
    display: inline-block;
    padding: 10px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: left;
    border-bottom: 1px solid #fff;

    &.disable {
      opacity: 0.4;
    }
  }

  .tail-msg {
    display: block;
    margin-top: 20px;
    font-size: 12px;
    line-height: 22px;
    color: #fff;
    opacity: 0.6;
  }

  .progress-bar {
    position: relative;
    display: block;
    padding-top: 18px;

    progress {
      display: block;
      width: 100%;
      height: 3px;
      color: #fff;
      appearance: none;
      background-color: rgba(255, 255, 255, 0.14);
      border: none;
      border-radius: 1.5px;

      &::-ms-fill {
        border: none;
        border-radius: 1.5px;
      }
    }

    progress[value] {
      &::-webkit-progress-bar {
        background-color: rgba(255, 255, 255, 0.14);
        border-radius: 1.5px;
      }

      &::-webkit-progress-value {
        background-color: #fff;
        border-radius: 1.5px;
      }

      &::-moz-progress-bar {
        background-color: #fff;
        border-radius: 1.5px;
      }

      &::-ms-fill {
        background-color: #fff;
        border-radius: 1.5px;
      }
    }

    .count-playing {
      position: relative;
      display: block;
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.1;
      color: #fff;
    }

    .count-total {
      position: absolute;
      top: 30px;
      right: 0;
      font-size: 12px;
      color: #fff;
      opacity: 0.4;
    }
  }

  .PAYMENT_Wrap & {
    h1 {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
    }
  }
}

.cancelr-cancel {
  margin-top: 20px;
  clear: both;
  text-align: center;

  .btn-cancelr-cancel {
    display: inline-block;
    min-width: 154px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: #525cfd;
    background: #fff;
    border-radius: 5px;
  }
}

// 해지프로세스 개선
.em-color-blue {
  color: #3f3fff !important;
}

.termination-popup-list {
  margin-top: -5px;

  li {
    position: relative;
    padding-left: 14px;
    margin-top: 5px;

    .num {
      position: absolute;
      left: 0;
    }
  }
}

.termination-popup-reason {
  margin-top: -10px;

  li {
    margin-top: 10px;

    .form-check {
      > span {
        font-size: 0.9375rem;
        color: #181818;
      }
    }
  }
}

.termination-title {
  margin-bottom: 30px;

  // font-size: 0.9375rem;
  font-weight: 400;
}

.termination-popup-exp {
  margin-top: 20px;
}

.payment-refund-list-notice {
  width: 88.88%;
  margin: 15px auto 0;
  font-size: 12px;
  line-height: 17px;
  text-align: left;

  h3 {
    position: relative;
    padding: 0 0 0 15px;
    font-size: 12px;
    font-weight: normal;
    color: #666;

    &::before {
      position: absolute;
      top: 2px;
      left: 0;
      width: 13px;
      height: 13px;
      content: '';
      background: url('~@/assets/img/icon_info_02.png') 0 0 no-repeat;
      background-size: 100% auto;
    }
  }

  p {
    margin-top: 2px;
    color: #989898;

    strong {
      display: block;
      font-weight: normal;
      color: #666;
    }
  }
}

// 회원정보추가 레이어
.layer-member-userformadd_Wrapper {
  .sign-container {
    width: 100%;
    padding: 0 50px;
    margin: 0 auto 50px;
    border: 0;

    h1 {
      @include signContainerTitleSize('small');
    }
  }

  .sign-brandlogo {
    margin: 50px auto;
  }
}

// 홈, 상세 타이틀에서 사용되는 seed 명
.title_seed {
  &::before,
  &::after {
    content: "'";
  }
}
