
          @import '@/assets/scss/common/color.scss';
        

.music-playlist-top {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 80px;
  }

  &__search {
    position: relative;
    display: flex;
    align-items: center;
    width: 240px;
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1000px;

    &.disabled {
      opacity: 0.4;
    }
  }

  &__input {
    width: 195px;
    font-size: 12px;
    color: #fff;
    background: none;
    outline: none;

    &.disabled {
      color: #484848;
    }
  }

  &__cancel {
    position: absolute;
    top: 6px;
    right: 10px;
  }

  &__my-list {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    color: #989898;
  }

  &__fold {
    position: absolute;
    right: 8px;
    width: 50px;
    margin-left: 24px;
    font-size: 14px;
    color: #989898;

    &.disabled {
      color: #484848;
    }
  }
}
