@import 'helpers.variable';

@mixin input_default {
  font-size: 13px;
  border: 1px solid #d2d2d2;
}

@mixin hide_text {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  vertical-align: middle;
}

@mixin align_middle($left, $top, $h, $v) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate($h, $v);
}

@mixin ellipsis1 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// ie대응을 위해서 ie_ellipsis와 함꼐 사용필수 (ie_ellipsis를 아래 import하기)
// ex)
// @include ellipsis_muti(2,'')
// @include ie_ellipsis;

@mixin ellipsis_multi($line, $height) {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  @if $height != '' {
    height: #{$height}px;
  }

  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}

@mixin wordbreak {
  word-break: break-all;
  word-wrap: break-word;
}

@mixin sp_mplayer {
  background-image: url($sp_mplayer);
}

@mixin thumb_round($size) {
  display: inline-block;
  width: $size;
  height: $size;
  vertical-align: middle;
  background-position: left top;
  background-size: 100% auto;
  border-radius: $size;
}

@mixin thumb_rect($size) {
  @include thumb_round($size);

  border-radius: calc($size / 10);
}

@mixin show_dimd {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    content: '';
    background: #000;
    opacity: 0.6;
  }
}

// progress_container
@mixin track() {
  height: 6px;
  background-color: #323232;
}

@mixin fill() {
  height: 6px;
  background-color: #3f3fff;
}

@mixin thumb() {
  width: 0;
  height: 0;
  background: transparent;
  border: 0 none;
}

// Layer Style
@mixin layerStyle($width) {
  position: absolute;
  z-index: 10;
  width: $width;
  background: #fff;

  // border:1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

// Dropdown List Style
@mixin dropdownListStyle($height) {
  display: block;
  height: $height;
  font-size: 14px;
  line-height: $height;
  color: $font_default;

  &:hover {
    background-color: $bg_hover;
  }

  &:active {
    background-color: $bg_active;
  }
}

// thumbnail playlist
@mixin thumbnailShadow($size: 's') {
  position: absolute;
  top: if($size == 's', 4px, 9px);
  left: 50%;
  z-index: if($size == 's', 1, 2);
  display: block;
  width: if($size == 's', 88%, 95%);
  height: if($size == 's', 88%, 95%);
  content: '';
  background-color: if($size == 's', #f3f3f3, #e3e3e3);
  border-radius: 6px;
  transform: translate(-50%, 0);
}

// Media Query
$mediaquery-width-1024: 1024px;

@mixin mediaquery-1024 {
  @media screen and (max-width: #{$mediaquery-width-1024 + 1}) {
    @content;
  }
}

$break-small: $min_width;
$break-large: 1440px;

@mixin respond-to($media) {
  @if $media == mediaquery-small {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media == mediaquery-large {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  } @else if $media == mediaquery-min {
    @media only screen and (min-width: $break-small) {
      @content;
    }
  }
}

// psuedo clear both
@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

// multiple artist thumb
@mixin multiple_artist($thumb-size, $overlap, $length) {
  @include clearfix;

  float: left;
  padding-right: $overlap;

  li {
    @include thumb_round($thumb-size);

    position: relative;
    float: left;
    margin-right: -$overlap;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    $max-length: $length;
    @for $length from 1 through $max-length {
      &:nth-child(#{$length}) {
        z-index: ($max-length * 10) - (($length - 1) * 10);
      }
    }
  }
}

// prefix
@mixin vendor_prefix($property, $values) {
  -webkit-#{$property}: #{$values};
  -moz-#{$property}: #{$values};
  -ms-#{$property}: #{$values};
  -o-#{$property}: #{$values};
  #{$property}: #{$values};
}

// 애니메이션 키프레임
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

// scroll style
@mixin scroll_style {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

// ie multiline 대응 , 필요 변수 (max-height, 말줄임처리 필요한 줄 수, 그라데이션 사이즈(기본 26px))
// 앞으로 ie 대응은 1줄 말줄임으로 통일 (ie_ellipsis 사용)
// ie_multiline mixin은 대응하는 영역이 있기에 유지함
@mixin ie_multiline($maxHeight, $multiline, $size: 'default') {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
    max-height: $maxHeight;

    &::after {
      position: absolute;
      top: $maxHeight - calc($maxHeight / $multiline);
      right: 0;
      height: calc($maxHeight / $multiline);
      content: '';
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 70%,
        rgba(255, 255, 255, 1) 100%
      );
      @if $size == 'default' {
        width: 26px;
      } @else if $size == 'large' {
        width: 36px;
      }
    }
  }
}

@mixin searchDimmed($opacity: 0.6) {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  content: '';
  background-color: rgba(255, 255, 255, $opacity);
}

@mixin progressInThumb($borderRadius: 4px, $height: 4px) {
  height: $height;
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $borderRadius;

  .label-thumb-progressbar {
    top: auto;
    right: auto;
    left: auto;
    height: 1px;
    cursor: none;
  }

  progress {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    appearance: none;
    border: 0;

    &::-webkit-progress-bar {
      background: transparent;
      border-radius: $borderRadius;
    }

    &::-webkit-progress-value {
      background-color: $key_color;
      border-radius: $borderRadius;
    }

    &::-moz-progress-bar {
      background-color: $key_color;
      border-radius: $borderRadius;
    }

    &::-ms-fill {
      background-color: transparent;
      border: none;
      border-radius: $borderRadius;
    }
  }

  progress[value] {
    &::-ms-fill {
      background-color: $key_color;
      border-radius: $borderRadius;
    }
  }
}

//  로그인, 회원가입 페이지 타이틀
@mixin signContainerTitle {
  position: static;
  display: block;
  width: auto;
  height: auto;
  color: #181818;
  text-align: center;

  @include signContainerTitlePb;
  @include signContainerTitleSize;
}

//  로그인, 회원가입 페이지 타이틀 여백
@mixin signContainerTitlePb($_size: 'default') {
  @if $_size == 'small' {
    padding-bottom: 15px;
  } @else if $_size == 'large' {
    padding-bottom: 40px;
  } @else {
    padding-bottom: 30px;
  }
}

//  로그인, 회원가입 페이지 타이틀 사이즈
@mixin signContainerTitleSize($_size: 'default') {
  @if $_size == 'small' {
    font-size: 24px;
  } @else {
    font-size: 30px;
  }
}

@mixin ie_ellipsis {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @include ellipsis1;
  }
}

// 화살표 라인
// 사용법
// .arrow-line-up {
//     @include arrow(2px, 10px, #ddd, up)
// }
@mixin arrow($line-width: 4px, $size: 4px, $color: #000, $direction: down) {
  display: inline-block;
  padding: $size;
  margin-top: -$size;
  border: solid $color;
  border-width: 0 $line-width $line-width 0;

  @if $direction == down {
    transform: rotate(45deg);
  } @else if $direction == up {
    transform: rotate(-135deg);
  } @else if $direction == left {
    transform: rotate(135deg);
  } @else if $direction == right {
    transform: rotate(-45deg);
  }
}
