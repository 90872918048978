@import 'player.button';

$player-wrapper-background-color: #262626;
$album-thumb-width: 402px;
$album-thumb-width2: 40px;
$mini-right-buttons-position-top: 40px;

// common
.player_ct {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  // 트랙 영억
  .track_area {
    .thumb {
      overflow: hidden;
      background-size: 100% 100%;
    }

    .track_info {
      .title {
        @include ellipsis1;

        color: #fff;
      }

      .artist {
        @include ellipsis1;

        color: #989898;
      }
    }

    .ico_adult {
      @include hide_text;

      display: inline-block;
      width: 0;
    }

    .ico_free {
      @include hide_text;

      display: inline-block;
      width: 0;
    }
  }

  // 재생 시간, 프로그래스바
  .playtime {
    // progress
    input[type='range'].progress {
      width: 100%;
      margin: 0;
      overflow: hidden;
      vertical-align: top;
      cursor: pointer;
      background: transparent;
      border: 0;

      &.chrome-bug-fix {
        &::-webkit-slider-thumb {
          @include thumb;

          box-shadow: -100vw 0 0 100vw transparent;
        }
      }

      &:focus {
        outline: 0 none;
      }

      &,
      &::-webkit-slider-thumb {
        appearance: none;
      }

      &::-webkit-slider-runnable-track {
        @include track;
      }

      &::-moz-range-track {
        @include track;
      }

      &::-ms-track {
        @include track;
      }

      &::-webkit-slider-thumb {
        @include thumb;

        box-shadow: -100vw 0 0 100vw $font_purple;
      }

      &::-moz-range-thumb {
        @include thumb;
      }

      &::-ms-thumb {
        @include thumb;
      }

      &::-ms-tooltip {
        display: none;
      }

      &::-moz-range-progress {
        @include fill;
      }

      &::-ms-fill-lower {
        @include fill;
      }
    }

    .time_current {
      color: #a0a0a0;
    }

    .time_all {
      color: #646464;
    }
  }

  // 컨트롤 영역
  .control_area {
    position: relative;
    text-align: center;

    button {
      position: relative;
      @include hide_text;
    }

    .loading-circle {
      position: absolute;
      top: 18px;
      left: 50%;
      margin-left: -27px;
    }
  }

  .util_area {
    button[class^='btn_'] {
      @include hide_text;
    }
  }
}

// 전체 사이즈
.player_ct.full {
  top: 0;
  right: -$is-scroll-space;
  z-index: 300;
  padding-right: $is-scroll-space;
  overflow-y: auto;
  background: $player-wrapper-background-color;

  .playbar_wrap {
    padding-top: 140px;
    padding-bottom: 50px;

    .playbar_ct {
      width: $player-ct-width;
    }

    // 트랙 정보
    .track_area {
      position: relative;
      margin-bottom: 157px;

      // 썸네일
      .thumb {
        position: relative;
        width: $album-thumb-width;
        height: $album-thumb-width;
        margin: 0 auto;
        border-radius: 10px;

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 201px;
          content: '';
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.5)
          ); // TODO: 정확한 alpha값 확인 필요 (제플린 확인 안됨)
        }

        .icon-original {
          position: absolute;
          top: 20px;
          left: 23px;

          @include retina-sprite($sp-icon-icon-flo-original-player-group);
        }
      }

      // 가사
      .lyrics {
        position: absolute;

        @at-root {
          .show_lyrics {
            .lyrics {
              // 가사 영역의 위치가 썸네일을 기준으로 위에서 38px, 아래로 27px 떨어져있음
              top: 0;
              right: 0;
              bottom: -27px;
              left: 0;
              padding-top: 38px;
              background: $player-wrapper-background-color;

              // 등록된 가사 없을 경우
              &.no_lyrics {
                .txt {
                  position: relative;
                }

                .focus {
                  @include align_middle(50%, 50%, -50%, -50%);
                }
              }

              .txt {
                height: 100%;
                padding-right: 100px;
                overflow-y: auto;
              }
            }
          }

          .hide_lyrics {
            .lyrics {
              top: 0;
              bottom: 0;
              left: 50%;
              width: $album-thumb-width;
              padding-top: 350px;
              cursor: pointer;
              transform: translateX(-50%);

              p {
                display: none;

                &.focus {
                  display: block;
                  text-align: center;
                }
              }

              pre {
                display: none;
              }

              button[class^='btn_'] {
                display: none;
              }
            }
          }
        }

        // .txt {
        // 	overflow-y: auto;
        // }
        p,
        pre {
          font-size: 15px;
          line-height: 2;
          color: #646464;
        }

        p.focus {
          font-weight: bold;
          color: #fff;
        }

        p.hide {
          display: none;
        }

        pre {
          margin: 0;
          white-space: pre-wrap;
        }

        // 닫기 버튼
        .btn_lyrics_close {
          position: absolute;
          top: -25px;
          left: 0;
          font-size: 14px;
          color: #fff;

          i {
            display: inline-block;
            margin-right: 9px;
            vertical-align: middle;
          }
        }

        // 위치 바로 가기 버튼
        .btn_lyrics_focus {
          @include hide_text;

          position: absolute;
          top: 38px;
          right: 30px;
        }
      }

      // 트랙 타이틀
      .track_info {
        position: absolute;
        bottom: -132px;
        width: 420px;

        .title {
          font-size: 22px;
          font-weight: bold;
          color: #fff;
        }

        .artist {
          padding-top: 9px;
          font-size: 13px;
          color: #989898;
        }

        .no_list {
          display: none;
        }
      }
    }

    // 플레이어 컨트롤러
    .player_controller {
      position: relative;
      padding-bottom: 85px;
    }

    // 재생 시간, 프로그래스바
    .playtime {
      position: relative;
      height: 6px;

      // margin-top: 25px;
      padding-bottom: 30px;

      // progress
      input[type='range'].progress {
        width: 100%;
        margin: 0;
        overflow: hidden;
        vertical-align: top;
        cursor: pointer;
        background: transparent;
        border: 0;

        &.chrome-bug-fix {
          &::-webkit-slider-thumb {
            @include thumb;

            box-shadow: -100vw 0 0 100vw transparent;
          }
        }

        &:focus {
          outline: 0 none;
        }

        &,
        &::-webkit-slider-thumb {
          appearance: none;
        }

        &::-webkit-slider-runnable-track {
          @include track;
        }

        &::-moz-range-track {
          @include track;
        }

        &::-ms-track {
          @include track;
        }

        &::-webkit-slider-thumb {
          @include thumb;

          box-shadow: -100vw 0 0 100vw $font_purple;
        }

        &::-moz-range-thumb {
          @include thumb;
        }

        &::-ms-thumb {
          @include thumb;
        }

        &::-ms-tooltip {
          display: none;
        }

        &::-moz-range-progress {
          @include fill;
        }

        &::-ms-fill-lower {
          @include fill;
        }
      }

      span[class^='time_'] {
        position: absolute;
        bottom: 0;
        font-size: 13px;
      }

      .time_current {
        left: 0;
        font-weight: bold;
      }

      .time_all {
        right: 0;
        text-align: right;
      }
    }

    // 재생 컨트롤러
    .control_area {
      padding-top: 30px;

      button {
        margin: 0 14px;
      }

      .loading-circle {
        top: 50%;
        margin-top: -11px;
        margin-left: -26px;

        .circle-line2 {
          border-right-color: #262626;
        }
      }
    }

    // 유틸
    .util_area {
      position: absolute;
      top: -36px + -46px; // 아이콘 높이 + 아이콘과 프로그레스바 간격
      right: -15px;

      button[class^='btn_'] {
        margin-left: 8px;
      }

      .btn {
        &_open_list {
          display: none;
        }
      }
    }

    // 퀄리티
    .quality {
      @include hide_text;

      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .btn_player_close {
    @include hide_text;

    position: fixed;
    top: 45px;
    left: 45px;
  }

  // 1440px 이상
  @include respond-to(mediaquery-large) {
    right: 0;
    padding-right: 0;
    overflow: hidden;

    // left area
    .playbar_wrap {
      position: relative;
      float: left;
      width: 50%;
      height: 100%;
      padding: 0;

      .playbar_ct {
        position: absolute;
        top: 50%;
        right: calc($player-ct-gap / 2);
        margin-top: -380px;

        // -webkit-transform: translateY(-50%);
        // -moz-transform: translateY(-50%);
        // -o-transform: translateY(-50%);
        // -ms-transform: translateY(-50%);
        // transform: translateY(-50%);
      }
    }
  }
}

// 하단 미니 사이즈
.player_ct.mini {
  z-index: 500; // 미니 상태에서 스낵바가 떠있을 경우 볼륨바를 가리므로 z-index를 높여줌
  background-color: #000;

  .playbar_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .playbar_ct {
    position: relative;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    max-width: 1920px;
    height: 80px;
    padding: 21px 20px 19px;
  }

  // 트랙 정보
  .track_area {
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 30%;

    .thumb {
      width: $album-thumb-width2;
      height: $album-thumb-width2;
      cursor: pointer;
      border-radius: 4px;

      &.empty {
        cursor: initial;
      }
    }

    .lyrics {
      display: none;
    }

    .track_info {
      max-width: calc(100% - 96px);
      margin-left: 12px;

      .title {
        font-size: 14px;
      }

      .artist {
        padding-top: 4px;
        font-size: 12px;
      }
    }

    // 재생 목록 없을 경우
    .no_list {
      padding-top: 12px;
      font-size: 14px;
      line-height: 20px;
      color: #989898;

      span {
        display: block;
        margin-right: 5px;
        color: #989898;
      }
      @include respond-to(mediaquery-large) {
        padding-top: 23px;

        span {
          display: inline;
        }
      }
    }
  }

  .ico_adult {
    @include retina-sprite($sp-icon-ic-list-badge-19-group);

    margin-right: 5px;
    vertical-align: middle;
  }

  .ico_free {
    @include retina-sprite($sp-icon-icon-free-group);

    margin-right: 5px;
    vertical-align: middle;
  }

  // 플레이어 컨트롤러
  .player_controller {
    display: flex;
    align-items: center;

    // 재생 시간
    .playtime {
      position: absolute;
      bottom: 22px;
      left: 50%;
      width: 232px;
      height: 4px;
      margin-left: -calc(232px / 2);

      .progress {
        height: 6px;
      }

      span[class^='time_'] {
        position: absolute;
        bottom: -7px;
        font-size: 12px;
        font-weight: bold;
      }

      .time_current {
        right: calc(100% + 10px);
        left: auto;
        text-align: right;
      }

      .time_all {
        right: auto;
        left: calc(100% + 10px);
      }
    }

    .control_area {
      width: 375px;
      padding-top: 23px;
      margin: 0 auto;
      white-space: nowrap;

      button {
        margin: 0 16px;
      }
    }

    // 우측 버튼
    .util_area {
      button[class^='btn_'] {
        position: absolute;
      }

      .btn_like {
        top: $mini-right-buttons-position-top;
        right: 140px;
      }

      .btn_add {
        display: none;
      }

      .btn_share {
        display: none;
      }

      .btn_open_list {
        top: $mini-right-buttons-position-top;
        right: 30px;
      }
    }
  }

  // 볼륨 버튼
  .volume_area {
    .btn_volume {
      @include hide_text;

      position: relative;
      z-index: 10;

      &.active {
        & + .progress_area {
          display: block;
        }
      }
    }

    .progress_area {
      position: absolute;
      bottom: -15px;
      left: -8px;
      display: none;
      width: 47px;
      height: 169px;
      background: #000;
      border-radius: 7px;

      // range
      .range_area {
        position: absolute;
        top: 62px;
        left: -21px;
        width: 90px;
        height: 3px;
        transform: rotate(270deg);
      }

      input[type='range'].progress {
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: hidden;
        vertical-align: top;
        cursor: pointer;
        border: 0;

        &.chrome-bug-fix {
          &::-webkit-slider-thumb {
            @include thumb;

            box-shadow: -100vw 0 0 100vw transparent;
          }
        }

        &:focus {
          outline: 0 none;
        }

        &,
        &::-webkit-slider-thumb {
          appearance: none;
        }

        &::-webkit-slider-runnable-track {
          @include track;

          background-color: #4a4a4a;
        }

        &::-moz-range-track {
          @include track;

          background-color: #4a4a4a;
        }

        &::-ms-track {
          @include track;

          background-color: #4a4a4a;
        }

        &::-webkit-slider-thumb {
          @include thumb;

          box-shadow: -100vw 0 0 100vw #fff;
        }

        &::-moz-range-thumb {
          @include thumb;
        }

        &::-ms-thumb {
          @include thumb;
        }

        &::-ms-tooltip {
          display: none;
        }

        &::-moz-range-progress {
          @include fill;
        }

        &::-ms-fill-lower {
          @include fill;
        }
      }

      .volume_handle {
        position: absolute;
        top: -3px;
        display: block;
        width: 9px;
        height: 9px;
        margin-left: -3px;
        pointer-events: none;
        background: #fff;
        border-radius: 5px;
      }
    }
  }

  .quality {
    display: none;
  }

  .playlist_wrap {
    display: none;
  }

  // 1440px 이상
  @include respond-to(mediaquery-large) {
    .player_controller {
      .playtime {
        width: 400px;
        margin-left: -calc(400px / 2);
      }

      .util_area {
        .btn_like {
          right: 155px;
        }

        .btn_open_list {
          right: 45px;
        }
      }
    }
  } // media query
}
