
          @import '@/assets/scss/common/color.scss';
        
@charset "UTF-8";

.loading-bar {
  &.loading-full {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000; // 가장 큰 z-index .popup_container(3001) 보다 작게
    overflow: hidden;
  }

  .loading-bar-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 38px;
    height: 38px;
    transform: translate(-50%, -50%);
  }

  .loading-bar-inner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .bar {
    width: 6px;
    height: 37.5%;
    background: #4349fb;
    border-radius: 6px;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .bar1 {
    animation-name: loadingBar1;
  }

  .bar2 {
    height: 67%;
    background: rgb(74, 66, 253);
    background: linear-gradient(90deg, rgba(74, 66, 253, 1) 0%, rgba(78, 62, 253, 1) 100%);
    animation-name: loadingBar2;
  }

  .bar3 {
    height: 45.8%;
    background: rgb(84, 58, 253);
    background: linear-gradient(90deg, rgba(84, 58, 253, 1) 0%, rgba(88, 52, 255, 1) 100%);
    animation-name: loadingBar3;
  }

  .bar4 {
    height: 21%;
    background: rgb(92, 48, 255);
    background: linear-gradient(90deg, rgba(92, 48, 255, 1) 0%, rgba(98, 44, 255, 1) 100%);
    animation-name: loadingBar4;
  }
}

@keyframes loadingBar1 {
  30% {
    height: 21%;
  }

  60% {
    height: 45.9%;
  }

  100% {
    height: 67%;
  }
}

@keyframes loadingBar2 {
  30% {
    height: 54.1%;
  }

  60% {
    height: 21%;
  }

  100% {
    height: 37.5%;
  }
}

@keyframes loadingBar3 {
  30% {
    height: 20.8%;
  }

  60% {
    height: 41.7%;
  }

  100% {
    height: 67%;
  }
}

@keyframes loadingBar4 {
  30% {
    height: 41.7%;
  }

  60% {
    height: 67%;
  }

  100% {
    height: 37.5%;
  }
}
