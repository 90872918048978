
          @import '@/assets/scss/common/color.scss';
        

.dimdLayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
