@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 100;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 200;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 300;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 400;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 500;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 600;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 700;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 800;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 900;
  src:
    local('Pretendard Variable'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff2'),
    url('~@/assets/font/PretendardVariable.woff2') format('woff'),
    url('~@/assets/font/PretendardVariable.ttf') format('truetype');
}
