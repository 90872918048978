
          @import '@/assets/scss/common/color.scss';
        

.track-view-more {
  &__artist-info {
    position: relative;

    &__item {
      &:hover {
        + .track-view-more__artist-info__hover {
          display: block;
        }
      }
    }

    &__hover {
      position: absolute;
      top: -10px;
      right: 100%;
      display: none;

      &:hover {
        display: block;
      }

      &.right {
        right: 0;
        left: 100%;
      }
    }
  }
}
