
          @import '@/assets/scss/common/color.scss';
        

.playlist-action {
  &__more {
    margin-left: 20px;
  }

  &__delete {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__move {
    color: white;
    opacity: 0.3;
  }

  &__more-action {
    right: 30px;
  }
}
