$track-list-table-cell-height: 84px;
$info-area-min-width: 130px;
$info-area-max-width: 440px;
$info-area-max-width-sm: 280px;
$info-area-thumb-size: 60px;
$info-area-thumb-padding: 20px;
$info-area-left-padding: 5px;
$info-area-lyrics-max-width: 604px;
$cell-album-left-padding: 29px;
%btn_allplay_style {
  position: relative;
  padding-left: 17px;
  margin: 0 15px 0 10px;
  font-size: 13px;
  line-height: 17px;
  color: #222;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    @include retina-sprite($sp-button-btn-list-top-play-b-nor-group);
  }

  &:hover {
    color: $key_color;

    &::after {
      @include retina-sprite($sp-button-btn-list-top-play-b-hover-group);
    }
  }

  &:active {
    color: rgba(51, 51, 51, 0.3);

    &::after {
      @include retina-sprite($sp-button-btn-list-top-play-b-press-group);
    }
  }
}

%i_update_style {
  position: absolute;
  top: -6px;
  left: -6px;
  @include hide_text;

  display: inline-block;
  @include retina-sprite($sp-icon-ic-thumb-badge-update-group);
}

%cell-album-common-style {
  padding-left: $cell-album-left-padding;
  text-align: left;
}

.section_inner {
  min-width: 800px;
  max-width: 1600px; // TODO: max width 확인
  padding-top: 50px;
  margin: 0 auto;

  h2 {
    font-size: 26px;
  }

  // 이용권 쿠폰등록 위치때문에 컨텐츠 넓이와 동일하게 고정
  @at-root .section_purchase {
    .section_inner {
      position: relative;
      width: 955px;
    }
  }
}

%tab_style {
  position: relative;
  height: 32px;
  padding-right: 40px;
  overflow: hidden;

  &.unfolded {
    height: auto;

    .swiper-wrapper {
      white-space: normal;

      // 탭메뉴 펼쳐진 상태에서 swiper 좌우 scroll 막기
      transform: none !important;
    }
  }

  ul {
    display: inline-block;
    margin-top: -10px !important;
    font-size: 0;
    vertical-align: top;
  }

  li {
    display: inline-block;
    padding-top: 10px;
    padding-right: 8px;
  }

  .swiper-wrapper {
    width: auto;
    white-space: nowrap;
  }

  .swiper-slide {
    width: auto;
  }

  .btn_more {
    @include retina-sprite($sp-button-btn-chart-arrow-open-nor-group);
    @include hide_text;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background-color: #fff;

    &:hover {
      @include retina-sprite($sp-button-btn-chart-arrow-open-hover-group);
    }

    &:active {
      @include retina-sprite($sp-button-btn-chart-arrow-open-press-group);
    }

    &.on {
      @include retina-sprite($sp-button-btn-chart-arrow-close-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-chart-arrow-close-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-chart-arrow-close-press-group);
      }
    }
  }

  // storage tab ui 변경(부분)
  &.tab_main {
    height: auto;

    ul {
      margin-top: 0 !important;
    }

    li {
      padding-right: 14px;

      &.on {
        .btn_tab_normal {
          color: $key_color;
          background-color: #fff;

          &::before {
            content: '';
          }
        }
      }
    }

    .btn_tab_normal {
      position: relative;
      box-sizing: border-box;
      display: block;
      min-height: 40px;
      padding: 11px 7px;
      font-size: 15px;
      line-height: 1.2;
      color: #222;
      text-align: center;
      background-color: transparent;
      border-radius: 0;

      &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: $key_color;
      }

      &:hover {
        color: $key_color;
      }
    }

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      content: '';
      background-color: #eee;
    }
  }

  &.tab_sub {
    margin-top: 12px;

    .btn_tab_normal {
      font-size: 14px;
      color: #6d6d6d;
      background-color: #f5f5f5;

      &:hover {
        color: $key_color;
      }
    }

    ul {
      margin-top: 0 !important;
    }

    li {
      padding: 0 5px;

      &.on {
        .btn_tab_normal {
          color: #fff;
          background-color: $key_color;
        }
      }
    }
  }
}

// TODO: tab common / class 수정
.tab {
  @extend %tab_style;
}

.section_search {
  .tab {
    margin-top: 25px;
  }
}

.newlist_tab {
  @extend %tab_style;

  margin-top: 25px;
}

.settings_tab {
  @extend %tab_style;

  ul {
    padding-left: 30px;

    li {
      padding-top: 0;

      &:nth-child(2) {
        padding-left: 117px;
      }

      .btn_tab_normal {
        font-weight: bold;
        color: #989898 !important;
      }

      .active {
        color: #3f3fff !important;
      }
    }
  }
}

.storage_tab {
  @extend %tab_style;

  z-index: 3;
  margin-top: 2px;

  li {
    padding-right: 0;

    .btn_tab_normal {
      .ico_like {
        @include retina-sprite($sp-button-ic-my-like-off-group);

        padding: 0 5px 2.5px 0;
      }

      &:hover {
        .ico_like {
          @include retina-sprite($sp-button-ic-my-like-on-group);
        }
      }
    }

    &.on {
      .btn_tab_normal {
        .ico_like {
          @include retina-sprite($sp-button-ic-my-like-on-group);
        }
      }
    }
  }
}

.setting_tab + div {
  margin-top: 30px;
}

.setting_tab {
  @extend %tab_style;

  height: 46px;
  margin-top: 3px;

  .btn_tab_normal {
    height: 36px;
    padding: 3px 15px;
    border-radius: 18px;
  }
}

.voucher_tab {
  @extend %tab_style;

  margin-top: 33px;
  text-align: center;
}

.discovery_tab {
  @extend %tab_style;

  height: 40px;

  .btn_tab_normal {
    display: block;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    border-radius: 20px;

    &:active {
      color: #333;
      background: none;
    }

    &:hover {
      color: #333;
    }

    &.is-active {
      color: #fff;
      background-color: #3f3fff;
    }
  }
}

.section_purchase {
  .tab {
    text-align: center;
  }
}

.ct_chart {
  padding-top: 42px;
}

.tab + .ct_chart {
  padding-top: 57px;
}

.storage_tab + .ct_chart {
  padding-top: 42px;

  .notice {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: #989898;

    .notice-icon {
      margin-right: 6px;
    }
  }
}

.newlist_tab + .ct_chart {
  padding-top: 42px;
}

.section_content_head + .chart_lst {
  padding-top: 27px;
}

// 검색결과 타이틀과 테이블 사이 여백
.section_content_head + .track_list_table {
  margin-top: 20px;
}

.chart_notice {
  padding: 19px 16px 15px;
  margin-bottom: 34px;
  background: #f9f9f9;
  border-radius: 2px;

  &__comment {
    display: flex;
    font-size: 14px;
    line-height: 1.2;
    color: #777;
  }

  &__icon {
    display: inline-block;
    flex-shrink: 0;
    margin-top: -1px;
    margin-right: 6px;

    @include retina-sprite($sp-icon-icon-notice-nor-group);
  }
}

.chart_content_head {
  position: relative;
  min-height: 20px; // detail-아티스트, 보관함에서 최소값 필요
  padding-bottom: 16px;

  @include clearfix;

  .btn_allchoice {
    top: 0;
  }

  h4 {
    float: left;
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }

  .update {
    padding-left: 18px;
    font-size: 13px;
    color: #a0a0a0;

    span {
      color: #a0a0a0;
    }
  }

  .btn_allplay {
    @extend %btn_allplay_style;
  }

  .btn_plus {
    @extend %btn_allplay_style;

    &::after {
      position: absolute;
      content: '';
      @include retina-sprite($sp-button-btn-list-top-plus-nor-group);
    }

    &:hover {
      color: $key_color;

      &::after {
        @include retina-sprite($sp-button-btn-list-top-plus-hover-group);
      }
    }

    &:active {
      color: rgba(51, 51, 51, 0.3);

      &::after {
        @include retina-sprite($sp-button-btn-list-top-plus-press-group);
      }
    }
  }

  .btn_popular_listen {
    position: absolute;
    top: 0;
    right: 0;
  }

  .chart_head_desc {
    display: inline-block;
    margin: 1px 0 0 50px;
    font-size: 15px;
    line-height: 1.2;
    color: #969696;
    vertical-align: middle;
  }
}

// 공통 오른쪽 버튼텝
.sort_type_wrap {
  position: absolute;
  top: 0;
  right: 0;
  color: #333;

  button {
    padding-right: 15px;
    font-size: 13px;

    &:hover {
      color: $key_color;
    }

    &.on {
      color: $key_color;
    }
  }

  &.p_top42px {
    top: 42px;
  }

  &.p_top15px {
    top: 15px;
  }

  .dimmed {
    opacity: 0.5;

    &:hover {
      color: #333;
    }
  }

  span {
    position: relative;
    padding: 0 15px 0 0;

    &::after {
      position: absolute;
      top: 7px;
      left: 0;
      display: block;
      width: 1px;
      height: 8px;
      content: '';
      background-color: #dcdcdc;
    }
  }

  &.btn_top100_listen {
    top: 4px;
    font-size: 13px;
    color: #333;

    &::before {
      @include retina-sprite($sp-button-btn-list-top-play-b-nor-group);

      display: inline-block;
      vertical-align: middle;
      content: '';
    }
  }
}

%track_list_table_style {
  width: auto;
  min-width: 100%;
  max-width: none;
  table-layout: fixed;

  // width: 100%;
  border-collapse: collapse;

  thead {
    th {
      white-space: nowrap;
    }
  }

  th {
    height: 39px;
    font-size: 13px;
    font-weight: normal;
    color: #a0a0a0;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;

    &.info,
    &.artist {
      text-align: left;
    }

    &.info {
      padding-left: $info-area-left-padding;
    }

    &.album {
      @extend %cell-album-common-style;
    }
  }

  @at-root {
    #wrap {
      .track_list_table {
        tr {
          &.checked {
            td {
              background: #f5f5f5;
            }
          }

          &.disabled {
            td::after {
              background-color: rgba($color: #fff, $alpha: 0.7);
            }
          }
        }
      }
    }
  }

  tr {
    &.disabled {
      td::after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        display: block;
        height: $track-list-table-cell-height;
        content: '';
      }
    }
  }

  td {
    position: relative;
    height: $track-list-table-cell-height;
    text-align: center;
    border-bottom: 1px solid #f6f6f6;

    label {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: $track-list-table-cell-height;
      cursor: pointer;
    }

    input[type='checkbox'] {
      margin: 0;
    }

    label::before {
      @include align_middle(50%, 50%, -50%, -50%);
    }

    // 순위
    &.num {
      font-size: 15px;
      font-weight: bold;
      color: #333;
    }

    // 체크박스
    &.select {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        height: 12px;

        svg {
          vertical-align: baseline;
        }
      }
    }

    &.rank {
      min-width: 30px;

      .ico {
        @include hide_text;

        display: inline-block;
        margin-right: 2px;
      }

      .txt {
        font-size: 12px;
        font-weight: bold;
      }

      .up {
        .ico {
          @include retina-sprite($sp-icon-ic-chart-up-group);
        }

        .txt {
          color: #ff6e91;
        }
      }

      .down {
        .ico {
          @include retina-sprite($sp-icon-ic-chart-down-group);
        }

        .txt {
          color: #7575ff;
        }
      }

      .steady {
        .ico {
          width: 8px;
          height: 2px;
          background: #c2c2c2;
        }
      }
    }

    // 정보
    &.info {
      // width: 1%; // 트랙 정보 td가 컨텐츠 넓이만큼 fix 되도록 하기 위함
      padding-left: $info-area-left-padding;
      text-align: left;

      .info_wrap {
        position: relative;
        min-width: $info-area-thumb-size + $info-area-thumb-padding + $info-area-min-width;
        max-width: $info-area-thumb-size + $info-area-thumb-padding + $info-area-max-width;
        height: $info-area-thumb-size;
        padding-right: 28px;
        padding-left: $info-area-thumb-size + $info-area-thumb-padding;

        // 앨범 목록일 경우 썸네일 미노출
        &.type_album {
          padding-left: 0;
        }

        &.type_lyrics {
          max-width: $info-area-thumb-size + $info-area-lyrics-max-width;
          padding-right: 80px;

          .txt_area {
            max-width: $info-area-lyrics-max-width;
          }

          .tit {
            padding-top: 0;
          }

          .album {
            @include ellipsis_multi(2, 40);

            padding-top: 3px;
            line-height: 1.54;
            white-space: pre-wrap;
          }
        }

        &.in-player-track {
          .ico_adult {
            @include retina-sprite($sp-icon-ic-list-badge-19-group);
          }
        }
      }

      .thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: $info-area-thumb-size;
        height: $info-area-thumb-size;

        a {
          position: relative;
          display: block;
          height: 100%;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: '';
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 4px;
          }
        }

        img {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          vertical-align: top;
          object-fit: cover;
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }

        i.update {
          @extend %i_update_style;
        }

        i.origin {
          @extend %i_update_style;
          @include retina-sprite($sp-icon-ic-thumb-badge-origin-group);
        }

        .track_icon_original {
          position: absolute;
          top: 4px;
          left: 4px;
          line-height: 7px;
        }
      }

      .txt_area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: $info-area-min-width;
        max-width: $info-area-max-width;
        height: 100%;

        @media screen and (max-width: 1140px) {
          max-width: $info-area-max-width-sm;
        }

        .tit-play {
          min-width: 0;
          max-width: 100%;
          text-align: left;
        }

        .desc {
          display: flex;
          align-items: center;

          &__state {
            display: flex;
            flex-shrink: 0;

            &-text {
              display: block;
              padding-top: 6px;
              font-size: 13px;
              color: #969696;

              &--play {
                color: #3f3fff;
              }
            }
          }
        }

        .desc__link {
          @include ellipsis1;

          display: inline-block;
        }

        .tit {
          font-size: 15px;
          color: #333;
        }

        .tit__icons {
          display: flex;
          flex-shrink: 0;
          align-items: center;

          &.tit__icons--left {
            margin-top: -1px;
          }
        }

        .tit__inner {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .tit__text {
          @include ellipsis1;

          font-weight: normal;
        }

        .album {
          @include ellipsis1;

          flex: 0 1 auto;
          padding-top: 6px;
          font-size: 13px;

          a {
            color: #969696;
          }
        }

        .count {
          margin-top: 4px;
          font-size: 13px;
          line-height: 1;
          color: #989898;
          white-space: nowrap;
          vertical-align: middle;
        }

        i[class^='ico_'] {
          display: inline-block;
          margin-left: 7px;
          vertical-align: top;
        }

        i.track_ic_up {
          display: inline-block;
          margin-right: 3px;
          margin-left: 0 !important;
          vertical-align: top;
          @include retina-sprite($sp-icon-ic-track-up-group);
        }

        .ico_adult {
          display: inline-block;
          margin-right: 4px;
          margin-left: 0 !important;
          vertical-align: top;

          @include retina-sprite($sp-icon-ic-track-age-19-group);
        }

        .ico_title {
          @include retina-sprite($sp-icon-ic-list-badge-title-group);

          margin-right: 7px;
          margin-left: 0 !important;
        }

        .ico_unreleased {
          @include retina-sprite($sp-icon-ic-list-badge-unreleased-group);
        }

        i.ico_free {
          @include hide_text;
          @include retina-sprite($sp-icon-icon-free-group);

          display: inline-block;
          margin-right: 5px;
          margin-left: 0;
          vertical-align: middle;
        }

        i.ico_count {
          @include hide_text;
          @include retina-sprite($sp-icon-ico-like-listen-group);

          display: inline-block;
          margin-right: 4px;
          color: #989898;
          vertical-align: -1px;
        }
      }
    }

    // 아티스트
    &.artist {
      position: relative;
      font-size: 15px;
      color: #333;
      text-align: left;

      p {
        position: relative;
        min-width: 100px;

        .artist_link_w {
          @include ellipsis1;

          width: 196px;

          .artist_link_and {
            margin: 0 5px;
          }

          a {
            font-size: 15px;
            color: #333;

            &:hover {
              text-decoration: underline;
            }
          }

          &.active {
            &:hover {
              + .tooltip {
                display: block;
              }
            }
          }
        }

        .tooltip {
          // display: block;
          position: absolute;
          top: 25px; // TODO: 위치 조정 필요
          left: 0;
          z-index: 10;
          display: none;
          width: 162px;
          padding: 10px 17px;
          font-size: 13px;
          background: #fff;
          border: 1px solid #d2d2d2;
          border-radius: 5px;

          &::after {
            @include retina-sprite($sp-icon-ic-tooltip-group);

            position: absolute;
            top: -9px;
            left: 17px;
            content: '';
          }
        }
      }
    }

    &.album {
      @extend %cell-album-common-style;

      white-space: pre-wrap;
    }

    // 듣기, 재생목록, 내 리스트, 더보기 버튼
    button[class^='btn_'] {
      @include hide_text;
    }

    // TODO: scss 리팩토링
    .btn_listen {
      @include retina-sprite($sp-button-btn-list-play-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-list-play-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-list-play-press-group);
      }
    }

    .btn_add_play {
      @include retina-sprite($sp-button-btn-list-playlist-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-list-playlist-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-list-playlist-press-group);
      }
    }

    .btn_add_my {
      @include retina-sprite($sp-button-btn-list-mylist-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-list-mylist-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-list-mylist-press-group);
      }
    }

    .btn_more {
      @include retina-sprite($sp-button-btn-list-more-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-list-more-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-list-more-press-group);
      }
    }

    // 더보기
    .more_wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .layer_track_table_more {
      position: absolute;
      top: $track-list-table-cell-height + 1;
      right: 0;
      z-index: 12;
    }
  }
}

// 차트 공통 테이블
.track_list_table {
  @extend %track_list_table_style;
}

.help_list_table {
  @extend %track_list_table_style;

  th {
    &.info {
      padding-left: 20px;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: #fafafa;
      }
    }
  }

  tr {
    &:hover {
      background-color: #fafafa;
    }

    td {
      font-size: 15px;

      &.info_help {
        max-width: 196px;
        padding-left: 20px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      b {
        padding-right: 13px;
      }

      .item_title svg {
        vertical-align: middle;
      }
    }

    &.on {
      background-color: #f5f5f5;

      td {
        border-bottom: 0;

        &.info_help {
          white-space: pre-line;
        }

        &.help_detail {
          padding: 0 100px 28px 78px;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          word-break: break-all;
          white-space: pre-line;
        }
      }
    }

    .btn_help_open {
      @include retina-sprite($sp-button-btn-list-open-nor-group);
    }

    .btn_help_close {
      @include retina-sprite($sp-button-btn-list-close-nor-group);
    }
  }
}

// 차트 더보기 버튼
.btn_more_area {
  padding-top: 20px;
  text-align: center;
}

// 트랙 정보 더보기 레이어
.layer_track_table_more {
  li {
    button {
      &:hover {
        &::after {
          display: none;
        }
      }
    }
  }
}
