
          @import '@/assets/scss/common/color.scss';
        

.group-action {
  display: flex;
  align-items: center;

  &__move,
  &__repeat {
    margin-left: 20px;
  }

  &__more {
    margin-left: 20px;
    color: #e9e9e9;

    &.active {
      transform: rotate(0.5turn);
    }

    &.disabled {
      color: #484848;
    }
  }

  &__delete {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.6);
  }

  &__move {
    color: rgba(255, 255, 255, 0.3);

    &.disabled {
      color: #333;
    }
  }

  &__live {
    width: 17px;
    height: 17px;
  }
}
