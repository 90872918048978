
          @import '@/assets/scss/common/color.scss';
        

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.marquee {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.center {
    justify-content: center;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }

    10% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-50%);
    }
  }
  @keyframes shadow {
    0% {
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    }

    15% {
      mask-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 15%,
        rgba(255, 255, 255, 1) 85%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    85% {
      mask-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 15%,
        rgba(255, 255, 255, 1) 85%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    100% {
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &__inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__active {
    &.gradient {
      animation: shadow 8s linear infinite;
    }

    .marquee__inner {
      width: auto;
      overflow: initial;
      text-overflow: initial;
      animation: marquee 8s linear infinite;

      .marquee__indent {
        display: inline-block;
        text-indent: 60px;
        transform: translateX(-60px);
      }
    }
  }

  &__text {
    display: none;
  }

  &__icon {
    margin-right: 4px;
  }
}
