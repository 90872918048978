
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.music-playlist {
  @include respond-to(mediaquery-large) {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 10%;
  }

  padding-top: 15px;

  &__content {
    @include respond-to(mediaquery-large) {
      flex-grow: 1;
      height: 10%;
      overflow: auto;
    }

    width: 605px;
    margin-top: 20px;
  }

  &__text {
    font-size: 17px;
    color: #a0a0a0;
  }

  &__desc {
    margin-top: 11px;
    font-size: 15px;
    color: #646464;
  }

  &__search {
    padding: 8px 15px;
    margin: 0 auto;
    font-size: 14px;
    color: #fff;
    border: 0.5px solid #505050;
    border-radius: 18px;
  }

  /* Empty 컴포넌트 스타일 오버라이딩 */
  &__empty {
    padding: 120px 0;

    @include respond-to(mediaquery-large) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0;
    }

    ::v-deep {
      .empty-section {
        &__title {
          color: #a0a0a0;
        }

        &__sub-text {
          color: #646464;
        }

        &__button {
          width: fit-content;
          margin: 0 auto;
          color: #fff;
          border: 0.5px solid #505050;
          border-radius: calc(32px / 2);
        }
      }
    }
  }
}
