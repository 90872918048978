$fixed_layer_index: 98;

@mixin inner_style {
  position: relative;
  width: 955px;
  margin: 0 auto;
}

.section_discovery {
  padding: 0;

  // 취향 선택
  .discovery_top {
    position: relative;
    z-index: $fixed_layer_index;
    text-align: center;
    background-color: #fff;

    .top_inner {
      padding: 100px 0 20px;
      @include inner_style;
    }

    h2 {
      padding-bottom: 35px;
      font-size: 28px;
      line-height: 41px;

      .block {
        display: block;
      }
    }
  }

  .discovery_top_select {
    position: fixed;
    top: 130px;
    right: 0;
    left: 0;
    z-index: $fixed_layer_index;
    height: 145px;
    background-color: rgba(255, 255, 255, 0.95);
    border-top: 1px solid rgba(151, 151, 151, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);

    .top_inner {
      @include inner_style;

      padding: 20px 0 24px;

      .btn_select_view {
        font-size: 16px;

        &::after {
          display: inline-block;
          margin: -2px 0 0 2px;
          vertical-align: middle;
          content: '';
          @include retina-sprite($sp-icon-ic-discovery-arrow-group);
        }

        &:disabled::after {
          display: none;
        }
      }

      .btn_select_save {
        position: absolute;
        right: 0;
        bottom: 35px;
        min-width: 96.55px;
        height: 40px;
        padding: 0 33px;
        font-size: 16px;
        line-height: 39px;
        color: $key_color;
        text-align: center;
        border: 1px solid $key_color;
        border-radius: 20px;

        img {
          display: inline-block;
          width: 24px;
          height: auto;
          vertical-align: middle;
        }

        &:disabled {
          color: $text_disabled;
          cursor: not-allowed;
          border-color: $text_disabled;
        }
      }
    }

    .top_thumb_area {
      position: relative;
      margin-top: 17px;
      margin-right: 193px;

      /*
            가로 955px 이하일 때 가로 잘리는 문제 대응방법 : 썸네일 최대 갯수 줄이고 css 수정
            .discovery_top_select .top_inner {max-width: 955px}
            .top_thumb_area {margin-right: 193px + 줄어든 썸네일 갯수*50px}
            */

      .top_thumb_list {
        height: 64px;
        margin-left: -2px;
        overflow: hidden;

        li {
          position: relative;
          float: left;
          width: 60px;
          height: 60px;
          margin-left: -14px;
          overflow: hidden;
          border: 2px solid #fff;
          border-radius: 100%;

          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            content: '';
            background-color: rgba(0, 0, 0, 0.06);
            border-radius: 100%;
          }

          &:first-child {
            margin-left: 0;
          }

          img {
            width: 100%;
            height: auto;
            min-height: 100%;
          }
        }

        &.more {
          &::after {
            position: absolute;
            top: 2px;
            right: -48px;
            z-index: -100;
            display: block;
            content: '';
            border-radius: 100%;
            @include retina-sprite($sp-icon-icon-discovery-more-group);
          }
        }
      }
    }
  }

  .discovery_cont {
    @include inner_style;

    // 화면 배율 낮을때 탭 버튼이 활성화 되지 않는 문제로 인해 하단에 여백추가 (배율 50% 까지)
    padding-bottom: 50vh;

    .thumb_section {
      &:first-child {
        padding-top: 20px;
      }

      .thumbnail_group {
        h3 {
          padding: 30px 0;
          font-size: 17px;
          font-weight: normal;
          color: #999;
          text-align: center;
        }

        .check_thumbnail_list {
          margin-bottom: -60px;
          margin-left: -25px;
          font-size: 0;

          li {
            display: inline-block;
            padding-bottom: 60px;
            margin-left: 25px;
            font-size: 15px;
            vertical-align: top;
          }

          &.align_center {
            text-align: center;
          }
        }

        .check_thumbnail {
          position: relative;
          width: 138px;

          label {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
          }

          .thumbnail {
            display: block;
            width: 138px;
            height: 138px;
            overflow: hidden;
            border-radius: 100%;

            img {
              width: 100%;
              height: auto;
              min-height: 100%;
            }
          }

          .artist_text {
            margin-top: 16px;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            @include ellipsis_multi(2, '');

            word-break: break-word;
          }
        }
      }
    }
  }

  // 취향 전체보기
  .discovery_all_top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $fixed_layer_index + 1;
    background-color: #fff;

    .top_inner {
      @include inner_style;

      padding: 32px 0 30px;
    }

    h2 {
      font-size: 22px;
      line-height: 28px;
      text-align: center;
    }
  }

  .discovery_all_cont {
    @include inner_style;

    .select_section {
      padding-top: 10px;

      &:first-child {
        padding-top: 90px;
      }

      h3 {
        padding: 20px 0 15px;
        font-size: 18px;
        border-bottom: 1px solid #eee;
      }

      .my_select_list {
        overflow: hidden;

        li {
          position: relative;
          float: left;
          width: 455px;
          padding: 15px 0;

          &:nth-child(2n) {
            margin-left: 40px;
          }
        }
      }

      .select_thumbnail {
        display: table;
        width: 100%;
        height: 60px;

        .thumb_info {
          display: table-cell;
          width: 60px;
          vertical-align: middle;

          .thumb {
            position: relative;
            display: block;
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 100%;

            &::before {
              position: absolute;
              top: 0;
              left: 0;
              box-sizing: border-box;
              display: block;
              width: 100%;
              height: 100%;
              content: '';
              background-color: rgba(0, 0, 0, 0.06);
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 100%;
            }

            img {
              width: 100%;
              height: auto;
              min-height: 100%;
              vertical-align: top;
            }
          }
        }

        .title_info {
          display: table-cell;
          padding-left: 20px;
          font-size: 16px;
          vertical-align: middle;

          .title {
            max-width: 290px;
            @include ellipsis1;
          }
        }

        .sel_info {
          display: table-cell;
          width: 40px;
          padding: 0 15px;
          vertical-align: middle;

          .btn_like_on {
            @include hide_text;
            @include retina-sprite($sp-icon-icon-browse-like-on-group);
          }
        }
      }
    }

    + .btn_close_area {
      z-index: $fixed_layer_index + 1;
    }
  }

  // 버튼
  .btn_close_area {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $fixed_layer_index;
    width: 100%;

    .btn_inner {
      position: relative;
      max-width: 955px;
      margin: 0 auto;

      .btn_discovery_close {
        position: absolute;
        top: 37px;
        right: 0;
        @include retina-sprite($sp-button-btn-discovery-close-group);
        @include hide_text;
      }

      .btn_select_back {
        position: absolute;
        top: 37px;
        left: 0;
        z-index: 110;
        font-size: 16px;

        &::before {
          display: inline-block;
          margin: -2px 6px 0 0;
          vertical-align: middle;
          content: '';
          @include retina-sprite($sp-icon-icon-initialization-group);
        }
      }
    }
  }

  // 음악 선택시 상단 고정
  &.selected,
  &.fixed {
    .discovery_top {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      height: 131px; // 엣지 스크롤시 떨림문제로 .discovery_top_select와 떨어져보이는 현상 가리기위해 높이 +1픽셀 추가

      .top_inner {
        padding-top: 32px;
      }

      h2 {
        padding-bottom: 15px;
        font-size: 17px;
        font-weight: normal;
        line-height: 23px;

        .block {
          display: inline;
        }
      }
    }

    .discovery_cont {
      // margin-top: 277px;
      // 화면 배율 클때 탭 활성화 되지 않는 문제로 인해 상단 fixed 영역의 높이값보다(277px) 크게 기입(290px)
      margin-top: 290px;
    }

    .btn_close_area {
      position: fixed;
    }
  }
}

// Electron style
#player_wrap {
  .section_discovery {
    &.selected,
    &.fixed {
      .discovery_top,
      .discovery_top_select {
        left: 150px;
      }

      & .btn_close_area {
        right: 0;
        left: 150px;
        width: auto;
      }
    }

    .discovery_all_top {
      left: 150px;
    }
  }
}

// Electron for windows10
.windows10 {
  #player_wrap {
    .section_discovery.fixed,
    .section_discovery.selected {
      .discovery_top {
        top: 40px;
      }

      .discovery_top_select {
        top: 170px;
      }

      .discovery_all_top {
        top: 40px;
      }

      .btn_close_area {
        top: 40px;
      }
    }
  }
}

.maintenance {
  #player_wrap {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
}
