
          @import '@/assets/scss/common/color.scss';
        
@charset "UTF-8";

.loading-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: rotateCircle 2s linear infinite;
}

@keyframes rotateCircle {
  100% {
    transform: rotate(720deg);
  }
}

.circle-line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  border: 2px solid transparent;
  border-radius: 50%;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &.circle-line1 {
    border-top-color: #3f3fff;
    transform: rotate(30deg);
  }

  &.circle-line2 {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 3;
    border-width: 4px;
    border-right-color: black;
    transform: rotate(-55deg);
    animation-name: rotateCircleLine2;
  }

  &.circle-line3 {
    z-index: 1;
    border-width: 2px;
    border-top-color: #733fff;
    transform: rotate(30deg);
    animation-name: rotateCircleLine3;
  }
}

@keyframes rotateCircleLine2 {
  50% {
    transform: rotate(40deg);
  }

  100% {
    transform: rotate(-55deg);
  }
}

@keyframes rotateCircleLine3 {
  20%,
  100% {
    transform: rotate(30deg);
  }

  40% {
    transform: rotate(-30deg);
  }
}
