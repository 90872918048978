
          @import '@/assets/scss/common/color.scss';
        
.dialogWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 102;
  width: 88%;
  min-width: 400px;
  max-width: 300px;
  height: auto;
  padding: 0 0 0.875rem;
  background: #fff;
  transform: translate(-50%, -50%);

  .head {
    h6 {
      box-sizing: border-box;
      width: 85%;
      padding: 1.25rem 0 0;
      padding-bottom: 17px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #f3f3f3;

      .align-left {
        text-align: left !important;
      }
    }

    .btn-layerclose {
      display: none;
    }

    + .content p {
      padding-top: 20px;
    }
  }

  .head-block {
    height: 64px;
    background-color: #3f3fff;

    h5,
    h6 {
      box-sizing: border-box;
      padding: 22px 0 0 30px;
      font-size: 17px;
      font-weight: normal;
      color: #fff;
      text-align: left;
      border-bottom: 0 !important;
    }
  }

  .head-bigtitle {
    h5,
    h6 {
      padding: 20px 0 10px 30px;
      font-size: 15px;
      font-weight: 500;
      color: #181818;
      text-align: left;
    }
  }

  .content {
    height: auto;
    max-height: 310px;
    overflow: hidden;
    overflow-y: auto;

    p {
      box-sizing: border-box;
      padding: 30px;
      font-size: 0.9375rem;
      line-height: 1.4;
      text-align: left;

      &.align-center {
        text-align: center;
      }

      .emcolor-blue {
        color: #3f3fff;
      }

      .emcolor-red {
        color: #ff4d78;
      }
    }

    .inner-scroll {
      box-sizing: border-box;
      width: 100%;
      padding: 27px 27px 0;
      text-align: left;

      .title {
        height: 24px;
        font-size: 14px;
        font-weight: 600;
        color: #181818;
      }

      .text {
        display: block;
        margin-top: -10px;
        margin-bottom: 25px;
        font-size: 13px;
        line-height: 1.4;
        color: #989898;
      }

      .list {
        margin: 0 0 25px;

        li {
          position: relative;
          padding-bottom: 5px;
          padding-left: 13px;
          font-size: 12px;
          line-height: 1.5;
          color: #010101;
          word-break: keep-all;

          &::before {
            position: absolute;
            top: -14px;
            left: 0;
            font-size: 28px;
            line-height: 1;
            content: '.';
          }

          &.point {
            color: #d83a4d;
          }
        }

        a {
          font-size: 12px;
          font-weight: 500;
          color: #3f3fff;
        }
      }

      .allplan {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.4;
        color: #989898;
        word-break: keep-all;
      }
    }
  }

  .foot {
    display: table;
    width: auto;
    padding: 10px 0 15px;
    margin: 0 auto;
    text-align: center;
    border-spacing: 10px;
    border-collapse: separate;

    button {
      display: table-cell;
      height: 40px;
      padding: 0 35px;
      margin: -10px 5px;
      font-size: 14px;
      font-weight: 600;
      line-height: 40px;
      color: #484848;
      text-align: center;
      background-color: #fff;
      border: 1px solid #bebebe;

      &.btn-d-blue {
        color: #fff;
        background-color: #3f3fff;
        border: 1px solid #3f3fff;
      }
    }
  }

  &.sktplan {
    height: 500px !important;

    h6 {
      width: 100% !important;
    }

    .content {
      height: 370px !important;
      max-height: 490px;
    }
  }
}

.layerSktPlan0campus_Wrapper {
  height: 380px;
}

.layerSktPlan0plan_Wrapper,
.layerSktPlan5gx_Wrapper {
  height: 395px;
}

.layerSktPlan0campus_Wrapper,
.layerSktPlan0plan_Wrapper,
.layerSktPlan5gx_Wrapper,
.layerSktPlan_Wrapper {
  height: auto;

  .head-bigtitle h6 {
    padding-left: 0;
  }
}

.layerSktPlan5gx_Wrapper {
  .content {
    max-height: 300px;
  }
}

.voucherCategoryLayer {
  .head-block {
    h5 {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .content {
    .inner-scroll {
      padding: 19px;

      .voucher-category-desc {
        padding: 0;
        margin: 0;
        font-size: 13px;
        color: #010101;

        &.warning {
          margin-top: 15px;
          color: #f00 !important;

          & + .voucher-category-table {
            margin-top: 20px;
          }
        }
      }

      h6.title {
        margin: 30px 0 0;
        font-size: 12px;
      }

      .voucher-category-table {
        width: 100%;
        border-spacing: 0;
        border: 0;

        th,
        td {
          padding: 8px 0;
          font-size: 13px;
          font-weight: normal;
          color: #606060;
          text-align: center;
          background: #f2f2f2;
        }

        td {
          font-size: 12px;
          background: none;
        }

        & + .voucher-category-table {
          margin-top: 15px;
        }
      }
    }
  }
}

.plan-benefit-explain-table {
  width: 105%;
  margin: 10px 0 10px -12px;
  color: #606060;
  border-spacing: 0;
  border: none;
  border-bottom: 1px solid #f2f2f2;

  th,
  td {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
  }

  th {
    width: 50%;
    height: 34px;
    background: #f2f2f2;
  }

  td {
    padding: 18px 10px;

    em {
      display: block;
      margin-top: 5px;
      font-size: 10px;
    }
  }
}
