$min_width: 955px;
$max_width: 1600px;
$header_height: 95px;
$font_default: #333;
$font_black: #000;
$font_white: #fff;
$font_grey: #666;
$font_blue: #3f3fff;
$font_purple: #3f3fff;
$bg_hover: #fafafa;
$bg_active: #f5f5f5;
$key_color: #3f3fff; // 주요 키 컬러 - TODO: 해당 변수로 공통 적용 예정

$miniplayer-height: 80px;
$player-ct-width: 575px;
$playlist-tab-height: 56px; // 플레이리스트 상단 탭 높이
$is-scroll-space: 100px; // player_cs, playlist_ct, playlist_scroll 스크롤을 감추기 위한 공통 여백
$player-ct-gap: 90px;
$player-wrapper-background-color: #262626;

// 홈 추천 패널 색상
$color-list-today: (#40274c, #33274c, #28274c, #27374c, #27494c); // 오늘의 FLO
$color-list-me: (#3e3667, #2f4575, #2e5b70, #396164, #487161); // 나를 위한 FLO
$color-list-artist: (#844141, #742f40, #7c3258, #74316e, #463174); // 아티스트 FLO
$color-list-theme: (#4c4863, #444e62, #465550, #674d45, #61484b); // 플레이리스트
$color-list-react: (#0f0649, #2b1f74, #22347f, #6d118c, #441c67); // 반응형 추천
$color-list-rcmmd-begin: (#a34426, #a29c89, #8aa491, #6687a3, #346d8f); // 맛보기 추천 (FLO와 14일 동안)

// 프로모션 공통
$color-btn_dim: #e1e3e7;
$color-text_3: #989898;
$color-text_7: #181818;
$color-background_line: #ebebeb;
$color-background: #fff;
$color-flo_red: #ff4d78;
$color-primary: #3f3fff;
$color-text_noti: rgba(255, 255, 255, 0.3);
$color-flo_white: #fff;
$color-section: #ccc;

// for payment coupon
$color-ticket_bg: #0e0e0e;
$color-ticket_box: #212226;
$color-point_text: #f04949;
$color-text_6: #484848;
$color-text_0: #989898;
$color-text_1: #989898;
$color-text_2: #989898;
$color-text_3: #989898;
$color-text_4: #989898;
$color-btn_dim: #e1e3e7;
