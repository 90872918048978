$player-track-list-table-cell-height: 64px;

.artist_intro {
  @include clearfix;

  padding-top: 40px;

  .thumb {
    position: relative;
    float: left;

    .multiple_artist {
      @include multiple_artist(110px, 30px, 3);
    }

    .btn_more {
      @include retina-sprite($sp-button-btn-artist-add-nor-group);

      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 50;

      &:hover {
        @include retina-sprite($sp-button-btn-artist-add-press-group);
      }

      &.active {
        @include retina-sprite($sp-button-btn-artist-add-sel-group);

        & + .layer_dropdown {
          display: block;
        }
      }
    }

    // 아티스트 4명 이상 스크롤
    .multiple_artist_scroll {
      position: relative;
      float: left;
      -ms-overflow-style: auto;

      &::-webkit-scrollbar {
        display: block;
      }

      // 그라데이션
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 25px;
        content: '';
        background: linear-gradient(
          to right,
          rgba(38, 38, 38, 0) 0%,
          rgba(38, 38, 38, 0.55) 51%,
          rgba(38, 38, 38, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }

      .multiple_artist {
        box-sizing: border-box;
        float: none;
        max-width: 360px;
        height: 110px;
        overflow: auto hidden;
        white-space: nowrap;

        > li {
          float: none;
        }
      }
    }
  }

  .layer_dropdown {
    top: 120px;
    right: -(200px - 30px);
    z-index: 10;
    display: none;
  }

  dl {
    @include clearfix;

    box-sizing: border-box;
    float: left;
    max-width: 100%;
    padding-left: 25px;

    dt {
      @include ellipsis1;

      padding-top: 30px;
      padding-bottom: 7px;
      font-size: 21px;
      color: #fff;

      a {
        color: #fff;
      }
    }

    dd {
      position: relative;
      float: left;
      padding-right: 10px;
      padding-left: 11px;
      font-size: 15px;
      color: #a0a0a0;

      &::before {
        position: absolute;
        top: 3px;
        left: 0;
        width: 1px;
        height: 9px;
        content: '';
        background-color: #787878;
        opacity: 0.4;
      }

      &:first-of-type {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }
}

.playlist_track_header {
  @include clearfix;

  padding-top: 32px;
  padding-bottom: 15px;
  clear: both;

  .btn_all_listen {
    padding-left: 11px;
    font-size: 14px;
    color: #fff;

    &::before {
      @include retina-sprite($sp-button-btn-list-top-play-w-nor-group);

      display: inline-block;
      vertical-align: middle;
      content: '';
    }
  }

  .sort_type_drop {
    position: relative;
    float: right;
  }

  .layer_dropdown {
    top: 28px;
    right: 0;
    z-index: 10;
    width: 154px;
  }
}

.playlist_track_list {
  overflow-y: auto;

  .track_list_table {
    tr {
      &.disabled {
        td::after {
          background-color: rgba($color: $player-wrapper-background-color, $alpha: 0.6);
        }
      }

      &.checked {
        td {
          background: rgba(255, 255, 255, 0.03);
        }
      }
    }

    th {
      color: #666;
      border-color: #464646;
    }

    td {
      height: $player-track-list-table-cell-height;
      border-color: #2f2f2f;

      label {
        height: $player-track-list-table-cell-height;
      }

      &.info {
        padding-left: 5px;

        .info_wrap {
          height: 45px;
          padding-left: 60px;

          &.in-player-track {
            cursor: pointer;
          }
        }

        .thumb {
          width: 45px;
          height: 45px;
        }

        .txt_area {
          max-width: 320px;

          .tit {
            padding-top: 5px;
            color: #fff;
          }

          .album {
            padding-top: 4px;
            font-size: 12px;
            color: #666;
          }

          .count {
            font-size: 12px;
            color: #666;
          }
        }
      }

      .more_wrap {
        height: $player-track-list-table-cell-height;
      }

      .btn_listen {
        @include retina-sprite($sp-button-btn-player-music-play-nor-group);

        &:hover {
          @include retina-sprite($sp-button-btn-player-music-play-press-group);
        }
      }

      .btn_more {
        @include retina-sprite($sp-button-btn-player-music-more-nor-group);

        &:hover {
          @include retina-sprite($sp-button-btn-player-music-more-press-group);
        }
      }
    }
  }
}

.big_size {
  .multiple_artist {
    @include multiple_artist(180px, 80px, 3);

    padding-right: 0;
  }
}
