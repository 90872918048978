
          @import '@/assets/scss/common/color.scss';
        

.util_area {
  button {
    color: rgba(255, 255, 255, 0.4);

    &:hover {
      color: rgba(255, 255, 255, 1);
      transform: scale(1.1);
    }
  }
  #{&} > &__empty {
    color: #333;
    cursor: initial;

    &:hover {
      color: #333;
      transform: initial;
    }
  }
}
