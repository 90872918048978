
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.full-player {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #0f0e0e;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(100px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    opacity: 0.2;
    will-change: transform;
  }

  &__inner {
    @include respond-to(mediaquery-large) {
      overflow: hidden;
    }

    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &__content {
    @include respond-to(mediaquery-large) {
      flex-direction: row;
      padding-bottom: 80px;
    }

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    height: 100px;
    padding-bottom: 90px;
  }

  &__player {
    width: 100%;
  }

  &__playlist {
    @include respond-to(mediaquery-large) {
      padding-bottom: 0;
    }

    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
  }
}
