
          @import '@/assets/scss/common/color.scss';
        

.playlist-edit {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__text {
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 14px;
    color: #989898;
  }

  &__sort {
    position: relative;
  }

  &__select {
    margin-left: 4px;
  }

  &__arrow {
    vertical-align: middle;

    &.active {
      transform: scaleY(-1);
    }
  }

  &__list {
    position: absolute;
    top: 30px;
    right: -50px;
    z-index: 10;
    width: 200px;
    padding: 10px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-size: 15px;

    &:hover {
      background: #f5f5f5;
    }

    &.active {
      color: #3f3fff;
    }
  }

  &__space {
    width: 75px;
  }
}
