
          @import '@/assets/scss/common/color.scss';
        

.electron_app {
  // NOTE: electron app 상태바에서 여백을 차지하는 이슈가 있어서 추가
  .player-header {
    padding: 60px 55px 20px;
  }
}

.player-header {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 30px 55px 20px;

  &__setting {
    margin-right: 20px;
  }
}
