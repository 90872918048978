@import '~@/assets/scss/common/base.icon';

.album_thumbnail {
  position: relative;
  width: 175px;
  height: 175px;
  padding-top: 14px;
  margin-bottom: 15px;
  line-height: 175px;
  text-align: center;
  background: transparent;

  // 장르 콜렉션 하단 마진 삭제
  @at-root .thumbnail_list {
    li {
      .album_thumbnail:only-child {
        margin-bottom: 0;
      }
    }
  }

  &.sm_size {
    width: 157px;
    height: 157px;
  }

  .thumbnail__icon--original {
    @include hide_text;
    @include retina-sprite($sp-icon-icon-flo-original-home-group);

    position: absolute;
    top: 25px;
    left: 10px;
    z-index: 10;
  }

  .thumbnail__icon--original-big {
    @include hide_text;
    @include retina-sprite($sp-icon-icon-flo-original-badge-group);

    position: absolute;
    top: 25px;
    left: 14px;
    z-index: 10;
  }

  .thumbnail__icon--right {
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 10;
    height: 0;

    .thumbnail__icon--adult {
      @include hide_text;
      @include retina-sprite($sp-icon-icon-adult-home-group);

      display: inline-block;
      vertical-align: top;
    }

    .thumbnail__icon--up {
      @include hide_text;
      @include retina-sprite($sp-icon-icon-up-home-group);

      display: inline-block;
      vertical-align: top;
    }

    .thumbnail__icon--up-big {
      @include hide_text;
      @include retina-sprite($sp-icon-icon-up-badge-group);

      position: absolute;
      right: 1px;
      z-index: 10;
    }
  }

  .link_thumbnail {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f8f9fa;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 6px;
    }

    img {
      vertical-align: top;
      border-radius: 6px;
    }

    &:hover,
    &:active {
      &::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .btn_thumbnail_play {
    position: absolute;
    right: 11px;
    bottom: 15px;
    z-index: 3;
    @include hide_text;
    @include retina-sprite($sp-button-btn-play-thumb-157-nor-group);

    &:hover {
      @include retina-sprite($sp-button-btn-play-thumb-157-hover-group);
    }

    &:active {
      @include retina-sprite($sp-button-btn-play-thumb-157-press-group);
    }

    &:disabled {
      @include retina-sprite($sp-button-btn-play-thumb-157-press-group);
    }

    @at-root .big_size {
      .album_thumbnail {
        .btn_thumbnail_play {
          right: 11px;
          bottom: 15px;
          @include retina-sprite($sp-button-btn-play-thumb-188-nor-group);

          &:hover {
            @include retina-sprite($sp-button-btn-play-thumb-188-hover-group);
          }

          &:active {
            @include retina-sprite($sp-button-btn-play-thumb-188-press-group);
          }

          &:disabled {
            @include retina-sprite($sp-button-btn-play-thumb-188-press-group);
          }
        }
      }
    }
  }

  .btn_thumbnail_play_sm {
    position: absolute;
    right: 9px;
    bottom: 12px;
    z-index: 3;
    @include hide_text;
    @include retina-sprite($sp-button-btn-play-thumb-157-nor-group);

    &:hover {
      @include retina-sprite($sp-button-btn-play-thumb-157-hover-group);
    }

    &:active {
      @include retina-sprite($sp-button-btn-play-thumb-157-press-group);
    }
  }

  // 캐릭터 설정
  &.type_character {
    margin: 0 auto;

    .link_thumbnail {
      overflow: hidden;
      background-color: transparent;

      img,
      &::before {
        border-radius: 188px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .thumb {
        @include thumb_round(100%);

        background-color: #f8f9fa;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    &:hover,
    &:active {
      &::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.1);
      }

      .btn_thumbnail_del {
        position: absolute;
        right: 48px;
        bottom: 69px;
        z-index: 3;
        @include hide_text;
        @include retina-sprite($sp-button-btn-character-imgae-del-nor-group);

        &:hover {
          @include retina-sprite($sp-button-btn-character-imgae-del-hover-group);
        }

        &:active {
          @include retina-sprite($sp-button-btn-character-imgae-del-press-group);
        }
      }

      .btn_thumbnail_choice {
        position: absolute;
        bottom: 69px;
        left: 69px;
        z-index: 3;
        @include hide_text;
        @include retina-sprite($sp-button-btn-character-image-choice-nor-group);

        &:hover {
          @include retina-sprite($sp-button-btn-character-image-choice-hover-group);
        }

        &:active {
          @include retina-sprite($sp-button-btn-character-image-choice-press-group);
        }
      }
    }
  }

  // 아티스트
  &.type_artist {
    .link_thumbnail {
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      border-radius: 175px;

      img,
      &::before {
        border-radius: 175px;
      }

      // TODO: 추후 이미지 대응
      // img {
      //     width: 100%;
      //     height: auto;
      //     min-height: 100%;
      // }
    }

    .album_thumbnail {
      overflow: hidden;
      background: blue;
      border-radius: 188px;
    }

    .btn_thumbnail_play {
      right: 0;
      bottom: 0;
      @include retina-sprite($sp-button-btn-artist-play-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-artist-play-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-artist-play-press-group);
      }
    }

    .btn_thumbnail_play_sm {
      right: 0;
      bottom: 0;
      @include hide_text;
      @include retina-sprite($sp-button-btn-play-artist-157-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-play-artist-157-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-play-artist-157-press-group);
      }
    }
  }
}

.big_size {
  .album_thumbnail,
  .link_thumbnail {
    width: 240px;
    height: 240px !important; // big size 대응 ( http://jira.skplanet.com/browse/GODMUSIC-7796 )
  }
}

.thumbnail_chart {
  display: inline-block;
  font-size: 0;
  color: $font_white;
  vertical-align: top;

  .chart_thumbnail {
    @include thumb_rect(53px);

    vertical-align: top;
    border-radius: 4px;
  }

  .chart_ranking {
    display: inline-block;
    width: 27px;
    margin: 5px 0 0 7px;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: $font_white;
    text-align: center;
    vertical-align: top;

    .rank_status {
      display: block;
      margin: 2px 0 0;
      font-size: 12px;

      &.up,
      &.down {
        &::before {
          position: relative;
          display: inline-block;
          margin-right: -1px;
          content: '';
          opacity: 1;
        }
      }

      &.up {
        color: #ff4d78;

        &::before {
          top: -1px;
          @include retina-sprite($sp-icon-ic-main-chart-up-group);
        }
      }

      &.down {
        color: #3f3fff;

        &::before {
          @include retina-sprite($sp-icon-ic-main-chart-down-group);
        }
      }
    }
  }

  .chart_title {
    @include ellipsis1;

    display: inline-block;
    max-width: 246px;
    margin: 6px 0 0 11px;
    font-size: 15px;
    font-weight: normal;
    vertical-align: top;

    .chart_artist {
      display: block;
      margin-top: 4px;
      font-size: 12px;
      color: #fff;
      opacity: 0.6;
    }
  }
}

.btn_create_playlist {
  position: relative;
  display: block;
  margin: 14px 0 0;

  &:hover {
    .type_newlist_plus {
      background-color: #ebebeb;
    }
  }

  &:active {
    .type_newlist_plus {
      .storage_plus {
        @include retina-sprite($sp-button-btn-storage-plus-press-group);
      }
    }
  }
}

.type_newlist_plus {
  position: relative;
  display: inline-block;
  width: 175px;
  height: 175px;
  vertical-align: top;
  background-color: #f6f6f6;
  border-radius: 10px;

  &:hover {
    background-color: #ebebeb;
  }

  &:active {
    .storage_plus {
      @include retina-sprite($sp-button-btn-storage-plus-press-group);
    }
  }

  .storage_plus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: inline-block;
    margin: auto;
    @include retina-sprite($sp-button-btn-storage-plus-nor-group);
    @include hide_text;
  }
}

.newlist_plus_text {
  top: 50%;
  display: inline-block;
  padding-left: 20px;
  font-size: 15px;
  color: $font_blue;
  vertical-align: top;
  transform: translateY(78px);
}

// 검색결과 아티스트탭 썸네일 대응 ( http://jira.skplanet.com/browse/GODMUSIC-7796 )
.artist_thumbnail_list {
  &.sm_size {
    .type_artist {
      .link_thumbnail {
        height: 100%;
      }
    }
  }
}

// flo 추천
.recommend_thumbnail {
  position: relative;
  margin-bottom: 12px;

  &:only-child {
    margin-bottom: 0;
  }

  .link_thumbnail {
    position: relative;
    display: block;
    width: 100%;
    height: 175px;
    overflow: hidden;
    border-radius: 6px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }

    .link_title {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 5;
      max-width: 155px;
      font-size: 18px;
      font-weight: normal;
      line-height: 24px;
      color: #fff;
      @include ellipsis_multi(3, '');
    }

    .date {
      position: absolute;
      top: 14px;
      right: 14px;
      z-index: 5;
      font-size: 13px;
      color: #fff;

      [class^='ico_'] {
        margin-left: 5px;
      }
    }

    &.type_single {
      .date {
        top: auto;
        bottom: 22px;
        left: 20px;
      }
    }
  }

  .btn_thumbnail_play {
    position: absolute;
    right: 9px;
    bottom: 12px;
    z-index: 5;
    @include hide_text;
    @include retina-sprite($sp-button-btn-play-thumb-157-nor-group);

    &:hover {
      @include retina-sprite($sp-button-btn-play-thumb-157-hover-group);
    }

    &:active {
      @include retina-sprite($sp-button-btn-play-thumb-157-press-group);
    }

    &:disabled {
      @include retina-sprite($sp-button-btn-play-thumb-157-press-group);
    }
  }

  @at-root .recommend_list {
    &.type_flo_today {
      @for $i from 1 through length($color-list-today) {
        li:nth-of-type(#{$i}) {
          .recommend_thumbnail a {
            background-color: nth($color-list-today, $i);
          }
        }
      }
    }

    &.type_flo_me {
      @for $i from 1 through length($color-list-me) {
        li:nth-of-type(#{$i}) {
          .recommend_thumbnail a {
            background-color: nth($color-list-me, $i);
          }
        }
      }
    }

    &.type_flo_artist {
      @for $i from 1 through length($color-list-artist) {
        li:nth-of-type(#{$i}) {
          .recommend_thumbnail a {
            background-color: nth($color-list-artist, $i);
          }
        }
      }
    }

    &.type_rcmmd_begin {
      @for $i from 1 through length($color-list-rcmmd-begin) {
        li:nth-of-type(#{$i}) {
          .recommend_thumbnail a {
            background-color: nth($color-list-rcmmd-begin, $i);
          }
        }
      }
    }
  }
}

.recommend_thumb_single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 175px;

  .thumb_box {
    width: 100%;
    height: 100%;

    .bg_thumbnail {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}

.recommend_thumb_group {
  position: relative;
  width: 310px;
  height: 100%;
  margin: 0 auto;

  .thumb_box {
    position: relative;

    // top: 21px;
    z-index: 3;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .bg_thumbnail {
      position: absolute;
      top: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      transform: translate(0, -50%);
      @include thumb_rect(110px);

      &:nth-child(1) {
        left: 50%;
        z-index: 4;
        background-size: cover;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        transform: translate(-50%, -50%);
        @include thumb_rect(132px);
      }

      &:nth-child(2) {
        left: 0;
      }

      &:nth-child(3) {
        right: 0;
      }
    }
  }

  &.type_artist {
    .thumb_box {
      .bg_thumbnail {
        @include thumb_round(114px);

        background-size: cover;

        &:nth-child(1) {
          @include thumb_round(132px);

          background-size: cover;
        }
      }
    }
  }

  &.big {
    width: 452px;

    .thumb_box {
      .bg_thumbnail {
        width: 170px;
        height: 170px;

        &:nth-child(1) {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  // 썸네일 양쪽 대칭  (반응형 추천 패널)
  &.type_symmetry {
    top: -5px;
    width: 438px;
    margin: 0;

    .thumb_box {
      .bg_thumbnail {
        left: 0;
        width: 170px;
        height: 170px;
        overflow: hidden;
        background-color: rgba(255, 255, 255, 0.2);
        transform: translate(0, -50%);

        &:last-child {
          right: 0;
          left: auto;
        }
      }
    }

    .ico {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & + .recommend_album_title {
      max-width: 438px;
    }
  }
}

// 썸네일 내부에 들어가는 조각 썸네일 (반응형 추천 패널 참고)
.piece_thumbnail {
  float: left;
  width: 50%;
  height: 50%;
  background-size: cover;
}

// stretch thumbnail list
.list_thumbnail_stretch {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  overflow: hidden;

  > li {
    width: 25%;
    margin-bottom: 20px;

    .thumbnail_stretch {
      box-sizing: border-box;
      margin-left: 20px;

      @media screen and (max-width: 955px) {
        margin-left: 16px;
      }
    }
  }
}

// stretch thumbnail (배경이미지 한장을 n% 확대하여 배경색상으로 채우는 thumbnail type)
.thumbnail_stretch {
  position: relative;
  height: 153px;
  overflow: hidden;
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 300% auto;
  border-radius: 6px;

  // 링크인 영역에 cursor 적용
  a[class='thumbnail_stretch_inner'] {
    cursor: pointer;
  }

  .thumbnail_stretch_title {
    position: relative;
    z-index: 20;
    display: block;
    padding-right: 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.47;
    color: #fff;
    word-break: break-all;
  }

  .label_new,
  .button_play {
    position: absolute;
    z-index: 20;
  }

  // new label
  .label_new {
    top: 14px;
    right: 17px;

    @include retina-sprite($sp-storage-recommend-icon-small-new-group);
  }

  // 전체듣기 버튼
  .button_play {
    right: 13px;
    bottom: 11px;

    @include retina-sprite($sp-storage-recommend-btn-play-thumb-157-nor-group);

    &:hover {
      @include retina-sprite($sp-storage-recommend-btn-play-thumb-157-hover-group);
    }
  }

  // 950 media query
  @media screen and (max-width: 955px) {
    height: 110px;

    .button_play {
      @include retina-sprite($sp-storage-recommend-btn-play-thumb-157-nor-sm-group);

      &:hover {
        @include retina-sprite($sp-storage-recommend-btn-play-thumb-157-hover-sm-group);
      }
    }
  }
}

// hover시 효과
.thumbnail_stretch_hover {
  .thumbnail_stretch_inner {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    .thumbnail_stretch_inner {
      &::after {
        content: '';
      }
    }
  }
}

.thumbnail_stretch_inner {
  position: relative;
  box-sizing: border-box;
  display: block;
  height: 100%;
  padding: 15px 20px 18px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    content: '';
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 6px;
  }
}

// 오른쪽에 위치하는 thumbnail img object
.thumbnail_strench_object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

// 추천패널 보관함 추천에 사용되는 썸네일 묶음
.thumbnails_decrescendo {
  display: flex;
  align-items: center;

  .thumb_box {
    position: relative;
    width: 175px;
    height: 175px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }

    .bg_thumbnail {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      z-index: 15;

      &::after {
        background-color: transparent;
      }
    }

    &:nth-child(2) {
      z-index: 10;
      width: 142px;
      height: 142px;
      margin-left: -68px;

      .bg_thumbnail {
        opacity: 0.95;
      }
    }

    &:last-child {
      z-index: 5;
      width: 110px;
      height: 110px;
      margin-left: -56px;

      &::after {
        background-color: rgba(0, 0, 0, 0.16);
      }

      .bg_thumbnail {
        opacity: 0.9;
      }
    }
  }
}

// 썸네일 리스트
.thumbnail_list {
  // 플리 큐레이션 리스트
  &.curation_thumbnail_list {
    > li {
      width: 370px;

      .album_thumbnail {
        width: 370px;
        height: 370px;

        img {
          width: 100% !important;
          height: 100% !important;
        }

        // 플리 큐레이션 썸네일의 .type_playlist 클래스 제거가 복잡하여 상단배경제거 css로 대응
        &.type_playlist {
          margin-top: 0;

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.thumbnail_item_area {
  &.dimmed {
    position: relative;

    &::after {
      @include searchDimmed;
    }
  }
}

// 앨범리스트, 뱃지에서 .piece_thumbnail 감싸는 요소 (.piece_thumbnail_group > .piece_thumbnail)
.piece_thumbnail_group {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
}
