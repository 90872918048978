.section_info,
.section_info-control {
  i[class^='icon_'] {
    display: inline-block;
    margin-left: 10px;
    @include hide_text;

    &:first-child {
      margin-right: 10px;
      margin-left: 0;
    }
  }

  .icon_naver {
    @include retina-sprite($sp-icon-icon-naver-s-group);
  }

  .icon_kakao {
    @include retina-sprite($sp-icon-icon-kakao-s-group);
  }

  .icon_skt {
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/img/icon_skt.svg');
    background-repeat: no-repeat;
  }

  .icon_apple {
    @include retina-sprite($sp-icon-icon-apple-s-group);
  }

  .info_box {
    position: relative;
    padding: 35px 50px;
    margin-top: 25px;
    border: 1px solid #eee;
    border-radius: 5px;

    .info_email {
      font-size: 25px;
      font-weight: bold;
    }

    .info_email_sns {
      display: inline-block;
      padding-top: 15px;
      vertical-align: middle;

      span {
        position: relative;
        display: block;
        padding-right: 12px;
        margin-right: 15px;
        font-size: 15px;
        color: #999;

        &::after {
          position: absolute;
          top: 4px;
          right: 0;
          display: block;
          width: 1px;
          height: 8px;
          content: '';
          background-color: #dcdcdc;
        }
      }
    }

    dl {
      display: inline-block;
      padding-top: 15px;
      overflow: hidden;
      vertical-align: middle;

      dd {
        position: relative;
        float: left;
        padding: 0 12px 0 15px;
        font-size: 15px;
        color: #999;

        &:first-of-type {
          padding-left: 0;

          &::after {
            display: none;
          }
        }

        &:last-of-type {
          padding-left: 0;

          &::after {
            display: none;
          }
        }

        &::after {
          position: absolute;
          top: 4px;
          left: 0;
          display: block;
          width: 1px;
          height: 8px;
          content: '';
          background-color: #dcdcdc;
        }

        i.number {
          font-style: normal;
          color: $font_blue;
        }
      }
    }

    .btn_sell {
      position: absolute;
      top: 56px;
      right: 50px;
      font-size: 16px;
      font-weight: bold;
      color: $font_blue;
    }
  }

  .newlist_tab {
    margin-top: 37px;

    .btn_tab_normal {
      font-size: 14px;
      border: 1px solid #ebebeb;
    }

    .on {
      .btn_tab_normal {
        border: none;
      }
    }
  }

  .sort_type_wrap {
    top: 6px;
  }

  .input_section {
    padding-top: 27px;
    margin: 0 15px;

    li {
      span {
        float: left;
        padding-top: 30px;
        padding-right: 20px;

        &.regist_time {
          float: right;
          padding-top: 8px;
        }

        & button.btn_bg_blue_s {
          margin-top: 11px;
        }
      }

      &::after {
        display: block;
        clear: both;
        content: '';
      }

      &:nth-child(2) {
        padding-top: 20px;
      }
    }

    &.type_social {
      li {
        padding-top: 11px;

        &:nth-child(1),
        &:nth-child(2) {
          padding-top: 0;
        }

        span {
          padding-top: 20px;

          &.email {
            width: 440px;
            padding-top: 34px;

            &.disabled {
              color: #c4c4c4;

              i[class^='icon_'] {
                opacity: 0.2;
              }
            }

            span {
              display: block;
            }
          }
        }
      }
    }
  }

  .social_section {
    padding-top: 57px;
    margin: 0 15px;

    li {
      display: table;
      padding-top: 48px;

      &:first-child {
        padding-top: 0;
      }

      > span {
        display: table-cell;
        vertical-align: middle;

        &:first-child {
          width: 440px;
          padding-right: 20px;
        }

        .icon_area {
          display: table-cell;
          vertical-align: middle;
        }

        .text {
          display: table-cell;
          padding-left: 10px;
          vertical-align: middle;
        }

        .date {
          display: block;
          padding-top: 5px;
          font-size: 13px;
          color: #929292;
        }

        &.disabled {
          i[class^='icon_'] {
            opacity: 0.2;
          }

          .text,
          .date {
            color: #c4c4c4;
          }
        }
      }

      // naver API
      > div {
        display: table-cell;
        vertical-align: middle;

        &.hidden {
          display: none;
        }

        a {
          img {
            vertical-align: top;
          }
        }
      }

      &::after {
        display: block;
        clear: both;
        content: '';
      }
    }
  }
}

%login_box_style {
  position: relative;
  width: 800px;
  padding: 92px 0 85px;
  margin: 41px auto;
  text-align: center;
  border: 1px solid #eee;

  .sub_text {
    padding-top: 12px;
    font-size: 13px;
    color: #989898;
  }

  .popup_button_w {
    padding-top: 41px;
  }

  .btn_bg_blue_s {
    min-width: 150px;
    height: 40px;
    border-radius: 0;
  }
}

.login_box {
  @extend %login_box_style;
}

.myvoucher {
  display: block;
  width: 800px;
  margin: 40px auto 0;
  font-size: 16px;
}

.voucher_box {
  @extend %login_box_style;

  padding: 109px 0;
  margin: 6px auto;

  .myvoucher_text {
    font-size: 17px;
    color: #999;
  }

  .myvoucher_text + .popup_button_w {
    padding-top: 27px;
  }
}

.voucher_box + .myvoucher {
  margin: 55px auto 0;
}

.error_01 {
  padding: 165px 0 100px;
  text-align: center;

  .main_text {
    font-size: 30px;
  }

  .sub_text {
    padding-top: 10px;
    font-size: 14px;
    color: #666;
  }

  .popup_button_w {
    padding-top: 63px;

    button,
    a {
      margin: 0 4px;
    }
  }
}

.error_02 {
  padding: 165px 0 100px;
  text-align: center;

  .main_text {
    font-size: 30px;
  }

  .sub_text {
    padding-top: 10px;
    font-size: 16px;
  }

  .error_m_text {
    padding-top: 26px;
    font-size: 14px;
    line-height: 1.57;
    color: #666;
    text-align: center;
    white-space: pre-wrap;
  }
}

/* 플레이어 다운로드 */
.player_download {
  padding: 220px 0 100px;
  text-align: center;

  .text_group {
    width: 254px;
    min-height: 137px;
    padding-right: 258px;
    margin: 0 auto;
    background: url('../../assets/img/img-playerscreen.png') no-repeat 282px 0;
    background-size: 214px 137px;

    .main_text {
      padding: 24px 0 6px;
      font-size: 30px;
    }

    .sub_text {
      font-size: 14px;
      line-height: 22px;
      color: #666;
    }
  }

  .popup_button_w {
    padding-top: 45px;
  }

  .button-download {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    min-width: 232px;
    height: 62px;
    padding: 19px 20px 21px;
    margin: 0 7px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background-color: #3f3fff;
    border-radius: 5px;

    &:hover {
      color: #fff;
    }

    &__icon {
      &::before {
        display: inline-block;
        margin: -2px 3px 0 -3px;
        vertical-align: middle;
        content: '';
      }
    }

    &--window {
      .button-download__icon {
        &::before {
          @include retina-sprite($sp-icon-ico-windows-group);
        }
      }
    }

    &--mac {
      .button-download__icon {
        &::before {
          @include retina-sprite($sp-icon-ico-mac-group);
        }
      }
    }
  }
}

/* 아티스트플로 이용권 */
.artist_voucher {
  padding-top: 40px;

  .voucher_title {
    padding-bottom: 13px;
    font-size: 16px;
    font-weight: bold;
    color: $font_black;
  }

  .voucher_cont {
    box-sizing: border-box;
    display: table;
    width: 100%;
    margin-bottom: 40px;
    border: 1px solid #d9d9d9;

    .title_area {
      position: relative;
      display: table-cell;
      width: 300px;
      vertical-align: top;

      .title {
        img {
          width: 100%;
          vertical-align: top;
        }
      }

      .voucher_detail {
        position: absolute;
        bottom: 30px;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        padding: 0 28px;

        a {
          display: block;
          font-size: 13px;
          font-weight: bold;
          line-height: 46px;
          color: $font_white;
          text-align: center;
          background-color: $font_blue;
        }
      }
    }

    .voucher_area_end {
      position: relative;
      display: table-cell;
      padding: 0 30px;
      text-align: center;
      vertical-align: middle;

      img {
        width: 50%;
      }

      .btn_benefit_detail {
        position: absolute;
        right: 0;
        bottom: 53px;
        left: 0;
        z-index: 99;
        display: block;
        width: 183px;
        height: 40px;
        margin: 0 auto;
        color: transparent;
      }
    }

    .voucher_area {
      // position:relative;
      // top:-60px;
      display: table-cell;
      padding: 0 30px;
      vertical-align: middle;

      li {
        margin-bottom: 77px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .voucher_name {
        padding-bottom: 12px;
        border-bottom: 1px solid #f3f3f3;

        .name {
          font-size: 18px;
          font-weight: bold;
          color: $font_black;
        }

        .text {
          padding-top: 5px;
          font-size: 13px;
          color: #999;
        }
      }

      .voucher_item {
        position: relative;
        height: 100px;
        border-bottom: 1px solid #f3f3f3;

        .title {
          position: absolute;
          top: 50%;
          left: 0;
          font-size: 17px;
          color: $font_black;
          transform: translate(0, -50%);

          .info {
            display: block;
            padding-top: 6px;
            font-size: 12px;
            color: #999;
          }
        }

        .price_box {
          position: absolute;
          top: 50%;
          right: 106px;
          text-align: right;
          transform: translate(0, -50%);

          .price {
            display: block;
            font-size: 22px;
            font-weight: bold;
            color: $font_purple;
          }

          .first_cost {
            display: block;
            font-size: 16px;
            color: #999;
            letter-spacing: -0.26px;
          }
        }

        .btn_buy {
          position: absolute;
          top: 50%;
          right: 0;
          width: 90px;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          line-height: 40px;
          color: $font_white;
          text-align: center;
          background-color: $font_purple;
          transform: translate(0, -50%);
        }
      }

      .voucher_anf_info {
        display: block;
        padding-top: 6px;
        margin-top: 10px;
        font-size: 13px;
        line-height: 1.8;
        color: #999;
      }

      .voucher-link {
        padding-top: 12px;

        a {
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }

    .voucher-link {
      padding-top: 20px;
      font-size: 12px;
      line-height: 18px;

      a {
        color: #333;
        text-decoration: underline;
      }
    }
  }
}

/* 내이용권 - 아티스트플로 배너 */
.artist_banner {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 206px;
  padding: 0 247px 0 61px;
  margin-top: 20px;
  background-image: url('../../assets/img/artist_banner_exo_pc.png');
  background-size: cover;

  .title {
    padding-top: 45px;

    img {
      width: 112px;
      vertical-align: top;
    }
  }

  .now_turn {
    padding-top: 12px;

    .turn_info {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
    }

    .turn_state {
      position: relative;
      top: 2px;
      display: inline-block;
      width: 59px;
      margin-left: 8px;
      font-size: 13px;
      line-height: 28px;
      color: #fff;
      text-align: center;
      vertical-align: top;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        content: '';
        border: 1px solid #fff;
        border-radius: 16px;
        opacity: 0.5;
      }
    }
  }

  .now_period {
    padding-top: 24px;
    font-size: 15px;
    line-height: 28px;
    color: #fff;

    .date {
      padding-left: 14px;
    }
  }

  .btn_artist {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 247px;
    height: 100%;

    a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 17px;
      line-height: 26px;
      color: #5e78ff;
      text-align: center;

      span {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0, -50%);
      }
    }
  }
}

// 선택 동의 설정
.policy_info {
  padding-top: 47px;
  margin: 0 15px;

  li {
    padding: 10px 0;

    > span {
      display: inline-block;
      font-size: 15px;
      color: #181818;

      &:first-child {
        min-width: 490px;
      }

      em {
        color: $key_color;
      }

      .btn_detail {
        padding-left: 6px;
        font-size: 13px;
        color: #929292;
        text-decoration: underline;
      }

      .inp_radio + label {
        margin-right: 24px;
        font-size: 14px;
        color: #181818;
      }
    }
  }
}

// error 공통
.errorpage {
  padding: 100px 0;

  .errorpage__layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .errorpage__logo {
    width: 140px;
    height: 70px;
    margin-bottom: 50px;
    background: url('~@/assets/img/error_img_logo.png') 0 0 / 100% auto no-repeat;
  }

  .errorpage__main-text {
    font-size: 30px;
    line-height: 1.45;
    text-align: center;
  }

  .errorpage__button {
    margin-top: 60px;
  }
}
