html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family:
    'Pretendard Variable',
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    Roboto,
    'Segoe UI',
    Helvetica,
    sans-serif;
  font-size: 100%;
  font-weight: 400;
  color: $font_default;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none;
}

html,
body,
form,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
ul,
ol,
li,
fieldset,
th,
td,
button,
em,
span,
address {
  padding: 0;
  margin: 0;
  border: 0;

  // color: $font_default;
}

img {
  margin: 0;
  border: 0;
}

legend {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

caption {
  @include hide_text;
}

input,
textarea,
select,
button {
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

input[type='button'],
input[type='text'],
input[type='image'],
input[type='submit'],
input[type='password'],
input[type='tel'],
input[type='number'],
select,
textarea {
  appearance: none;
  border-radius: 0;
}

input[type='radio'],
input[type='checkbox'] {
  appearance: checkbox;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

ol,
ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

em {
  font-style: normal;
}

#app {
  padding-bottom: $miniplayer-height;
  overflow: hidden;
}

#wrap {
  width: 100%;
  min-width: $min_width;
  max-width: $max_width;
  height: 100%;
  margin: 0 auto;
}

.ct_container,
.section_recent,
.section_new,
.section_browse,
.section_storage,
.section_purchase,
.section_member,
.section_search,
.section_detail,
.section_info,
.section_character,
.section_help,
.voucher_info,
.section_error,
.section_discovery,
.section_download,
.section_editors-pick,
.section_donotplay {
  height: 100%;
  padding: 95px 80px 40px;
  background-color: #fff;
  @include respond-to(mediaquery-small) {
    padding: 95px 30px 40px;
  }

  a {
    color: $font_default;

    &:hover {
      color: $key_color;
    }

    &:active {
      color: #c2c2c2;
    }
  }
}

.sp-icon {
  @include hide_text;
}

.ir {
  @include hide_text;
}

.hidden {
  @include hide_text;

  z-index: -1;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

.lb {
  display: block;
}

.user_character {
  display: block;
  font-size: 13px;
  color: #000;

  .character_thumbnail {
    position: relative;
    top: -2px;
  }
}

.btn_keyword {
  display: inline-block;
  height: 26px;
  padding: 0 9px;
  font-size: 12px;
  line-height: 26px;
  color: $font_default;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 18px;

  &:hover {
    color: #3f3fff;
    border-color: #3f3fff;
  }

  &:active {
    color: $font_default;
    background-color: $bg_active;
    border-color: #ccc;
  }
}

.search_keyword {
  font-style: normal;
  color: $font_purple;
}

.thumb_round {
  @include thumb_round(40px);
}

.thumb_rect {
  @include thumb_rect(40px);
}

// 대체텍스트를 위한 텍스트숨김스타일 앞으로 이 클래스 사용
.blind {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

// 공통링크 : 오른쪽 화살표붙은
.link_arrow {
  font-size: 16px;
  color: #333;

  &::after {
    @include retina-sprite($sp-button-btn-theme-arrow-black-group);

    display: inline-block;
    margin: -1px 0 0 5px;
    vertical-align: middle;
    content: '';
  }

  // hover
  &:hover {
    &::after {
      @include retina-sprite($sp-button-btn-theme-arrow-hover-group);
    }
  }

  // press
  &:active {
    &::after {
      @include retina-sprite($sp-button-btn-theme-arrow-press-group);
    }

    background-color: transparent;
  }
}

// clause cms contents
.clause--cms__cont {
  table {
    width: 100%;
    margin: 0.625rem 0;
    font-size: 0.875rem;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    border: 0;

    th {
      font-weight: bold;
      background-color: #f9f9f9;
    }

    th,
    td {
      padding: 0.1875rem 0.3125rem;
      text-align: center;
      word-break: keep-all;
      word-wrap: break-word;
      border: 1px solid #cdcdcd;
    }
  }
}
