.player_ct {
  // 미니 버튼
  &.mini {
    // 반복 재생
    .btn-player-repeat {
      @include retina-sprite($sp-button-btn-miniplayer-repeat-n-nor-group);
    }

    // 반복 재생 활성화
    .btn-player-repeat-active {
      @include retina-sprite($sp-button-btn-miniplayer-repeat-p-nor-group);
    }

    // 한 곡만 반복 재생
    .btn-player-repeat1 {
      @include retina-sprite($sp-button-btn-miniplayer-repeat-1-nor-group);
    }

    // 플레이 리스트 반복 재생
    .btn-playlist-repeat {
      @include retina-sprite($sp-button-btn-miniplayer-playlist-repeat-nor-group);
    }

    // 이전 곡 재생
    .btn-player-previous {
      @include retina-sprite($sp-button-btn-miniplayer-previous-nor-group);
    }

    // 이전 곡 없을 경우
    .btn-player-previous-dim {
      @include retina-sprite($sp-button-btn-miniplayer-previous-press-group);
    }

    // 현재 곡 재생
    .btn-player-play {
      @include retina-sprite($sp-button-btn-miniplayer-play-nor-group);
    }

    // 재생 중인 곡 정지
    .btn-player-pause {
      @include retina-sprite($sp-button-btn-miniplayer-pause-nor-group);
    }

    // 다음 곡 재생
    .btn-player-next {
      @include retina-sprite($sp-button-btn-miniplayer-next-nor-group);
    }

    // 다음 곡 없을 경우
    .btn-player-next-dim {
      @include retina-sprite($sp-button-btn-miniplayer-next-press-group);
    }

    // 셔플 재생
    .btn-player-shuffle {
      @include retina-sprite($sp-button-btn-miniplayer-random-n-nor-group);
    }

    // 셔플 재생 활성화
    .btn-player-shuffle-active {
      @include retina-sprite($sp-button-btn-miniplayer-random-p-nor-group);
    }

    .btn_volume {
      @include retina-sprite($sp-button-btn-miniplayer-sound-nor-group);

      &.active {
        @include retina-sprite($sp-button-btn-miniplayer-sound-sel-group);
      }

      &.mute {
        @include retina-sprite($sp-button-btn-miniplayer-mute-sel-group);
      }
    }

    .util_area {
      .btn {
        &_like {
          @include retina-sprite($sp-button-btn-miniplayer-like-nor-group);

          &.active {
            @include retina-sprite($sp-button-btn-miniplayer-like-sel-group);
          }

          &.disabled {
            @include retina-sprite($sp-button-btn-miniplayer-like-press-group);
          }
        }

        &_open_list {
          @include retina-sprite($sp-button-btn-miniplayer-playlist-nor-group);

          &.active {
            @include retina-sprite($sp-button-btn-miniplayer-playlist-sel-group);
          }
        }

        // 미니플레이에서 이곡 안듣기 버튼 제거
        &_dislike {
          display: none;
        }
      }
    }
  } // mini end

  // 전체 사이즈 버튼
  &.full {
    .btn_lyrics_close {
      i {
        @include retina-sprite($sp-button-btn-playlist-close-nor-group);
      }
    }

    // 위치 바로 가기 버튼
    .btn_lyrics_focus {
      @include retina-sprite($sp-icon-icon-gplayer-focus-nor-group);

      &:active,
      &.active {
        @include retina-sprite($sp-icon-icon-gplayer-sel-group);
      }
    }

    // 재생 컨트롤러
    .control_area {
      padding-top: 30px;

      button {
        margin: 0 14px;
      }

      // 반복 재생
      .btn-player-repeat {
        @include retina-sprite($sp-button-btn-player-repeat-n-nor-group);
      }

      // 반복 재생 활성화
      .btn-player-repeat-active {
        @include retina-sprite($sp-button-btn-player-repeat-p-nor-group);
      }

      // 한 곡만 반복 재생
      .btn-player-repeat1 {
        @include retina-sprite($sp-button-btn-player-repeat-1-nor-group);
      }

      // 플레이 리스트 반복 재생
      .btn-playlist-repeat {
        @include retina-sprite($sp-button-btn-player-playlist-repeat-nor-group);
      }

      // 이전 곡 재생
      .btn-player-previous {
        @include retina-sprite($sp-button-btn-player-previous-nor-group);
      }

      // 이전 곡 없을 경우
      .btn-player-previous-dim {
        @include retina-sprite($sp-button-btn-player-previous-press-group);
      }

      // 현재 곡 재생
      .btn-player-play {
        @include retina-sprite($sp-button-btn-player-play-nor-group);
      }

      // 재생 중인 곡 정지
      .btn-player-pause {
        @include retina-sprite($sp-button-btn-player-pause-nor-group);
      }

      // 다음 곡 재생
      .btn-player-next {
        @include retina-sprite($sp-button-btn-player-next-nor-group);
      }

      // 다음 곡 없을 경우
      .btn-player-next-dim {
        @include retina-sprite($sp-button-btn-player-next-press-group);
      }

      // 셔플 재생
      .btn-player-shuffle {
        @include retina-sprite($sp-button-btn-player-random-n-nor-group);
      }

      // 셔플 재생 활성화
      .btn-player-shuffle-active {
        @include retina-sprite($sp-button-btn-player-random-p-nor-group);
      }
    }

    // 유틸
    .util_area {
      .btn {
        &_like {
          @include retina-sprite($sp-button-btn-player-like-nor-group);

          &.active,
          &:active {
            @include retina-sprite($sp-button-btn-player-like-sel-group);
          }

          &.disabled {
            @include retina-sprite($sp-button-btn-player-like-press-group);
          }
        }

        &_add {
          @include retina-sprite($sp-button-btn-player-add-nor-group);
        }

        &_share {
          @include retina-sprite($sp-button-btn-player-share-nor-group);
        }

        &_dislike {
          @include retina-sprite($sp-button-btn-player-dislike-nor-group);

          &:hover {
            opacity: 0.5;
          }

          &.active,
          &:active {
            @include retina-sprite($sp-button-btn-player-dislike-select-group);
          }
        }
      }
    }

    .volume_area {
      .btn_volume {
        @include retina-sprite($sp-button-btn-player-sound-sel-group);

        &.mute {
          @include retina-sprite($sp-button-btn-player-mute-sel-group);
        }
      }
    }

    // 퀄리티 - pc의 경우
    // .quality {
    // 	&.aac_128 {
    // 		@include retina-sprite($sp-icon-icon-player-quality-aac-128-group);
    // 	}
    // 	&.aac_192 {
    // 		@include retina-sprite($sp-icon-icon-player-quality-aac-192-group);
    // 	}
    // 	&.mp3_320 {
    // 		@include retina-sprite($sp-icon-icon-player-quality-mp3-320-group);
    // 	}
    // }
    .btn_player_close {
      @include retina-sprite($sp-button-btn-player-close-arrow-nor-group);
    }
  }
}
