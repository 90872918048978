
          @import '@/assets/scss/common/color.scss';
        

.simplebar {
  width: 100%;
  height: 100%;
}

.simplebar-scrollbar::before {
  width: 4px;
  background-color: #464646;
}

.simplebar-content-wrapper {
  height: 100% !important;
  outline: none;
}
