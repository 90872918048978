
          @import '@/assets/scss/common/color.scss';
        

.control {
  display: flex;
  gap: 12px;
  justify-content: center;
  width: 260px;

  &__seek {
    position: relative;
    width: 40px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    text-indent: initial;

    &:hover {
      .control__seek--time {
        transform: scale(0.91);
      }
    }

    &--time {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &__toggle-play {
    position: relative;
    width: 44px;
    height: 44px;
  }

  button {
    color: white;

    &:hover {
      transform: scale(1.1);
    }
  }

  .inactive {
    color: #333;

    &:hover {
      transform: initial;
    }
  }
}
