#pc_player {
  position: relative;
  width: 100%;
  height: 100%;
}

#electronWindows10TitleBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2500;
  width: 100%;
  height: 40px;
  font-size: 0;
  background: #2a2a2d;
  -webkit-app-region: drag;

  [class^='btn_win10_'] {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 100% auto;
    -webkit-app-region: no-drag;
  }

  .menu_title_w,
  .win_btn_w {
    box-sizing: border-box;
    height: 40px;
    vertical-align: middle;
  }

  .menu_title_w {
    float: left;
    padding: 9px 0 0 14px;

    .btn_win10_menu {
      width: 14px;
      height: 11px;
      background-image: url('~@/assets/img/electron/btn_win10_menu.png');
    }

    h1 {
      position: relative;
      top: 5px;
      display: inline-block;
      margin-left: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
    }
  }

  .win_btn_w {
    float: right;
    padding: 12px 14px 0 0;

    [class^='btn_win10_'] {
      width: 18px;
      height: 16px;
      margin-left: 19px;
    }

    .btn_win10_hide {
      background-image: url('~@/assets/img/electron/btn_win10_hide.png');
    }

    .btn_win10_full {
      background-image: url('~@/assets/img/electron/btn_win10_full.png');
    }

    .btn_win10_full.status-maximized {
      background-image: url('~@/assets/img/electron/btn_win10_mini.png');
    }

    .btn_win10_quit {
      background-image: url('~@/assets/img/electron/btn_win10_quit.png');
    }
  }
}

#player_header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 150px;
  height: 100%;
  background-color: #fafafa;

  .player_header_inner {
    padding: 30px;
  }

  .bi_wrap {
    position: relative;
    margin-top: 50px;
    -webkit-app-region: no-drag;

    .flo_bi {
      -webkit-app-region: no-drag;

      a {
        display: block;
        height: 100%;
        -webkit-app-region: no-drag;
      }
    }

    .ico_beta {
      position: absolute;
      top: 4px;
      right: -2px;
      width: 33px;
    }
  }

  .nav_gnb {
    margin-top: 10px;

    .gnb {
      > li {
        padding: 5px 0;

        > a {
          display: block;
          padding: 5px 0;
          font-size: 16px;
          color: #000;

          &.on,
          &:hover,
          &:active {
            color: $key_color;
          }

          &:not(:only-child) {
            &::after {
              position: relative;
              top: -1px;
              display: inline-block;
              margin-left: 4px;
              vertical-align: middle;
              content: '';
              @include retina-sprite($sp-button-btn-app-gnb-open-group);
            }

            &.active {
              &::after {
                @include retina-sprite($sp-button-btn-app-gnb-close-group);
              }
            }
          }
        }
      }
    }

    .gnb_sub {
      display: none;
      overflow: hidden;

      > li {
        &:first-child {
          padding-top: 5px;
        }

        a {
          display: block;
          padding: 4px 0;
          font-size: 13px;
          color: #000;

          &.on,
          &:hover,
          &:active {
            color: $key_color;
          }
        }
      }

      &.open {
        display: block;
      }
    }
  }
}

#player_search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 56px;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  -webkit-app-region: drag;

  @at-root .windows10 {
    #player_search {
      -webkit-app-region: no-drag;
    }
  }

  .page_nav {
    display: inline-block;
    margin: 11px 0 0 170px;
    vertical-align: middle;
    -webkit-app-region: no-drag;

    button {
      @include hide_text;

      &.btn_page_prev {
        margin-right: 12px;
        @include retina-sprite($sp-button-btn-app-page-prev-nor-group);

        &.disabled {
          @include retina-sprite($sp-button-btn-app-page-prev-dim-group);
        }
      }

      &.btn_page_next {
        @include retina-sprite($sp-button-btn-app-page-next-nor-group);

        &.disabled {
          @include retina-sprite($sp-button-btn-app-page-next-dim-group);
        }
      }
    }
  }

  .flo_search {
    display: inline-block;
    margin: 11px 0 0 20px;
    background-color: #fafafa;
    border: 1px solid #f4f4f4;
    -webkit-app-region: no-drag;

    .iptxt {
      font-size: 14px;
      background-color: #fafafa;

      &::placeholder {
        color: #999;
      }
    }
  }

  .snb_w {
    float: right;
    margin: 19px 20px 0 0;
    -webkit-app-region: no-drag;

    ul {
      font-size: 0;

      li {
        display: inline-block;
        margin-left: 25px;
        font-size: 14px;
        white-space: nowrap;
        vertical-align: top;

        &:first-child {
          margin-left: 0;
        }

        &.snb_w_li_intro {
          position: relative;
          top: 2px;
        }

        a {
          color: #8c8c8c;
        }
      }
    }

    .user_character {
      position: relative;
      top: -9px;
      font-size: 14px;
      color: #000;

      .character_thumbnail {
        @include thumb_round(36px);

        top: 0;
        margin-left: 11px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    @include mediaquery-1024 {
      margin-right: 30px;
    }

    .user_character_nav {
      position: relative;
    }

    .my_character_info {
      position: absolute;

      // top: 41px;
      top: 0;
      right: 0;
      width: 320px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

      &::before {
        position: absolute;
        top: -9px;
        right: 14px;
        width: 0;
        height: 0;
        content: '';
        border-right: 7px solid transparent;
        border-bottom: 9px solid #fff;
        border-left: 7px solid transparent;
      }

      ul {
        padding-top: 15px;

        li {
          position: relative;
          display: block;
          padding: 13px 25px;
          margin-left: 0;

          &:hover,
          &:active {
            background-color: #f5f5f5;
          }
        }

        .btn_setting {
          position: absolute;
          top: 25px;
          right: 25px;
          font-size: 14px;
          color: $font_blue;
        }
      }

      .info_artist_flo {
        padding: 8px 25px 12px;
        font-size: 13px;
        line-height: 1.2;
        color: #999;
      }
    }
  }
}

#player_wrap {
  // position: fixed;
  position: absolute;
  top: 57px;
  right: 0;
  bottom: 80px;
  left: 150px;
  overflow: auto;

  #section {
    padding-top: 0;

    .section_inner {
      padding-top: 30px;

      .sort_type_wrap_coupon {
        top: 35px;
      }
    }

    &.section_home {
      .section_inner {
        padding-top: 4px;
      }
    }

    &.section_browse {
      .section_inner {
        padding-bottom: 50px;
      }
    }
  }
}

.electron_app {
  .playlist_ct,
  .player_ct,
  .playbar_ct {
    -webkit-app-region: no-drag;
  }

  .player_ct.full {
    -webkit-app-region: drag;
  }
}

.windows10 {
  #player_header .player_header_inner {
    padding-top: 70px;
  }

  #player_search {
    top: 40px;
  }

  #player_wrap {
    top: 97px;
  }

  .player_ct.full {
    top: 40px;
    -webkit-app-region: no-drag;
  }
}

.payment,
.tid,
.terdef,
.moving {
  .electron_app {
    #player_wrap {
      top: 0;
      bottom: 0;
      left: 0;

      .player_wrap_inner {
        min-width: auto;
      }
    }
  }
}
