header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: $header_height;
  background-color: #fff;

  .header_inner {
    position: relative;
    min-width: $min_width;
    max-width: $max_width;
    margin: 0 auto;

    .nav_gnb {
      min-width: 200px;

      ul {
        font-size: 0;

        li {
          display: inline-block;
          margin-left: 28px;
          font-size: 16px;
          white-space: nowrap;

          &:first-child {
            margin-left: 0;
          }

          a {
            color: #000;

            &.on {
              color: $font_blue;
            }
          }
        }
      }
    }

    .snb_w {
      ul {
        font-size: 0;

        li {
          display: inline-block;
          margin-left: 25px;
          font-size: 13px;
          white-space: nowrap;
          vertical-align: top;

          &:first-child {
            margin-left: 0;
          }

          a {
            color: #8c8c8c;
          }
        }
      }

      .user_character {
        position: relative;
        padding-top: 3px;
        color: #000;

        .character_thumbnail {
          @include thumb_round(40px);

          margin-left: 11px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          outline: 0.5px solid rgba(0, 0, 0, 0.1);
        }
      }

      @include mediaquery-1024 {
        margin-right: 30px;
      }

      .user_character_nav {
        position: relative;
      }

      .my_character_info {
        position: absolute;

        // top: 41px;
        top: 12px;
        right: 0;
        width: 320px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

        &::before {
          position: absolute;
          top: -9px;
          right: 14px;
          width: 0;
          height: 0;
          content: '';
          border-right: 7px solid transparent;
          border-bottom: 9px solid #fff;
          border-left: 7px solid transparent;
        }

        ul {
          padding-top: 15px;

          li {
            position: relative;
            display: block;
            padding: 13px 25px;
            margin-left: 0;

            &:hover,
            &:active {
              background-color: #f5f5f5;
            }
          }

          .btn_setting {
            position: absolute;
            top: 20px;
            right: 25px;
            font-size: 14px;
            color: $font_blue;
          }
        }

        .info_artist_flo {
          padding: 8px 25px 12px;
          font-size: 13px;
          line-height: 1.2;
          color: #999;
        }
      }
    }
  }
}
