$player-edit-button-width: 74px;
$snack-bar-padding: 30px; // 닫기 버튼 크기, 좌우 여백

.popup_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3001;
  background: rgba(0, 0, 0, 0.6);

  // 프로모션 팝업 2개이상 일때 첫번째만 노출시키는 클래스
  &.double_popup_wrap {
    & ~ & {
      display: none;
    }
  }

  // 회원탈퇴 팝업
  &.popup_dismiss {
    .popup_w_big {
      width: 475px;
    }
    @media screen and (max-height: 850px) {
      .popup_w_big {
        max-height: 77.332vh;

        .popup_content {
          max-height: calc(77.332vh - 112px);
        }
      }
    }
  }
}

%popup_w_style {
  @include layerStyle(340px);

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2000;
  height: auto;
  padding: 30px;
  transform: translate(-50%, -50%);

  .popup_content {
    @include wordbreak;

    .popup_text {
      font-size: 15px;
      line-height: 1.47;
      color: #484848;
      text-align: center;

      .top_line_area {
        text-align: left;

        .inp_check_square + label::before {
          margin-right: 8px;
        }
      }

      &.align-left {
        text-align: left;
      }

      .emcolor-blue {
        color: #3f3fff;
      }

      .emcolor-red {
        color: #ff4d78;
      }

      &.font-size-13 {
        font-size: 0.8125rem;
      }

      &.emcolor-666 {
        color: #666;
      }
    }

    .popup_title {
      font-size: 18px;
      font-weight: normal;
      color: #181818;
      text-align: center;
    }

    .popup_desc {
      font-size: 15px;
      line-height: 1.47;
      color: #484848;

      dt {
        float: left;
        width: 50px;
      }
    }

    .popup_sub_text {
      padding-top: 5px;
      font-size: 13px;
      color: #989898;
    }

    .top_line_area {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid #f3f3f3;

      p + p {
        padding-top: 15px;
      }

      &.disabled {
        opacity: 0.3;
      }
    }

    .inp_radio {
      + label {
        span {
          font-size: 14px;
        }
      }
    }
  }

  .popup_button_w {
    margin: 40px 0 0;
    text-align: center;

    button {
      margin: 0 5px;
    }
  }
}

.popup_w {
  @extend %popup_w_style;
}

.popup_w_big {
  @extend %popup_w_style;

  width: 470px;

  h3 {
    font-size: 18px;
  }

  .inp_character_name {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .check_byte {
    @include hide_text;

    z-index: -1;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
  }

  h3 + .popup_content {
    margin-top: 15px;
    overflow-x: hidden; // 내 리스트 추가하기 팝업 가로 스크롤 hidden
  }

  .popup__dismiss--content {
    max-height: calc(77.332vh - 112px);
  }

  .popup_content {
    overflow-y: auto;

    .popup_put_mylist_ul {
      width: 99%;
    }

    li {
      border-bottom: 1px solid #eee;
    }

    .popup_sub_list {
      // 회원탈퇴 레이어 border 없음
      li {
        border-bottom: 0;
      }
    }

    .pop_user_character_plus {
      position: relative;
      display: block;
      width: 100%;
      height: 75px;
      padding: 14px 0;
      text-align: left;

      .character_pop_thumbnail {
        position: absolute;
        top: 8px;
        left: 0;
        width: 16%;

        img {
          @include thumb_round(60px);

          border-radius: 5px;
        }

        &.newlist_plus {
          width: 60px;
          min-width: 60px;
          height: 60px;
          text-align: center;
          vertical-align: middle;
          background-color: #f3f3f3;
          border-radius: 5px;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: inline-block;
            margin: auto;
            content: '';
            @include retina-sprite($sp-button-btn-character-add-group);
            @include hide_text;
          }
        }
      }

      .character_info {
        padding-left: 75px;
        vertical-align: middle;

        .character_w {
          p {
            display: block;
            padding-top: 7px;
            overflow: hidden;
            font-size: 13px;
            color: #969696;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.playlist_title {
              font-size: 15px;
              color: #333;
            }

            &.playlist_add_text {
              font-size: 15px;
              color: #3f3fff;
            }
          }
        }
      }
    }

    .list_add_bnt_s {
      padding-top: 20px;
      text-align: right;

      button {
        margin-left: 20px;
        font-size: 15px;

        &.on {
          color: #3f3fff;
        }

        &:disabled {
          color: $text_disabled;
        }
      }
    }

    .popup_main_text {
      padding-top: 30px;
      font-size: 15px;
    }

    .popup_sub_text {
      padding-top: 5px;
      font-size: 13px;
      color: #989898;
    }

    .popup_sm_text {
      padding-top: 15px;
      font-size: 13px;
    }

    @at-root .popup_sub_list {
      padding-top: 21px;
      margin-top: 18px;
      border-top: 1px solid #eee;

      li {
        position: relative;
        padding-top: 15px;
        padding-left: 15px;
        font-size: 13px;
        line-height: 1.46;
        color: #010101;
        border-bottom: 0;

        &::before {
          position: absolute;
          top: 20px;
          left: 0;
          content: '';
          @include retina-sprite($sp-icon-ic-my-dot-group);
          @include hide_text;
        }
      }

      .inp_radio {
        margin-top: 10px;
      }
    }

    .img_group {
      margin-top: 20px;
      overflow: hidden;

      img {
        float: left;
        width: 230px;

        &:last-child {
          float: right;
        }
      }
    }
  }
}

.popup_play_tooltip {
  @extend %popup_w_style;

  top: auto;
  bottom: 130px;
  width: auto;
  padding: 13px 20px;
  transform: translate(-50%, 0);

  &::after {
    position: absolute;
    bottom: -9px;
    left: 50%;
    width: 0;
    height: 0;
    content: '';
    border-top: 9px solid #fff;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    transform: translate(-50%, 0);
  }

  p {
    font-size: 13px;
  }
}

.popup_w_img {
  @extend %popup_w_style;

  width: 316px;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;

  .popup_content {
    overflow: hidden;

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  .popup_content_text {
    padding: 16px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
  }

  .popup_button_w {
    position: relative;
    box-sizing: border-box;
    padding: 17px 16px 16px;
    margin: -1px 0 0;
    text-align: left;
    background-color: #fff;

    .inp_check_simple {
      margin: 1px 40px 0 0;
    }

    .btn_close_popup_text {
      position: absolute;
      top: 50%;
      right: 16px;
      padding: 5px;
      margin: 0;
      font-size: 15px;
      line-height: 1;
      color: rgba(24, 24, 24, 0.8);
      transform: translateY(-50%);
    }
  }

  .popup_w_img_inner {
    overflow: hidden;
  }

  .btn_close_popup {
    top: 0;
    right: 0;
    width: 46px;
    height: 46px;
    background: none;

    .bar_close_popup {
      position: absolute;
      top: 50%;
      width: 1px;
      height: 19px;
      margin-top: -9px;
      background-color: #fff; // 기본 색상

      &.before {
        transform: rotate(45deg);
      }

      &.after {
        transform: rotate(-45deg);
      }
    }
  }
}

// 플레이어 스낵바
.snack_bar {
  position: fixed;
  right: 0;
  bottom: $miniplayer-height;
  left: 0;
  z-index: 400;
  height: 74px;
  background-color: rgba(24, 24, 24, 0.95);

  .txt_area {
    @include align_middle(50%, 50%, -50%, -50%);

    position: relative;
    width: $min_width - ($snack-bar-padding * 4) - (17px * 2); // 최소 해상도 넓이에서 닫기 버튼 크기와 좌우 여백만큼 제외한다
  }

  p {
    text-align: center;

    &.txt1 {
      font-size: 15px;
      line-height: 27px;
      color: #fff;
    }

    &.txt2 {
      font-size: 14px;
      line-height: 20px;
      color: #989898;

      a::after {
        content: none;
      }
    }
  }

  a {
    margin-left: 10px;
    color: $key_color;

    &::after {
      @include retina-sprite($sp-button-btn-playlist-arrow-primary-group);

      display: inline-block;
      vertical-align: -2px;
      content: '';
    }
  }

  button {
    @include retina-sprite($sp-button-btn-playlist-close-nor-group);
    @include hide_text;

    position: absolute;
    top: $snack-bar-padding;
    right: $snack-bar-padding;

    @include respond-to(mediaquery-large) {
      right: 45px;
    }
  }
}

// 트랙 선택 시 노출되는 팝업
.pop_item_select {
  position: fixed;
  bottom: 195px;
  left: 50%;
  z-index: 500;
  height: 99px;
  font-size: 0;
  background-color: #576aff;
  border-radius: 5px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 0);

  // 플레이어, 재생목록 안에 팝업이 위치할 경우
  @at-root {
    .music-playlist,
    .player_ct {
      .pop_item_select {
        bottom: 150px;
        width: auto;
        white-space: nowrap;

        @include respond-to(mediaquery-large) {
          position: absolute;
          bottom: 50px;
        }
      }
    }

    .player_ct {
      .pop_item_select {
        @include respond-to(mediaquery-large) {
          transform: translateX(50%);
        }
      }
    }

    .playlistInPlayer {
      .pop_item_select {
        @include respond-to(mediaquery-large) {
          transform: translateX(80%);
        }
      }
    }

    .single_view {
      .pop_item_select {
        position: absolute;
      }
    }
  }

  .selected {
    position: absolute;
    top: -23px;
    left: 14px;
    display: flex;
    align-items: center;
    min-width: 10px;
    height: 16px;
    padding: 10px 13px;
    font-size: 16px;
    font-weight: bold;
    color: #576aff;
    text-align: center;
    background-color: #fff;
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  button {
    width: $player-edit-button-width;
    height: 100%;
    font-size: 14px;
    color: #fff;

    &::before {
      display: block;
      margin: 0 auto 6px;
      content: '';
    }

    &:last-of-type {
      width: $player-edit-button-width + 16px;
      padding-right: 16px;
    }
  }

  &--dimmed {
    cursor: auto;
    opacity: 0.7;
  }

  .btn_unselected {
    position: relative;
    width: 96px;
    padding-left: 10px;
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.75);

    &::before {
      width: 34px;
      height: 34px;
      background-image: url('~@/assets/img/floating_cancel.png');
      background-size: cover;
    }

    &::after {
      position: absolute;
      top: 24px;
      right: 0;
      width: 1px;
      height: 51px;
      content: '';
      background-color: #fff;
      opacity: 0.1;
    }
  }

  .btn_select_listen {
    &::before {
      @include retina-sprite($sp-button-btn-floating-popup-play-nor-group);
    }
  }

  .btn_add_playlist {
    &::before {
      @include retina-sprite($sp-button-btn-floating-popup-playlist-nor-group);
    }
  }

  .btn_add_mylist {
    &::before {
      width: 34px;
      height: 34px;
      background-image: url('~@/assets/img/floating_mylist.png');
      background-size: cover;
    }
  }

  .btn_delete {
    &::before {
      width: 34px;
      height: 34px;
      background-image: url('~@/assets/img/floating_delete.png');
      background-size: cover;
    }
  }

  .btn_item_add {
    &::before {
      @include retina-sprite($sp-button-btn-floating-popup-add-nor-group);
    }
  }
}

// 드롭다운 레이어
.layer_dropdown {
  @include layerStyle(200px); // 기본 200px

  ul {
    width: 100%;
    padding: 10px 0;
  }

  li {
    text-align: left;

    button,
    & a {
      position: relative;
      display: block;
      padding-left: 17px;
      font-size: 14px;
      line-height: 40px;
      color: #353535;
      text-align: left;

      &:hover {
        color: $key_color;
        background-color: #f5f5f5;

        &::after {
          @include retina-sprite($sp-icon-ic-artist-add-check-group);

          position: absolute;
          top: 14px;
          right: 20px;
          display: block;
          content: '';
        }

        .ico_info_music {
          @include retina-sprite($sp-button-btn-more-music-press-group);
        }

        .ico_info_album {
          @include retina-sprite($sp-button-btn-more-album-press-group);
        }

        .ico_info_artist {
          @include retina-sprite($sp-button-btn-more-artist-press-group);
        }

        .ico_like {
          @include retina-sprite($sp-button-btn-more-like-press-group);

          &.active {
            @include retina-sprite($sp-button-btn-more-like-sel-group);
          }
        }

        .ico_share {
          @include retina-sprite($sp-button-btn-more-share-press-group);
        }

        .ico_dislike {
          opacity: 0.3;
        }
      }
    }

    button {
      width: 100%;
    }

    i {
      @include hide_text;

      display: inline-block;
      margin-right: 8px;
    }
  }

  // 아이콘
  .ico_info_music {
    @include retina-sprite($sp-button-btn-more-music-nor-group);
  }

  .ico_info_album {
    @include retina-sprite($sp-button-btn-more-album-nor-group);
  }

  .ico_info_artist {
    @include retina-sprite($sp-button-btn-more-artist-nor-group);
  }

  .ico_add_playlist {
    @include retina-sprite($sp-button-btn-thumb-playlist-nor-group);
  }

  .ico_like {
    @include retina-sprite($sp-button-btn-more-like-nor-group);

    &.active {
      @include retina-sprite($sp-button-btn-more-like-sel-group);
    }
  }

  .ico_share {
    @include retina-sprite($sp-button-btn-more-share-nor-group);
  }

  .ico_dislike {
    @include retina-sprite($sp-button-btn-more-dislike-nor-group);

    &.active {
      @include retina-sprite($sp-button-btn-more-dislike-sel-group);
    }
  }
}

.playlist-popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    flex-grow: 1;
    font-size: 20px;
  }

  h3 {
    font-size: 15px;
  }
}

// 플레이 리스트 세팅 팝업
.popup_w_setting {
  @extend %popup_w_style;
  @include layerStyle(470px);

  position: fixed;

  .setting_pop_w {
    height: 500px;
    margin-top: 20px;
    overflow-y: auto;

    @media only screen and (max-height: 660px) {
      height: 400px;
    }

    li {
      padding-top: 17px;
    }

    &__title {
      padding-top: 16px;
    }
  }

  h1 {
    font-size: 20px;
  }

  h3 {
    font-size: 15px;
  }

  .play-setting {
    &__item {
      display: flex;
      align-items: center;
      justify-content: left;
      padding-top: 6px;
      font-size: 14px;
      cursor: pointer;
    }

    &__setting {
      padding-bottom: 2px;
      padding-left: 3px;
      color: #989898;
    }
  }

  .sub_caption {
    padding-top: 5px;
    font-size: 12px;
    line-height: 1.5;
    color: #000;
    opacity: 0.4;

    span {
      display: block;
    }
  }

  .setting_guid {
    li {
      position: relative;
      padding-top: 0;
      padding-left: 15px;
      margin-top: 15px;
      font-size: 13px;

      &:first-child {
        padding-top: 0;
        margin-top: 20px;
      }

      &::before {
        position: absolute;
        top: 5px;
        left: 0;
        content: '';
        @include retina-sprite($sp-icon-ic-my-dot-group);
        @include hide_text;
      }

      span {
        display: block;

        em {
          color: #3f3fff;
        }
      }
    }
  }
}

.ppopup_w_19 {
  padding: 90px 0;
  text-align: center;

  .img_19 {
    @include retina-sprite($sp-icon-ic-popup-19-group);
    @include hide_text;

    margin: auto;
  }

  .main_text {
    padding-top: 46px;
    font-size: 18px;
  }

  .sub_text {
    width: 290px;
    margin: 15px auto 0;
    font-size: 13px;
    line-height: 22px;
    color: #666;
  }

  .popup_button_w {
    padding-top: 33px;
  }
}

.putInMyList_Wrapper {
  .popup_content {
    max-height: 540px;
  }
}

.snack_bar.snackbar_static {
  z-index: 300;
}

.snack_bar.snackbar_priority {
  z-index: 350;
}

.alertUIDAppDown {
  .popup_w {
    width: 385px;

    .popup_content {
      margin-top: 24px;

      .popup_text {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
}

.btn_download {
  width: 116px;

  &::before {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 3px;
    vertical-align: middle;
    content: '';
    background-image: url('~@/assets/img/icon-download-wh@3x.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}

.modal_player_download_w {
  @extend %popup_w_style;

  box-sizing: border-box;
  width: 442px;
  padding: 30px;
  border-radius: 7px;

  .lb {
    display: block;
  }

  h1 {
    font-size: 18px;

    .ico_beta {
      position: relative;
      top: -1px;
      width: 33px;
      vertical-align: middle;
    }
  }

  .popup_sub_list {
    padding: 0;
    margin: 7px 0 0;
    border-top: 0;

    li {
      font-size: 15px;

      &::before {
        top: 22px;
      }

      &.use_chrome {
        color: #3f3fff;
      }
    }
  }

  .popup_button_w {
    margin-top: 30px;

    .btn_download {
      width: 180px;

      &::before {
        margin-right: 8px;
      }
    }
  }
}

.tid-signout-alert-w {
  h3 {
    margin-bottom: 20px;
  }

  .form-check {
    display: block;
    margin-top: 15px;

    span {
      padding-left: 30px;
      font-size: 15px;
    }
  }
}

// 플로팅 팝업
.floating_bar {
  position: fixed;
  right: 0;
  bottom: 80px;
  left: 0;

  // 미니플레이어 재생목록 보다는 낮게
  z-index: 190;
  height: 74px;

  // 타이틀 숨기기
  .floating_title_blind {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  // 어드민을 통해 bg컬러를 제공해주는데 투명도가 들어가있어 bg를 별도로 뺌
  .floating_bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    // 기본값 지정
    background-color: #3f3fff;
    opacity: 0.96;
  }

  .floating_inner {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    max-width: 1600px;
    padding: 0 45px;
    margin: 0 auto;
  }

  .floating_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 74px;
    font-size: 15px;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;

    img {
      width: auto;
      height: 74px;
    }
  }

  .btn_close {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 10;
    display: block;
    width: 74px;
    height: 74px;
    padding: 17px 28px 39px;

    .icon_close {
      display: block;

      @include retina-sprite($sp-button-btn-playlist-close-nor-group);
    }

    .text_hide {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
    }
  }
}
