
          @import '@/assets/scss/common/color.scss';
        

.player {
  .v-enter-active,
  .v-leave-active {
    transition: transform 0.25s ease-in;
    will-change: transform;
  }

  .v-enter,
  .v-leave-to {
    transform: translateY(100%);
  }
}
