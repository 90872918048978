
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/sprite/sp_all';

.player_ct.mini {
  .playbar_wrap {
    &::after {
      position: absolute;
      width: 100%;
      height: 0;
      content: '';
      background-color: rgba(15, 14, 14, 0.9);
      opacity: 0;
    }

    &.seeking {
      &::after {
        height: 100%;
        opacity: 1;
        transition: opacity 0.1s 0.45s ease-in;
      }

      &.inactive {
        &::after {
          width: 100%;
          height: 0;
        }
      }
    }
  }

  .player {
    &__side {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      width: 30%;

      &--right {
        display: flex;
        gap: 20px;
        align-items: center;
      }
    }

    &__seek {
      height: 100%;
      background: rgba(240, 240, 240, 0.2);
      transition: transform 0.15s 0.3s ease-in;
      transform: scale(1, 0.375);
      transform-origin: top;

      &--action {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 20px;
        cursor: pointer;
      }

      &-wrap {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
        width: 100%;
        height: 20px;
        padding-bottom: 4px;

        &.hover {
          .player__seek {
            transform: scale(1);

            &--time {
              opacity: 1;
              transition: opacity 0.1s 0.45s ease-in;
            }

            &--current-time {
              opacity: 1;
              transition: opacity 0.1s 0.45s ease-in;
              will-change: transform;
            }
          }
        }

        &.inactive {
          &.hover {
            .player__seek {
              transform: scale(1, 0.375);

              &--time {
                opacity: 0;
              }

              &--current-time {
                opacity: 0;
              }
            }
          }

          .player__seek--action {
            cursor: initial;
          }
        }
      }

      &--inner {
        position: relative;
        width: 0;
        height: 100%;
        background-color: #576aff;
      }

      &--current-time {
        position: absolute;
        top: 24px;
        left: 4px;
        z-index: 2;
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: #576aff;
        text-align: right;
        white-space: nowrap;
        opacity: 0;
      }

      &--duration {
        color: #666;
      }

      &--time {
        position: absolute;
        top: 24px;
        z-index: 2;
        font-size: 14px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        text-align: right;
        opacity: 0;
      }
    }

    &__like {
      color: rgba(255, 255, 255, 0.4);

      &:hover {
        color: rgba(255, 255, 255, 1);
        transform: scale(1.1);
      }
    }
  }
}
