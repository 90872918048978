
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.playlist {
  @include respond-to(mediaquery-large) {
    height: 100%;
    margin-right: 60px;
  }

  box-sizing: border-box;
  width: 610px;
  height: auto;
  padding: 15px 5px;

  &__tab {
    position: relative;
  }

  &__edit {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 15px;
    color: #989898;

    &.active {
      color: #fff;
    }

    &.disabled {
      color: #484848;
    }
  }

  &__arrow {
    position: fixed;
    right: 80px;
    bottom: 100px;
  }
}

.playlist-tab {
  margin-right: 10px;
  border-bottom: 1px solid rgba(240, 240, 240, 0.2);

  &__list {
    display: flex;
  }

  &__item {
    padding-bottom: 10px;
    margin-right: 20px;
    font-size: 17px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.3);
    border: none;

    &.active {
      color: #fff;
      border-bottom: 3px solid #7286ff;
    }
  }
}
