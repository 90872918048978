
          @import '@/assets/scss/common/color.scss';
        

.header {
  &_inner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 96px;
    padding: 10px 80px 0;
  }

  &--left {
    display: flex;
    align-items: center;
    min-width: 534px;
  }

  &--right {
    display: flex;
    align-items: center;
    min-width: 240px;
    margin-left: 24px;
  }

  &__menu {
    margin: 0 40px;
  }

  &__link {
    margin-left: 25px;
    font-size: 13px;

    &:first-child {
      margin-left: 0;
    }

    a {
      color: #8c8c8c;
    }
  }
}
