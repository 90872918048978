
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.view-more-item {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    font-size: 14px;
    text-align: left;

    @include ellipsis1;

    &:hover {
      color: #3f3fff;
      background-color: #f5f5f5;

      + .view-more-item__icon--right {
        display: block;
      }
    }
  }

  &__icon {
    &--left {
      margin-right: 8px;
    }

    &--right {
      position: absolute;
      right: 16px;
      bottom: 12px;
      display: none;
    }
  }
}
