.section_help {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $font-color-placeholder;
    opacity: 1; /* Firefox */
  }

  h2 {
    font-size: 30px;
    text-align: center;
  }

  h3 {
    font-size: 20px;
  }

  .newlist_tab + h3 {
    padding-top: 40px;
  }

  .newlist_tab {
    padding-right: 0;
    text-align: center;
  }

  .search_faq_section {
    padding-top: 40px;
    text-align: center;
  }

  .help_faq_tab {
    padding-top: 40px;

    ul {
      display: inline-block;
      vertical-align: top;
    }

    li {
      position: relative;
      display: inline-block;
      padding: 0 10px 0 18px;

      &::after {
        position: absolute;
        top: 9px;
        left: 0;
        display: block;
        width: 1px;
        height: 13px;
        content: '';
        background-color: #dcdcdc;
      }

      &:first-of-type {
        padding: 0 10px 0 0;

        &::after {
          width: 0;
        }
      }

      .btn_tab_faq_normal {
        font-size: 15px;
        line-height: 32px;
        vertical-align: top;
      }

      &.on {
        .btn_tab_faq_normal {
          font-weight: bold;
          color: $key_color;
        }
      }
    }
  }

  .help_faq_result {
    padding-top: 10px;
    font-size: 13px;
    color: #999;

    span {
      padding-right: 5px;
      color: $key_color;
    }
  }

  .help_faq_tab + .help_list {
    margin-top: 20px;
  }

  .help_faq_result + .help_list {
    margin-top: 15px;
  }

  .comp_select + .help_list {
    margin-top: 15px;
  }

  .section_content_head + .help_list {
    margin-top: 15px;
  }

  h3 + .sort_type_wrap {
    padding-top: 7px;
  }

  .help_list {
    margin-top: 40px;

    &.question_type {
      tr {
        td {
          &.info_help {
            padding-left: 0;
          }
        }

        &.on {
          td {
            box-sizing: border-box;
            padding-top: 32px;
            line-height: 22px;
            vertical-align: top;

            &.info_help {
              padding-bottom: 32px;
            }

            &.help_detail {
              padding: 0 310px 30px 47px;
              color: $key_color;

              p,
              a {
                padding-bottom: 10px;
                font-size: 13px;
                color: $key_color;

                b {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }

      .info {
        padding-left: 0;
      }
    }
  }

  // 셀렉트 박스
  .comp_select {
    width: 241px;
    height: 50px;
    margin-top: 15px;
    border: 1px solid $border-bottom-color-normal;
    border-radius: 5px;

    select {
      width: 100%;
      padding-left: 20px;
      color: #333;
    }

    &::before {
      @include retina-sprite($sp-button-btn-sorting-open-nor-b-group);

      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      content: '';
    }
  }

  .help-question-textarea {
    position: relative;
    padding-top: 10px;

    .help-question-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 206px;
      resize: none;
      background-color: transparent;
      border: 1px solid $border-bottom-color-normal;
      border-radius: 5px;

      &:focus-within {
        outline: 2px solid #007bff;
      }
    }

    textarea {
      position: inherit;
      z-index: 1;
      width: 100%;
      height: 166px;
      padding: 20px;
      font-size: 16px;
      line-height: 24px;
      resize: none;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }

    span {
      margin: 20px 20px 20px auto;
      color: #c4c4c4;
      background-color: #fff;
    }

    &__placeholder {
      position: absolute;
      top: 34px;
      left: 24px;
      z-index: 0;
      display: block;
      line-height: 24px;
      color: #c4c4c4;
    }
  }

  .inquiry-file-attachment {
    margin: 24px 0 40px;

    &__header {
      margin: 40px 0 16px;
    }

    &__title {
      font-weight: 600;
    }

    &__counter {
      font-weight: 400;
      color: $color-text_3;
    }

    &__description {
      padding-top: 8px;
      font-size: 14px;
      color: #999;
    }

    &__contents {
      --content-width: calc((100% - 100px) / 6);

      display: inline-grid;
      grid-template-columns: repeat(6, var(--content-width));
      grid-gap: 20px;
      width: 100%;
      height: var(--content-width);

      .file-uploader {
        margin-bottom: 4px;

        &__btn-upload {
          display: flex;
          align-items: center;
          justify-content: center;

          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
          }
        }

        label {
          width: calc(100% - 2px);
          aspect-ratio: 1;
          cursor: pointer;
          border: 1px solid #ebebeb;
          border-radius: 4px;

          input[type='file'] {
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 1px;
            opacity: 0;
          }
        }
      }

      .attached-file {
        position: relative;
        display: contents;

        &__thumb {
          position: relative;
          width: 100%;
          aspect-ratio: 1;
          color: #fff;

          img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        &__backdrop {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          aspect-ratio: 1;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.2) 100%);
          border-radius: 4px;
        }

        &__file-data {
          position: absolute;
          bottom: 12px;
          left: 12px;
          width: calc(100% - 24px);

          .file-display-text {
            display: flex;
            max-width: 100%;
            font-size: 14px;
            font-weight: 500;

            &__file-name {
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: keep-all;
              white-space: nowrap;
            }
          }

          .file-size {
            margin-top: 4px;
            font-size: 12px;
            color: #ccc;
          }
        }

        &__btn-delete {
          position: absolute;
          top: 4px;
          right: 4px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .help-question-info-container {
    padding: 15px 20px;
    background-color: #f9f9f9;
    border-radius: 4px;

    .help-question-info {
      p {
        position: relative;
        padding-top: 8px;
        padding-left: 15px;
        font-size: 14px;
        line-height: 20px;

        &::before {
          @include retina-sprite($sp-icon-ic-my-dot-group);

          position: absolute;
          top: 16px;
          left: 0;
          content: '';
        }
      }

      span {
        font-weight: bold;
      }
    }

    .help-question-sub-info {
      margin-top: 8px;
      margin-left: 12px;
      font-size: 13px;
      line-height: 23px;
    }
  }

  .question_button_w {
    text-align: center;

    .btn_bg_blue_s {
      min-width: 248px;
      height: 62px;
      padding: 0 92px;
      margin: 40px auto;
      font-size: 18px;
    }
  }

  .paging_w {
    padding-top: 28px;
    text-align: center;

    button {
      width: 32px;
      height: 32px;
      line-height: 32px;

      i {
        display: block;
        margin: auto;

        &.btn_arrow_l {
          @include retina-sprite($sp-button-btn-arrow-back-nor-group);
          @include hide_text;

          &.press {
            @include retina-sprite($sp-button-btn-arrow-back-press-group);
            @include hide_text;
          }
        }

        &.btn_arrow_r {
          @include retina-sprite($sp-button-btn-arrow-forward-nor-group);
          @include hide_text;

          &.press {
            @include retina-sprite($sp-button-btn-arrow-forward-press-group);
            @include hide_text;
          }
        }
      }
    }

    a {
      display: inline-block;
      width: 32px;
      height: 32px;
      font-size: 14px;
      line-height: 34px;
      text-align: center;
      vertical-align: top;
      border: 0;
      border-radius: 16px;

      .btn_num {
        font-size: 14px;
        color: #181818;
      }

      &:active,
      &:hover,
      &.active {
        color: #fff;
        background-color: $key_color;
      }
    }
  }
}

.help-chat {
  $help-chat-bottom: 20px;
  $help-chat-right: 20px;

  position: fixed;
  right: $help-chat-right;
  bottom: $miniplayer-height + $help-chat-bottom;
  z-index: 5;

  @at-root .help-chat__link {
    display: block;
    @include retina-sprite($sp-button-btn-cs-chat-pc-group);
  }
}
