
          @import '@/assets/scss/common/color.scss';
        

.view-more {
  position: absolute;
  z-index: 11;
  width: 200px;
  padding: 10px 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}
