////////////////////////////
/// Token
////////////////////////////

// Blue
$blue_50: #f3f4fe;
$blue_400: #9ab2ff;
$blue_500: #768eff;
$blue_600: #5868ff;
$blue_700: #4356fe;
$blue_800: #3f3fff;
$blue_900: #3634a3;
$blue_gy: #49548e;
$blue_dl: #5867b7;
$blue_sf: #7b87c8;

// Sky Blue
$sky_blue_300: #abdcff;
$sky_blue_400: #86c7f8;
$sky_blue_500: #598afa;
$sky_blue_600: #2b9df4;
$sky_blue_700: #028af4;
$sky_blue_800: #0067d7;
$sky_blue_900: #194a98;
$sky_blue_gy: #35517d;
$sky_blue_dl: #4477b3;
$sky_blue_sf: #6b97c9;

// Light Blue
$light_blue_300: #9ee9f8;
$light_blue_400: #68def5;
$light_blue_500: #16cdf1;
$light_blue_600: #1cbae0;
$light_blue_700: #02a1cc;
$light_blue_800: #00789e;
$light_blue_900: #084f6b;
$light_blue_gy: #375e6e;
$light_blue_dl: #3f7990;
$light_blue_sf: #699baf;

// Teal
$teal_300: #a6ecdd;
$teal_400: #72e3d0;
$teal_500: #33ddc7;
$teal_600: #06cab5;
$teal_700: #00ab97;
$teal_800: #008372;
$teal_900: #145e4e;
$teal_gy: #39605b;
$teal_dl: #437e76;
$teal_sf: #619c94;

// Green
$green_300: #bcedb7;
$green_400: #8af088;
$green_500: #5fe864;
$green_600: #1ada51;
$green_700: #06c430;
$green_800: #1a9335;
$green_900: #155f24;
$green_gy: #475e4c;
$green_dl: #4e8559;
$green_sf: #748f7f;

// Amber
$amber_300: #fdf6bf;
$amber_400: #ffeb95;
$amber_500: #ffd86b;
$amber_600: #ffc53d;
$amber_700: #fcb321;
$amber_800: #fa960b;
$amber_900: #88440c;
$amber_gy: #684f3b;
$amber_dl: #9a6d49;
$amber_sf: #b58c6b;

// Orange
$orange_300: #fffcbf;
$orange_400: #fffa82;
$orange_500: #ff875c;
$orange_600: #ff6f36;
$orange_700: #ff5e1f;
$orange_800: #f24500;
$orange_900: #8f2400;
$orange_gy: #68463a;
$orange_dl: #9f5f4a;
$orange_sf: #bb8472;

// Red
$red_300: #fcc5cb;
$red_400: #ff96a3;
$red_500: #ff697a;
$red_600: #ff334b;
$red_700: #e5172f;
$red_800: #c9162b;
$red_900: #85101e;
$red_gy: #673d42;
$red_dl: #9b4a54;
$red_sf: #b8767e;

// Magenta
$magenta_300: #fccae7;
$magenta_400: #ff9ccd;
$magenta_500: #ff70b0;
$magenta_600: #f74397;
$magenta_700: #de2c7f;
$magenta_800: #bf0659;
$magenta_900: #8a0e46;
$magenta_gy: #6f3e54;
$magenta_dl: #9d5375;
$magenta_sf: #bc7f9b;

// Purple
$purple_300: #edc8f7;
$purple_400: #dd9cf7;
$purple_500: #ce7af0;
$purple_600: #c355d9;
$purple_700: #af36c7;
$purple_800: #8d18a5;
$purple_900: #5b1f66;
$purple_gy: #634469;
$purple_dl: #794583;
$purple_sf: #9c6fa5;

// Deep Purple
$deep_purple_300: #d4c4f5;
$deep_purple_400: #bd9ff5;
$deep_purple_500: #a17df5;
$deep_purple_600: #8556e3;
$deep_purple_700: #6b3cc9;
$deep_purple_800: #532f99;
$deep_purple_900: #47346b;
$deep_purple_gy: #56437b;
$deep_purple_dl: #624992;
$deep_purple_sf: #856eb2;

// Neutral
$white: #fff;
$gray_50: #fafafb;
$gray_100: #f7f7f9;
$gray_150: #f2f2f5;
$gray_200: #ebebee;
$gray_250: #e0e1e5;
$gray_300: #d8d8dc;
$gray_350: #cacace;
$gray_400: #bcbcc0;
$gray_450: #aeaeb2;
$gray_500: #8e8e93;
$gray_550: #6c6c70;
$gray_600: #565658;
$gray_650: #48484a;
$gray_700: #3a3a3c;
$gray_750: #2c2c2e;
$gray_800: #232325;
$gray_850: #1c1c1e;
$gray_900: #181818;
$black: #000;

// Alpha
$white_0a: rgba(255, 255, 255, 0);
$white_50a: rgba(255, 255, 255, 0.05);
$white_100a: rgba(255, 255, 255, 0.1);
$white_200a: rgba(255, 255, 255, 0.2);
$white_300a: rgba(255, 255, 255, 0.3);
$white_400a: rgba(255, 255, 255, 0.4);
$white_500a: rgba(255, 255, 255, 0.5);
$white_600a: rgba(255, 255, 255, 0.6);
$white_700a: rgba(255, 255, 255, 0.7);
$white_800a: rgba(255, 255, 255, 0.8);
$white_900a: rgba(255, 255, 255, 0.9);
$black_0a: rgba(0, 0, 0, 0);
$black_50a: rgba(0, 0, 0, 0.05);
$black_100a: rgba(0, 0, 0, 0.1);
$black_200a: rgba(0, 0, 0, 0.2);
$black_300a: rgba(0, 0, 0, 0.3);
$black_400a: rgba(0, 0, 0, 0.4);
$black_500a: rgba(0, 0, 0, 0.5);
$black_600a: rgba(0, 0, 0, 0.6);
$black_700a: rgba(0, 0, 0, 0.7);
$black_800a: rgba(0, 0, 0, 0.8);
$black_900a: rgba(0, 0, 0, 0.9);

// Service
$naver_green: #03c75a;
$kakao_yellow: #fee500;
$tid_blue: #3617ce;
$nugu_blue: #1b8aff;

////////////////////////////
/// Theme
////////////////////////////

$naver: $naver_green;
$kakao: $kakao_yellow;
$tid: $tid_blue;
$nugu: $nugu_blue;

// Background and Surface
$background: $white;
$background_inverse: $black;
$surface_primary: $gray_150;
$surface_secondary: $gray_150;
$surface_tertiary: $gray_150;
$surface_quaternary: $gray_300;
$surface_minimal: $gray_100;
$surface_alt: $blue_50;

// Text
$text_primary: $gray_800;
$text_secondary: $gray_600;
$text_tertiary: $gray_500;
$text_disabled: $gray_400;
$text_transparent: $gray_400;
$text_inverse: $white;

// Icon
$icon_enabled: $gray_800;
$icon_subtle: $gray_500;
$icon_active: $gray_800;
$icon_inactive: $gray_500;
$icon_disabled: $gray_400;
$icon_pressed: $gray_400;

// Border
$border: $gray_250;
$border_subtle: $gray_150;
$border_selected: $gray_800;
$border_transparent: $black_100a;

// Overlay
$overlay_70: $black_700a;
$overlay_50: $black_500a;
$overlay_10: $black_100a;
$overlay_5: $black_50a;

// Gradation
$gradation_from_0_static: $black_0a;
$gradation_to_50_static: $black_500a;
$gradation_from_0: $white_0a;
$gradation_to_100: $white;

// Static (Always)
$static_white: $white;
$static_white_bold: $white_800a;
$static_white_subtle: $white_400a;
$static_white_subtler: $white_300a;
$static_black: $black;
$static_black_bold: $black_800a;
$static_black_subtler: $black_300a;

// Component-specific
$modal_background: $white;
$modal_surface_primary: $gray_100;
$modal_surface_secondary: $gray_150;
$button_surface_accent: $blue_800;
$button_surface_accent_pressed: $blue_600;
$button_surface_accent_disabled: $gray_250;
$button_surface_neutral: $gray_150;
$button_surface_neutral_pressed: $gray_250;
$button_surface_neutral_disabled: $gray_100;
$toast_background: $gray_750;
$list_overlay_disabled: $white_800a;

// Semantic
$accent: $blue_800;
$error: $red_700;
$info: $sky_blue_700;
