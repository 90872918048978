
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.artist-name {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: currentcolor;

  &--link {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: currentcolor;
    cursor: pointer;
  }

  &--icon {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1px;
    vertical-align: middle;
  }
}
