.paging_dot_w {
  padding: 3px 0 10px 15px;
  font-size: 0;
  text-align: center;

  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 10px;
    background-color: $font_default;
    border-radius: 6px;
    opacity: 0.3;
    @include hide_text;

    &:hover,
    &:active,
    &.selected {
      background-color: $font_blue;
      opacity: 1;
    }
  }
}

.paging_slide_arrow_w {
  .btn_paging_slide {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @include hide_text;

    &.previous {
      @include retina-sprite($sp-button-btn-arrow-back-small-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-arrow-back-small-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-arrow-back-small-dim-group);
      }

      &:disabled,
      &.disabled,
      &.swiper-button-disabled {
        @include retina-sprite($sp-button-btn-arrow-back-small-dim-group);
      }
    }

    &.forward {
      margin-left: 2px;
      @include retina-sprite($sp-button-btn-arrow-small-nor-group);

      &:hover {
        @include retina-sprite($sp-button-btn-arrow-small-hover-group);
      }

      &:active {
        @include retina-sprite($sp-button-btn-arrow-small-dim-group);
      }

      &:disabled,
      &.disabled,
      &.swiper-button-disabled {
        @include retina-sprite($sp-button-btn-arrow-small-dim-group);
      }
    }
  }

  .swiper-button-disabled {
    cursor: default;
  }
}

.paging_arrow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .btn_paging_arrow {
    position: absolute;
    top: calc(160px - 55px / 2);
    z-index: 5;
    width: 55px;
    height: 55px;
    @include hide_text;

    &.previous {
      left: 0;
      @include retina-sprite($sp-button-btn-panel-arrow-back-group);

      transform: translate(-100%, 0);

      &:hover {
        @include retina-sprite($sp-button-btn-panel-arrow-back-over-group);
      }
    }

    &.forward {
      right: 0;
      @include retina-sprite($sp-button-btn-panel-arrow-group);

      transform: translate(100%, 0);

      &:hover {
        @include retina-sprite($sp-button-btn-panel-arrow-over-group);
      }
    }

    &.swiper-button-disabled {
      visibility: hidden;
    }
  }
}
