
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.list_track {
  position: relative;
}

.playlist-group {
  margin: 10px 13px 10px 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 15px 15px;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__check {
    margin-right: 5px;
  }

  &__title {
    @include ellipsis1;

    width: 360px;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;

    &.active {
      color: #7286ff;
    }
  }

  &__content {
    padding-bottom: 20px;
  }

  &__list {
    padding-bottom: 10px;
  }
}
