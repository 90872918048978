
          @import '@/assets/scss/common/color.scss';
        

.playlist-tab {
  margin-right: 8px;
  border-bottom: 1px solid rgba(240, 240, 240, 0.2);

  &__list {
    display: flex;
  }

  &__item {
    padding-bottom: 10px;
    margin-right: 20px;
    font-size: 17px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.3);
    border: none;

    &.active {
      color: #fff;
      border-bottom: 3px solid #7286ff;
    }
  }
}
