$lyrins-info-dt-width: 80px;
$album-desc-info-dt-width: 80px;

// 앨범 곡 정보
.ly_explain {
  padding-left: 15px;

  .info_txt {
    overflow: hidden;
    white-space: pre-wrap;

    dl {
      margin-top: -20px;
      font-size: 15px;

      dt {
        float: left;
        width: $lyrins-info-dt-width;
        padding-top: 20px;
        font-weight: bold;
      }

      dd {
        padding-top: 21px;
        margin-left: $lyrins-info-dt-width;
        overflow-wrap: break-word;
      }
    }
  }

  // 가사
  .lyrics {
    padding-top: 40px;
    margin: 0;
    font-size: 15px;
    line-height: 27px;
    word-wrap: break-word;
    white-space: pre-wrap;

    .lyrics_adult {
      margin: 100px 0 200px;
      color: #333;
      text-align: center;
      opacity: 0.3;

      strong {
        display: block;
        margin-bottom: 10px;
        font-size: 17px;
      }
    }
  }

  &.album_intro_desc {
    .info_txt {
      dl {
        dt {
          width: $album-desc-info-dt-width;
        }
      }
    }

    h4 {
      margin-top: 40px;
    }
  }
}

.big_size {
  .comp_inp_txt {
    padding-right: 32px;
    font-size: 28px;
    font-weight: bold;
  }

  .comp_inc_btn {
    .btn_pw_del {
      @include retina-sprite($sp-button-btn-input-edit-delete-nor-group);
      @include hide_text;
    }
  }

  label {
    position: static;
  }
}

.detail_album_plus_full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 400;
  padding: 68px 80px 130px;
  overflow-y: auto;
  background: #fff;

  h2 {
    font-size: 26px;
  }

  .add_song_wrap {
    min-width: 800px;
    max-width: 1600px;
    margin: 0 auto;
  }

  .btn_full_del {
    position: absolute;
    top: 67px;
    right: 80px;
    @include retina-sprite($sp-button-btn-page-close-nor-group);
    @include hide_text;
  }

  &.transitionWrap {
    width: auto;
    transform: translate(0, 0);
  }
}
