
          @import '@/assets/scss/common/color.scss';
        

.music-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding-right: 20px;

  &__title {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &__text-ellipsis {
    display: inline-block;
    gap: 2px;
    align-items: center;
    width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__check {
    width: auto;
    min-width: 20px;
  }

  &__checkbox {
    margin-right: 8px;
    margin-left: 15px;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 20px 9px 0;
  }

  &__thumbnail {
    position: relative;
    width: 45px;
    height: 45px;
  }

  &__image {
    width: 45px;
    height: 45px;
    border-radius: 4px;
  }

  &__playing {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
  }

  &__now {
    width: 17px;
    height: 17px;
  }

  &__text {
    width: 340px;
    padding: 5px 0;
    margin-left: 16px;
    cursor: pointer;
  }

  &__artist {
    margin-top: 4px;
    font-size: 12px;
    color: #989898;
  }
}

.group-area {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
