// badge layout
.badge_area {
  // overflow: hidden;
  position: relative;
  display: table;
  width: 412px;

  &.big_size {
    box-sizing: border-box;
    width: 100%;
    padding-left: 50px;
    table-layout: fixed; // 긴 제목 ellipsis 적용하기위해 필요함
  }

  .album_thumbnail {
    // float: left;
    display: table-cell;

    .link_thumbnail {
      height: 175px;

      img {
        object-fit: cover;
      }
    }

    &.sm_size {
      .link_thumbnail {
        height: 100%;
      }
    }
  }

  .badge_track_info {
    // float: left;
    display: table-cell;
  }

  label {
    position: absolute;
    top: 24px; // TODO: 위치 수정 필요
    left: 10px;
    z-index: 50;
  }

  &.checked {
    &::after {
      position: absolute;
      top: 14px; // TODO: 위치 수정 필요
      right: -12px;
      left: 20px;
      display: block;
      height: 175px;
      content: '';
      background: #f5f5f5;
      border-radius: 10px;
    }

    &.type_artist {
      &::after {
        border-radius: 100px;
      }
    }
  }

  // dimmed처리
  &.dimmed {
    &::before {
      @include searchDimmed;
    }

    &::after,
    label {
      display: none;
    }
  }
}

// badge common
.badge_track_info {
  position: relative;
  z-index: 10;
  padding-top: 14px;
  padding-left: 20px;

  .info_area {
    width: 204px;
  }

  .badge_ic_adult {
    @include hide_text;
    @include retina-sprite($sp-icon-ic-badge-age-19-small-group);

    display: inline-block;
    margin-right: 4px;
    vertical-align: top;

    &.big {
      margin-top: 3px;
    }
  }

  .title {
    @include ellipsis1;

    // 플레이리스트, small 사이즈일 경우만 타이틀 2줄 적용 - GODMUSIC-9955 홈 개편 스펙
    &.type_playlist {
      @include ellipsis_multi(2, '');

      white-space: normal;
    }

    i.origin {
      @include hide_text;
      @include retina-sprite($sp-icon-ic-thumb-badge-origin-group);

      display: inline-block;
      margin-right: 4px;
      vertical-align: top;
    }

    .title_seed {
      span {
        @include ellipsis1;

        display: inline-block;
        max-width: calc(100% - 50px);
        vertical-align: middle;
      }
    }
  }

  .title_detail {
    padding-top: 14px;
    font-size: 16px;
  }

  .title_modify {
    @extend.title;
  }

  .info_producer {
    @include ellipsis1;
  }

  dl {
    overflow: hidden;

    dd {
      position: relative;
      display: inline;
      padding-left: 9px;
      font-size: 13px;

      &.creator {
        @include ellipsis1;

        width: 204px;
      }

      &.track-count {
        display: flex;
        align-items: center;
      }

      &.date {
        display: block;
        padding: 7px 0 0;
        clear: both;
        color: #969696;

        &::after {
          display: none;
        }
      }

      &.info-block {
        display: block;
        padding-bottom: 8px;

        & + dd {
          padding-left: 0;

          &::after {
            display: none;
          }
        }
      }

      &:first-of-type {
        padding-left: 0;

        &::after {
          display: none;
        }
      }

      &::after {
        position: absolute;
        top: 4px;
        left: 4px;
        display: block;
        width: 1px;
        height: 8px;
        content: '';
        background-color: #dcdcdc;
      }
    }

    i.update,
    & i.new {
      @include hide_text;

      display: inline-block;
      margin-right: 5px;

      // vertical-align: top;
    }

    i.update {
      @include retina-sprite($sp-icon-ic-thumb-badge-update-group);
    }

    i.new {
      @include retina-sprite($sp-icon-ic-thumb-badge-new-group);
    }
  }

  // 하단 버튼
  .btn_add_list {
    &:disabled {
      &,
      &:hover {
        @include retina-sprite($sp-button-btn-thumb-playlist-press-group);
      }

      cursor: not-allowed;
    }

    @include retina-sprite($sp-button-btn-thumb-playlist-nor-group);
    @include hide_text;

    &:active {
      @include retina-sprite($sp-button-btn-thumb-playlist-press-group);
    }

    &:hover {
      @include retina-sprite($sp-button-btn-thumb-playlist-hover-group);
    }
  }

  .btn_add_playlist {
    @include retina-sprite($sp-button-btn-thumb-mylist-nor-group);
    @include hide_text;

    &:active {
      @include retina-sprite($sp-button-btn-thumb-mylist-press-group);
    }

    &:hover {
      @include retina-sprite($sp-button-btn-thumb-mylist-hover-group);
    }
  }

  .btn_like {
    @include retina-sprite($sp-button-btn-thumb-like-nor-group);
    @include hide_text;

    &:active {
      @include retina-sprite($sp-button-btn-thumb-like-press-group);
    }

    &:hover {
      @include retina-sprite($sp-button-btn-thumb-like-hover-group);
    }

    &.active {
      @include retina-sprite($sp-button-btn-thumb-like-sel-group);
    }
  }

  .btn_pin {
    @include retina-sprite($sp-button-btn-pin-nor-group);
    @include hide_text;

    &:hover {
      @include retina-sprite($sp-button-btn-pin-hover-group);
    }

    &:active,
    &.active {
      @include retina-sprite($sp-button-btn-pin-press-group);
    }
  }

  @at-root .btn_popular_listen {
    font-size: 13px;
    color: #333;

    &::before {
      @include retina-sprite($sp-button-btn-list-top-play-b-nor-group);

      display: inline-block;
      vertical-align: middle;
      content: '';
    }

    &:hover {
      color: $key_color;

      &::before {
        @include retina-sprite($sp-button-btn-list-top-play-b-hover-group);
      }
    }

    &:active {
      color: rgba(51, 51, 51, 0.3);

      &::before {
        @include retina-sprite($sp-button-btn-list-top-play-b-press-group);
      }
    }
  }

  // 앨범, 플레이 리스트
  @at-root .type_default {
    .badge_track_info {
      vertical-align: top;

      .title {
        padding-top: 15px;
        font-size: 15px;
        font-weight: bold;
      }

      .artist {
        position: relative;
        height: 16px;
        padding-top: 8px;

        &__title {
          @include ellipsis1;

          display: inline-block;
          max-width: #{204px - 12px - 3px};
          padding-right: 3px;
          font-size: 14px;
        }

        &__more-icon {
          height: 100%;
          vertical-align: baseline;
        }

        .artist_link_and {
          margin: 0 5px;
        }
      }

      .album {
        position: relative;
        height: 16px;
        padding-top: 8px;
        color: #969696;

        &__title {
          @include ellipsis1;

          display: inline-block;
          max-width: #{204px - 12px - 3px};
          padding-right: 3px;
          font-size: 14px;
        }

        &__more-icon {
          height: 100%;
          vertical-align: baseline;
        }

        a {
          color: #969696;

          &:hover {
            color: $font_purple;
          }
        }

        .ico_free {
          @include hide_text;
          @include retina-sprite($sp-icon-icon-free-group);

          display: inline-block;
          margin-right: 5px;
          vertical-align: middle;
        }
      }

      %anchor_bullet_arrow {
        position: relative;
        padding-right: 14px;
      }

      dl {
        padding-top: 15px;
      }

      .util_area {
        position: absolute;
        bottom: 16px;
        clear: both;

        button[class^='btn_'] {
          margin-right: 14px;
        }
      }
    }
  }

  // 아티스트
  @at-root .type_artist {
    .badge_track_info {
      vertical-align: middle;

      .artist {
        font-size: 15px;
        font-weight: bold;
      }

      dl {
        padding-top: 7px;
      }

      .util_area {
        padding-top: 15px;

        button[class^='btn_'] {
          margin-right: 8px;
        }
      }
    }
  }

  // 플레이리스트
  @at-root .type_explain {
    &.big_size {
      .badge_track_info {
        .info_area {
          padding-top: 0;
        }

        .explain_area {
          position: relative;
          padding: 10px 0 20px;

          .txt {
            @include ellipsis_multi(2, 42);

            font-size: 15px;
            line-height: 21px;
            color: #969696;
            white-space: pre-line;

            a {
              display: inline;
              color: #333;
              text-decoration: underline;

              &:hover {
                color: $key_color;
              }
            }
          }

          .btn_explain_more {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: 13px;
            color: rgba(51, 51, 51, 0.3);
          }
        }

        dl {
          padding-top: 15px;
        }

        .util_area {
          bottom: 5px;
        }
      }
    }
  }
}

.big_size {
  .badge_track_info {
    padding-right: 50px;
    padding-left: 35px;

    .info_area {
      @include clearfix;

      // width: 100%;
      position: relative;
      width: auto;
      padding-top: 12px;
      padding-right: 30px;
    }

    dl {
      padding-top: 30px;
      overflow: hidden;

      dd {
        padding-right: 10px;
        padding-left: 11px;
        font-size: 15px;

        &::after {
          left: 0;
        }

        i.update,
        & i.new {
          margin-top: -2px;
        }

        &.date {
          padding: 7px 0 0;
          clear: both;
          color: #969696;

          &::after {
            display: none;
          }
        }

        &:first-of-type {
          padding-left: 0;

          &::after {
            display: none;
          }
        }
      }
    }

    .title {
      padding-top: 20px;
      font-size: 28px;

      i.origin {
        margin-top: 5px;
      }

      .badge_ic_adult {
        @include retina-sprite($sp-icon-ic-badge-age-19-group);
      }
    }

    .title_modify {
      @extend.title;

      position: relative;
      float: left;
      overflow: inherit;
      white-space: normal;

      span {
        @include ellipsis_multi(2, 82px);

        line-height: 41px;
      }
    }

    .artist {
      z-index: 1;
      font-size: 16px;

      a.last {
        &::after {
          top: 3px;
        }
      }
    }

    .album {
      padding-top: 14px;
      font-size: 16px;

      a {
        &::after {
          top: 3px;
        }
      }
    }

    .explain {
      padding-top: 14px;
      font-size: 16px;
    }

    .util_area {
      bottom: 16px;
      left: 23px;

      button[class^='btn_'] {
        margin-right: 0;
      }
    }

    .btn_edit {
      @include retina-sprite($sp-button-btn-edit-nor-group);
      @include hide_text;

      position: absolute;
      right: -30px;
      bottom: 7px;
    }

    // 하단 버튼
    .btn_add_list {
      @include retina-sprite($sp-button-btn-list-playlist-nor-group);
      @include hide_text;

      &:hover {
        @include retina-sprite($sp-button-btn-list-playlist-hover-group);
      }
    }

    .btn_add_playlist {
      @include retina-sprite($sp-button-btn-list-mylist-nor-group);
      @include hide_text;

      &:hover {
        @include retina-sprite($sp-button-btn-list-mylist-hover-group);
      }
    }

    .btn_like {
      @include retina-sprite($sp-button-btn-like-nor-group);
      @include hide_text;

      &.active,
      &.active:hover {
        @include retina-sprite($sp-button-btn-like-sel-group);
      }

      &:hover {
        @include retina-sprite($sp-button-btn-like-hover-group);
      }
    }

    .btn_share {
      @include retina-sprite($sp-button-btn-share-nor-group);
      @include hide_text;

      &:hover {
        @include retina-sprite($sp-button-btn-share-hover-group);
      }
    }

    .btn_pin {
      @include retina-sprite($sp-button-btn_pin-big-nor-group);
      @include hide_text;

      &:hover {
        @include retina-sprite($sp-button-btn_pin-big-hover-group);
      }

      &:active,
      &.active {
        @include retina-sprite($sp-button-btn_pin-big-press-group);
      }
    }

    .btn-list-playlist-dim {
      @include retina-sprite($sp-button-btn-list-playlist-dim-group);
      @include hide_text;
    }

    .btn_dislike {
      @include retina-sprite($sp-button-btn-dislike-nor-group);
      @include hide_text;

      &:hover,
      &.active {
        @include retina-sprite($sp-button-btn-dislike-sel-group);
      }
    }
  }

  // 아티스트

  &.type_artist {
    .badge_track_info {
      padding-left: 35px;

      .artist {
        font-size: 28px;

        @include ellipsis_multi(3, '');
      }

      dl {
        padding-top: 20px;

        dd {
          font-size: 15px;
        }
      }

      .util_area {
        padding-top: 30px;
        margin-left: -7px;

        button[class^='btn_'] {
          margin-right: 6px;
        }
      }
    }
  }

  &.type_recommend {
    .badge_track_info {
      .title {
        padding-top: 8px;
        font-size: 20px;
        font-weight: normal;
      }

      .explain {
        @include ellipsis_multi(2, '');

        padding-top: 10px;
        font-size: 28px;
        font-weight: bold;
      }
    }
  }
}
