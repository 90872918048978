$banner-col2-gap: 30px; // 2단 배너 간격
$banner-text-padding-left: 50px; // 텍스트 영역 왼쪽 간격

.main_banner {
  min-width: 640px;
  max-width: 1600px;
  padding-top: 32px;
  margin: 0 auto;

  // padding-bottom: 71px;
  // padding-right: 80px;
  @include respond-to(mediaquery-small) {
    // min-width: 740px;
    // max-width: 740px;
    padding-top: 40px;

    // padding-right: 30px;
  }

  // common
  a {
    // TODO: font-family 필요
    display: block;
    height: 200px;
    background-repeat: no-repeat;
    background-position: 100% 0;
    border-radius: 10px;

    p {
      max-width: 547px - $banner-text-padding-left;
      padding-top: 53px;
      padding-left: $banner-text-padding-left;
      font-size: 38px;
      line-height: 1.24;
      color: #333;
      text-align: left;
      letter-spacing: -1px;

      strong {
        display: block;
      }
    }
  }

  // col 개수에 따른 배너 스타일
  &.col1 {
    ul {
      width: 100%;

      li {
        display: block;
        margin-top: 30px;

        a {
          height: auto;
          font-size: 0;
          text-align: center;
          border-radius: 0;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  &.col2 {
    ul {
      margin-left: -$banner-col2-gap;
      overflow: hidden;
    }

    li {
      float: left;
      width: 50%;
      @include respond-to(mediaquery-small) {
        width: 100%;
        padding-bottom: 30px;
      }
    }

    a {
      margin-left: $banner-col2-gap;
    }
  }
}
