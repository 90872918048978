
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.lyrics {
  height: 52px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include respond-to(mediaquery-large) {
      max-height: 460px;
    }

    max-height: 360px;
    overflow: hidden;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  &__focus {
    position: absolute;
    top: 0;
    right: 10px;
    color: rgba(255, 255, 255, 0.8);
  }

  &__text {
    height: 30px;
    font-size: 15px;
    font-weight: 400;
    color: #989898;
    white-space: pre-line;
  }

  &__adult {
    position: relative;
    top: 150px;
    padding: 66px 0;

    div {
      line-height: 20px;
      text-align: center;
    }
  }

  &__footer {
    margin-top: 30px;
    text-align: left;

    &-center {
      margin-top: 30px;
      text-align: center;
    }
  }

  &__close {
    box-sizing: border-box;
    width: 80px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    color: #fff;
    border: 1px solid #979797;
    border-radius: 4px;

    &:hover {
      background-color: rgba(240, 240, 240, 0.2);
      border: none;
    }
  }

  &--highlight {
    color: white;
  }

  &--scroll {
    color: #7286ff;

    &:hover {
      color: white;
    }
  }

  &--open {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 360px;
    height: 100%;
    text-align: left;
    transform: translate(-50%, -50%);

    .lyrics__container {
      overflow: auto;
    }
  }

  &--close {
    overflow: hidden;
  }

  &__request {
    margin-top: 15px;
    color: #fff;

    svg {
      padding-bottom: 2px;
    }
  }

  /* Empty 컴포넌트 스타일 오버라이딩 */
  &__empty {
    @include respond-to(mediaquery-large) {
      padding: 100px 0;
    }

    padding: 66px 0;

    ::v-deep {
      .empty-section {
        &__title {
          color: #a0a0a0;
        }

        &__sub-text {
          color: #646464;
        }
      }
    }
  }
}
