// SCSS variables are information about icon's compiled state, stored under its original file name

// .icon-home {
//   width: $icon-home-width;
// }

// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;

// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-name: 'sp-storage-recommend-btn-play-thumb-157-hover-sm';
$sp-storage-recommend-btn-play-thumb-157-hover-sm-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-y: 37px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-offset-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-offset-y: -37px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-width: 26px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-height: 26px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-total-width: 92px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-total-height: 63px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-image: '../../assets/img/sprite/sp_storage_recommend.png';
$sp-storage-recommend-btn-play-thumb-157-hover-sm: (
  0,
  37px,
  0,
  -37px,
  26px,
  26px,
  92px,
  63px,
  '../../assets/img/sprite/sp_storage_recommend.png',
  'sp-storage-recommend-btn-play-thumb-157-hover-sm'
);
$sp-storage-recommend-btn-play-thumb-157-hover-name: 'sp-storage-recommend-btn-play-thumb-157-hover';
$sp-storage-recommend-btn-play-thumb-157-hover-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-y: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-offset-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-offset-y: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-width: 32px;
$sp-storage-recommend-btn-play-thumb-157-hover-height: 32px;
$sp-storage-recommend-btn-play-thumb-157-hover-total-width: 92px;
$sp-storage-recommend-btn-play-thumb-157-hover-total-height: 63px;
$sp-storage-recommend-btn-play-thumb-157-hover-image: '../../assets/img/sprite/sp_storage_recommend.png';
$sp-storage-recommend-btn-play-thumb-157-hover: (
  0,
  0,
  0,
  0,
  32px,
  32px,
  92px,
  63px,
  '../../assets/img/sprite/sp_storage_recommend.png',
  'sp-storage-recommend-btn-play-thumb-157-hover'
);
$sp-storage-recommend-btn-play-thumb-157-nor-sm-name: 'sp-storage-recommend-btn-play-thumb-157-nor-sm';
$sp-storage-recommend-btn-play-thumb-157-nor-sm-x: 31px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-y: 37px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-offset-x: -31px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-offset-y: -37px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-width: 26px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-height: 26px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-total-width: 92px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-total-height: 63px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-image: '../../assets/img/sprite/sp_storage_recommend.png';
$sp-storage-recommend-btn-play-thumb-157-nor-sm: (
  31px,
  37px,
  -31px,
  -37px,
  26px,
  26px,
  92px,
  63px,
  '../../assets/img/sprite/sp_storage_recommend.png',
  'sp-storage-recommend-btn-play-thumb-157-nor-sm'
);
$sp-storage-recommend-btn-play-thumb-157-nor-name: 'sp-storage-recommend-btn-play-thumb-157-nor';
$sp-storage-recommend-btn-play-thumb-157-nor-x: 37px;
$sp-storage-recommend-btn-play-thumb-157-nor-y: 0;
$sp-storage-recommend-btn-play-thumb-157-nor-offset-x: -37px;
$sp-storage-recommend-btn-play-thumb-157-nor-offset-y: 0;
$sp-storage-recommend-btn-play-thumb-157-nor-width: 32px;
$sp-storage-recommend-btn-play-thumb-157-nor-height: 32px;
$sp-storage-recommend-btn-play-thumb-157-nor-total-width: 92px;
$sp-storage-recommend-btn-play-thumb-157-nor-total-height: 63px;
$sp-storage-recommend-btn-play-thumb-157-nor-image: '../../assets/img/sprite/sp_storage_recommend.png';
$sp-storage-recommend-btn-play-thumb-157-nor: (
  37px,
  0,
  -37px,
  0,
  32px,
  32px,
  92px,
  63px,
  '../../assets/img/sprite/sp_storage_recommend.png',
  'sp-storage-recommend-btn-play-thumb-157-nor'
);
$sp-storage-recommend-icon-small-new-name: 'sp-storage-recommend-icon-small-new';
$sp-storage-recommend-icon-small-new-x: 74px;
$sp-storage-recommend-icon-small-new-y: 0;
$sp-storage-recommend-icon-small-new-offset-x: -74px;
$sp-storage-recommend-icon-small-new-offset-y: 0;
$sp-storage-recommend-icon-small-new-width: 18px;
$sp-storage-recommend-icon-small-new-height: 18px;
$sp-storage-recommend-icon-small-new-total-width: 92px;
$sp-storage-recommend-icon-small-new-total-height: 63px;
$sp-storage-recommend-icon-small-new-image: '../../assets/img/sprite/sp_storage_recommend.png';
$sp-storage-recommend-icon-small-new: (
  74px,
  0,
  -74px,
  0,
  18px,
  18px,
  92px,
  63px,
  '../../assets/img/sprite/sp_storage_recommend.png',
  'sp-storage-recommend-icon-small-new'
);
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-name: 'sp-storage-recommend-btn-play-thumb-157-hover-sm@2x';
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-y: 74px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-offset-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-offset-y: -74px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-width: 52px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-height: 52px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-total-width: 184px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-total-height: 126px;
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x-image: '../../assets/img/sprite/sp_storage_recommend@2x.png';
$sp-storage-recommend-btn-play-thumb-157-hover-sm-2x: (
  0,
  74px,
  0,
  -74px,
  52px,
  52px,
  184px,
  126px,
  '../../assets/img/sprite/sp_storage_recommend@2x.png',
  'sp-storage-recommend-btn-play-thumb-157-hover-sm@2x'
);
$sp-storage-recommend-btn-play-thumb-157-hover-2x-name: 'sp-storage-recommend-btn-play-thumb-157-hover@2x';
$sp-storage-recommend-btn-play-thumb-157-hover-2x-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-y: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-offset-x: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-offset-y: 0;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-width: 64px;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-height: 64px;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-total-width: 184px;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-total-height: 126px;
$sp-storage-recommend-btn-play-thumb-157-hover-2x-image: '../../assets/img/sprite/sp_storage_recommend@2x.png';
$sp-storage-recommend-btn-play-thumb-157-hover-2x: (
  0,
  0,
  0,
  0,
  64px,
  64px,
  184px,
  126px,
  '../../assets/img/sprite/sp_storage_recommend@2x.png',
  'sp-storage-recommend-btn-play-thumb-157-hover@2x'
);
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-name: 'sp-storage-recommend-btn-play-thumb-157-nor-sm@2x';
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-x: 62px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-y: 74px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-offset-x: -62px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-offset-y: -74px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-width: 52px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-height: 52px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-total-width: 184px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-total-height: 126px;
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x-image: '../../assets/img/sprite/sp_storage_recommend@2x.png';
$sp-storage-recommend-btn-play-thumb-157-nor-sm-2x: (
  62px,
  74px,
  -62px,
  -74px,
  52px,
  52px,
  184px,
  126px,
  '../../assets/img/sprite/sp_storage_recommend@2x.png',
  'sp-storage-recommend-btn-play-thumb-157-nor-sm@2x'
);
$sp-storage-recommend-btn-play-thumb-157-nor-2x-name: 'sp-storage-recommend-btn-play-thumb-157-nor@2x';
$sp-storage-recommend-btn-play-thumb-157-nor-2x-x: 74px;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-y: 0;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-offset-x: -74px;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-offset-y: 0;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-width: 64px;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-height: 64px;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-total-width: 184px;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-total-height: 126px;
$sp-storage-recommend-btn-play-thumb-157-nor-2x-image: '../../assets/img/sprite/sp_storage_recommend@2x.png';
$sp-storage-recommend-btn-play-thumb-157-nor-2x: (
  74px,
  0,
  -74px,
  0,
  64px,
  64px,
  184px,
  126px,
  '../../assets/img/sprite/sp_storage_recommend@2x.png',
  'sp-storage-recommend-btn-play-thumb-157-nor@2x'
);
$sp-storage-recommend-icon-small-new-2x-name: 'sp-storage-recommend-icon-small-new@2x';
$sp-storage-recommend-icon-small-new-2x-x: 148px;
$sp-storage-recommend-icon-small-new-2x-y: 0;
$sp-storage-recommend-icon-small-new-2x-offset-x: -148px;
$sp-storage-recommend-icon-small-new-2x-offset-y: 0;
$sp-storage-recommend-icon-small-new-2x-width: 36px;
$sp-storage-recommend-icon-small-new-2x-height: 36px;
$sp-storage-recommend-icon-small-new-2x-total-width: 184px;
$sp-storage-recommend-icon-small-new-2x-total-height: 126px;
$sp-storage-recommend-icon-small-new-2x-image: '../../assets/img/sprite/sp_storage_recommend@2x.png';
$sp-storage-recommend-icon-small-new-2x: (
  148px,
  0,
  -148px,
  0,
  36px,
  36px,
  184px,
  126px,
  '../../assets/img/sprite/sp_storage_recommend@2x.png',
  'sp-storage-recommend-icon-small-new@2x'
);
$spritesheet-width: 92px;
$spritesheet-height: 63px;
$spritesheet-image: '../../assets/img/sprite/sp_storage_recommend.png';
$spritesheet-sprites: (
  $sp-storage-recommend-btn-play-thumb-157-hover-sm,
  $sp-storage-recommend-btn-play-thumb-157-hover,
  $sp-storage-recommend-btn-play-thumb-157-nor-sm,
  $sp-storage-recommend-btn-play-thumb-157-nor,
  $sp-storage-recommend-icon-small-new
);
$spritesheet: (92px, 63px, '../../assets/img/sprite/sp_storage_recommend.png', $spritesheet-sprites);
$retina-spritesheet-width: 184px;
$retina-spritesheet-height: 126px;
$retina-spritesheet-image: '../../assets/img/sprite/sp_storage_recommend@2x.png';
$retina-spritesheet-sprites: (
  $sp-storage-recommend-btn-play-thumb-157-hover-sm-2x,
  $sp-storage-recommend-btn-play-thumb-157-hover-2x,
  $sp-storage-recommend-btn-play-thumb-157-nor-sm-2x,
  $sp-storage-recommend-btn-play-thumb-157-nor-2x,
  $sp-storage-recommend-icon-small-new-2x
);
$retina-spritesheet: (184px, 126px, '../../assets/img/sprite/sp_storage_recommend@2x.png', $retina-spritesheet-sprites);

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sp-storage-recommend-btn-play-thumb-157-hover-sm-group-name: 'sp-storage-recommend-btn-play-thumb-157-hover-sm';
$sp-storage-recommend-btn-play-thumb-157-hover-sm-group: (
  'sp-storage-recommend-btn-play-thumb-157-hover-sm',
  $sp-storage-recommend-btn-play-thumb-157-hover-sm,
  $sp-storage-recommend-btn-play-thumb-157-hover-sm-2x
);
$sp-storage-recommend-btn-play-thumb-157-hover-group-name: 'sp-storage-recommend-btn-play-thumb-157-hover';
$sp-storage-recommend-btn-play-thumb-157-hover-group: (
  'sp-storage-recommend-btn-play-thumb-157-hover',
  $sp-storage-recommend-btn-play-thumb-157-hover,
  $sp-storage-recommend-btn-play-thumb-157-hover-2x
);
$sp-storage-recommend-btn-play-thumb-157-nor-sm-group-name: 'sp-storage-recommend-btn-play-thumb-157-nor-sm';
$sp-storage-recommend-btn-play-thumb-157-nor-sm-group: (
  'sp-storage-recommend-btn-play-thumb-157-nor-sm',
  $sp-storage-recommend-btn-play-thumb-157-nor-sm,
  $sp-storage-recommend-btn-play-thumb-157-nor-sm-2x
);
$sp-storage-recommend-btn-play-thumb-157-nor-group-name: 'sp-storage-recommend-btn-play-thumb-157-nor';
$sp-storage-recommend-btn-play-thumb-157-nor-group: (
  'sp-storage-recommend-btn-play-thumb-157-nor',
  $sp-storage-recommend-btn-play-thumb-157-nor,
  $sp-storage-recommend-btn-play-thumb-157-nor-2x
);
$sp-storage-recommend-icon-small-new-group-name: 'sp-storage-recommend-icon-small-new';
$sp-storage-recommend-icon-small-new-group: (
  'sp-storage-recommend-icon-small-new',
  $sp-storage-recommend-icon-small-new,
  $sp-storage-recommend-icon-small-new-2x
);
$retina-groups: (
  $sp-storage-recommend-btn-play-thumb-157-hover-sm-group,
  $sp-storage-recommend-btn-play-thumb-157-hover-group,
  $sp-storage-recommend-btn-play-thumb-157-nor-sm-group,
  $sp-storage-recommend-btn-play-thumb-157-nor-group,
  $sp-storage-recommend-icon-small-new-group
);

// The provided mixins are intended to be used with the array-like variables

// .icon-home {
//   @include sprite-width($icon-home);
// }

// .icon-email {
//   @include sprite($icon-email);
// }

// Example usage in HTML:

// `display: block` sprite:
// <div class="icon-home"></div>

// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// // CSS
// .icon {
//   display: inline-block;
// }

// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);

  background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);

  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.

// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );

// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);

  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS

// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS

// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
