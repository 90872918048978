// 나열형
.sort_type_list {
  overflow: hidden;

  li {
    float: left;
    padding: 0 7px 0 8px;
  }

  button {
    font-size: 13px;

    &.active {
      color: $key_color;
    }
  }
}

// 드롭다운형
.sort_type_drop {
  .current {
    font-size: 14px;
    color: #fff;

    &::after {
      @include retina-sprite($sp-button-btn-player-sorting-open-nor-group);

      display: inline-block;
      margin-left: 8px;
      content: '';

      @at-root {
        .sort_type_drop {
          .current {
            &.active {
              & + .layer_dropdown {
                display: block;
              }

              &::after {
                @include retina-sprite($sp-button-btn-player-sorting-close-nor-group);
              }
            }
          }
        }
      }
    }
  }

  .layer_dropdown {
    display: none;
  }
}
