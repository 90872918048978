/* 이용권 > 아티스트&플로 New */
.anflo_title {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 210px;
  margin-bottom: 20px;
  text-align: center;
  background-color: #f4f5f8;
  border-radius: 8px;

  .anf-logo {
    display: inline-block;

    @include retina-sprite($sp-common-artist-logo-main-group);
  }

  .desc {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    color: #484848;
  }

  .detail {
    margin-top: 10px;

    a {
      box-sizing: border-box;
      display: inline-block;
      min-width: 154px;
      height: 36px;
      padding: 0 19px;
      font-size: 14px;
      line-height: 36px;
      color: #fff;
      text-align: center;
      background-color: #3f3fff;
      border-radius: 18px;
    }
  }
}

$anflo_list_img: 460px;

// 메인 리스트
.voucher-anflo-item {
  position: relative;
  box-sizing: border-box;
  min-height: 200px;
  padding-right: $anflo_list_img;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f4f5f8;
  border-radius: 8px;

  .info {
    padding: 30px 30px 0 60px;
  }

  .info-title {
    font-weight: bold;
    color: #181818;

    .count {
      display: block;
      font-size: 18px;
    }

    .name {
      display: block;
      padding-top: 19px;
      font-size: 22px;

      &::after {
        display: inline-block;
        margin: -4px 0 0 5px;
        vertical-align: middle;
        content: '';

        @include retina-sprite($sp-common-artist-logo-w-group);
      }
    }
  }

  .date {
    padding-top: 6px;
    font-size: 12px;
    color: #181818;

    em {
      padding-left: 10px;
    }
  }

  .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $anflo_list_img;

    img {
      width: 100%;
      height: 200px;
      vertical-align: top;
    }
  }

  .voucher-link-arrow {
    position: absolute;
    bottom: 50px;
    left: 62px;

    &:hover,
    &:active,
    &:visited {
      color: #181818;
    }
  }
}

// 메인 리스트 없음
.anflo_empty {
  padding: 77px 0;
  margin-bottom: 40px;
  text-align: center;
  border: 1px solid #ededed;
  border-radius: 8px;

  p {
    font-size: 18px;
    line-height: 25px;
    color: #181818;
  }
}

// 이용권 > 카드형 이용권 리스트 (이용권(+추천), t혜택, my, 제휴에서 사용중)
.voucher-card {
  // 새창 레이어에서 (보유이용권)
  .viewsWrap & {
    width: 88%;
    margin: 0 auto;
  }
}

.voucher-card-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
  padding: 50px 60px;
  margin-bottom: 20px;
  background-color: #f4f5f8;
  border-radius: 8px;
  @include clearfix;

  .card-left {
    flex-shrink: 0;
    width: 310px;
    padding-bottom: 40px;
    margin-right: 14px;
    -ms-flex-negative: 0;
  }

  .card-right {
    flex: 1;
    align-self: center;
    padding-left: 49px;
    border-left: 1px solid rgba(0, 0, 0, 0.03);
    -webkit-box-flex: 1;
    -ms-flex-item-align: center;

    .voucher-info-list {
      margin-top: 7px;
    }
  }

  .card-row {
    width: 100%;

    .btn-detail {
      position: static;
      display: inline-block;
      margin-top: 30px;
    }
  }

  .name {
    margin-top: 4px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: #000;

    .ico-arrow-right {
      margin-top: -2px;
    }
  }

  .desc {
    margin-top: 5px;
    font-size: 13px;
    line-height: 20px;
    color: #555;
  }

  .item {
    position: relative;
    margin-bottom: 30px;
    text-align: right;

    .price-box-with-vat {
      display: inline-flex;
      flex-direction: column;
      margin-right: 80px;

      .vat-indicator {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .price-box {
      display: inline-block;
      line-height: 1.2;
      word-break: break-all;

      .first-cost {
        display: inline-block;
        margin-right: 10px;
        font-size: 13px;
        color: #bdbdbd;
        vertical-align: middle;
      }

      .subject {
        margin-right: 20px;
        font-size: 16px;
        color: #181818;
        vertical-align: middle;
      }

      .price {
        display: inline-block;
        font-size: 16px;
        color: #3f3fff;
        vertical-align: middle;

        em {
          font-size: 20px;
          vertical-align: middle;
        }

        span {
          position: relative;
          top: 2px;
        }
      }
    }

    .name {
      margin-top: 0;
      font-size: 16px;
      font-weight: 500;
    }

    .desc {
      margin-top: 10px;
      color: #929292;
    }

    & + .item {
      margin-top: 10px;

      &:last-child {
        padding-bottom: 9px;
      }
    }

    .btn-buy {
      box-sizing: border-box;
      display: inline-block;
      min-width: 60px;
      height: 32px;
      padding: 0 17px;
      margin-left: 20px;
      font-size: 14px;
      line-height: 33px;
      color: #fff;
      text-align: center;
      background-color: #3f3fff;
      border-radius: 22px;

      &:disabled {
        cursor: default;
      }

      &.move-to-voucher {
        width: 80px;
      }
    }

    .btn-buy-light {
      color: #3f33ff;
      background-color: #fff;
    }

    .btn-buy.btn_buy-recommend {
      color: #000;
      background-color: #fff;
    }
  }

  .btn-detail {
    position: absolute;
    bottom: 57px;
    left: 60px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #929292;
    background-size: 100% 100%;
    -webkit-box-flex: 0;

    svg {
      margin-left: 7px;
    }

    &:hover {
      color: #999;
    }
  }

  .ico-arrow-right {
    display: inline-block;
    vertical-align: middle;

    @include retina-sprite($sp-button-btn-arr-info-group);
  }

  .ico-t-lg {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-top: -5px;
    margin-right: 8px;
    vertical-align: top;
    background-image: url('~@/assets/img/icon_skt_b_l.svg');
    background-repeat: no-repeat;
  }

  .ico-t {
    width: 17px;
    height: 19px;
    margin-right: 0.3125rem;
    background-size: 100% auto;
  }

  .ico-t-xs {
    display: inline-block;
    width: 15px;
    height: 14px;
    margin-right: 3px;
    vertical-align: top;
    background-image: url('~@/assets/img/icon_skt_b_l.svg');
    background-size: 100%;
  }

  .voucher-label {
    position: absolute;
    top: 50px;
    right: 50px;
  }

  .btn-round {
    display: inline-block;
    height: 28px;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    color: #fff;
    text-align: center;
    background-color: #3f3fff;
    border-radius: 0.9375rem;
  }

  .msg-novoucher {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;

    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      color: #000;
    }

    .btn-round {
      height: 40px;
      padding: 0 32px;
      margin-top: 19px;
      font-size: 14px;
      line-height: 1.25;
      border-radius: 20px;
    }

    .msg-novoucher-logo {
      margin-bottom: 9px;
    }
  }

  .link-layer {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    color: rgba(24, 24, 24, 0.7);
    border-bottom: 1px solid rgba(24, 24, 24, 0.7);
  }

  .voucher-notice-list {
    margin-top: 34px;

    li {
      position: relative;
      padding-left: 9px;
      font-size: 12px;
      line-height: 22px;
      color: #999;
      letter-spacing: -0.2px;

      &::before {
        position: absolute;
        top: 7px;
        left: 0;
        width: 4px;
        height: 4px;
        content: '';
        background-color: #d8d8d8;
        border-radius: 2px;
      }
    }
  }

  .btn-underlink {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
    line-height: 1;
    text-decoration: underline;
    vertical-align: middle;
  }

  // 멤버십 할인 적용 정보
  .payment-form_membership {
    padding: 18px 0 10px;

    // 멤버십 상태
    h4 {
      display: block;
      height: auto;
      font-size: 12px;
      font-weight: 500;
      color: #555;
    }

    .membership-policy {
      position: relative;
      margin-top: 2px;

      .btn-view {
        position: static;
        display: inline-block;
        padding: 4px;
        font-size: 12px;
        color: #929292;
        text-decoration: underline;
        vertical-align: middle;
      }

      .btn-lookup {
        position: absolute;
        top: -1px;
        right: 8px;
        padding: 5px 15px 5px 5px;
        font-size: 12px;

        &::before {
          top: 3px;
          content: '';
          background-size: auto;
          @include retina-sprite($sp-icon-btn-my-ticket-arrow-red-group);
        }
      }
    }

    // 체크박스
    .form-check {
      span {
        display: inline-block;
        padding-left: 20px;
        font-size: 12px;
        color: #555;
        vertical-align: middle;

        &::before {
          top: 4px;
          background-size: auto;

          @include retina-sprite($sp-button-btn-ticket-check-normal-group);
        }
      }

      input {
        &:checked {
          + span {
            &::before {
              @include retina-sprite($sp-button-btn-ticket-check-select-group);
            }
          }
        }
      }
    }
  }

  .using-tms {
    padding: 18px 0 10px;
    font-size: 12px;
    color: #555;
  }
}

// 사용중인 이용권
.voucher-card-using {
  min-height: 210px;
  background-color: #525cfd;

  .name {
    color: #fff;
  }

  .desc {
    color: rgba(255, 255, 255, 0.9);
  }

  .card-left {
    width: 420px;
    padding-bottom: 80px;

    .etc-area {
      position: absolute;
      bottom: 48px;
      left: 60px;
    }
  }

  .card-right {
    border-color: rgba(255, 255, 255, 0.07);
  }

  .voucher-label {
    color: #fff;
    border-color: #fff;
    opacity: 0.7;
  }

  .color-blue {
    color: #fff !important;
  }

  .voucher-info-list {
    .th {
      color: rgba(255, 255, 255, 0.6);
    }

    .td {
      color: #fff;
    }
  }

  .tail-msg {
    font-size: 12px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.6);
  }

  .progress-bar {
    position: relative;
    display: block;
    width: 380px;
    margin-bottom: 8px;

    progress {
      display: block;
      width: 100%;
      height: 3px;
      color: #fff;
      appearance: none;
      background-color: rgba(255, 255, 255, 0.14);
      border: none;
      border-radius: 1.5px;

      &::-ms-fill {
        border: none;
        border-radius: 1.5px;
      }
    }

    progress[value] {
      &::-webkit-progress-bar {
        background-color: rgba(255, 255, 255, 0.14);
        border-radius: 1.5px;
      }

      &::-webkit-progress-value {
        background-color: #fff;
        border-radius: 1.5px;
      }

      &::-moz-progress-bar {
        background-color: #fff;
        border-radius: 1.5px;
      }

      &::-ms-fill {
        background-color: #fff;
        border-radius: 1.5px;
      }
    }

    .count-playing {
      position: relative;
      display: block;
      margin-top: 9px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.1;
      color: #fff;
    }

    .count-total {
      position: absolute;
      top: 12px;
      right: 0;
      font-size: 12px;
      color: #fff;
      opacity: 0.4;
    }
  }

  .link-layer {
    padding-top: 15px;
    color: #fff;
    border-color: #fff;
  }
}

// 사용대기 이용권
.voucher-card-waiting {
  .card-left {
    width: 420px;
    padding-bottom: 80px;

    .etc-area {
      position: absolute;
      bottom: 48px;
      left: 60px;
    }
  }
}

// 사용완료된 이용권
.voucher-card-complete {
  padding: 40px 60px;
  background-color: #f9f9f9;

  .name {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }

  .card-left {
    width: 420px;
  }

  .voucher-label {
    top: 55px;
  }
}

// 추천 이용권
.voucher-card-recommend {
  position: relative;
  padding: 0 0 40px;
  overflow: hidden;

  // 흰배경 케이스
  &[style*='rgb(255, 255, 255)'] {
    border: solid 1px #ededed;

    .voucher-rcommend-item {
      .name {
        color: #000;
      }

      .desc {
        color: #555;
      }

      .btn-detail {
        color: #929292;

        &::after {
          opacity: 0.5;

          @include retina-sprite($sp-button-btn-arrow-forward-nor-group);
        }
      }

      .price-box {
        .subject {
          color: #181818;
        }

        .price {
          color: #3f3fff;
        }

        .first-cost {
          color: #bdbdbd;
        }
      }

      .voucher-list-sub {
        .item {
          .subject {
            color: #181818;
          }
        }
      }
    }
  }

  // 추천 이용권 정보
  .voucher-rcommend-item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 165px;
    margin-top: 5px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;

    // 임시로 리스트 추가될 경우 간격지정
    & + .voucher-rcommend-item {
      margin-top: 40px;
    }

    .card-left {
      flex: 0 1 auto;
      width: auto;
      padding: 0;
      margin: 0;
      word-wrap: break-word;
      -webkit-box-flex: 0;
    }

    .card-right {
      flex: 0 0 auto;
      padding-left: 0;
      margin-left: 22px;
      border: 0;
      -webkit-box-flex: 0;
    }

    .item {
      margin: 0;
      text-align: left;

      .item-left {
        .subject {
          font-size: 14px;
        }
      }
    }

    .name,
    .desc {
      color: #fff;
    }

    .name {
      margin-top: 0;
      letter-spacing: -0.3px;
    }

    .price-box {
      .subject,
      .first-cost {
        margin-right: 5px;
        color: rgba(255, 255, 255, 0.7);
      }

      .price {
        color: #fff;
      }
    }

    .btn-buy {
      margin-left: 10px;
    }
  }

  .btn-detail {
    position: absolute;
    top: 50%;
    right: 40px;
    bottom: auto;
    left: auto;
    font-size: 12px;
    color: #fff;
    transform: translateY(-50%);

    &:hover {
      color: #fff;
    }

    &::after {
      display: inline-block;
      margin-top: -1px;
      vertical-align: middle;
      content: '';
      opacity: 1;

      @include retina-sprite($sp-icon-btn-ticket-arrow-white-group);
    }
  }

  .voucher-list-sub {
    > li {
      &:only-of-type {
        .item {
          -webkit-box-pack: normal;
          -ms-flex-pack: normal;
          justify-content: normal;
        }
      }
    }

    .item {
      .item-left {
        max-width: 95px;

        .subject {
          color: #fff;
        }
      }

      .item-right {
        -webkit-box-flex: 0;
        flex: 0 1 auto;
        text-align: right;
      }
    }
  }
}

// 추천 이용권 어드민 등록 이미지
.voucher-recommend-img {
  display: block;
  align-self: flex-start;
  width: 100%;
  height: auto;
  -ms-flex-item-align: start;
}

// 결합상품 리스트 (제휴)
.voucher_combi_list {
  > li {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    min-height: 95px;
    padding: 24px 45px;
    background-color: #fff;
    border-radius: 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;

    & + li {
      margin-top: 13px;

      &::before,
      &::after {
        position: absolute;
        bottom: calc(100% + 8px);
        left: 50%;
        transform: translate(-50%, 50%);
      }

      &::before {
        width: 40px;
        height: 40px;
        font-size: 0;
        content: '';
        background-color: #f4f5f8;
        border-radius: 50%;
      }

      &::after {
        font-size: 0;
        color: transparent;
        content: '결합';
        @include retina-sprite($sp-icon-plus-group);
      }
    }
  }

  .combi_logo {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 155px;
    margin-right: 20px;

    img {
      display: inline-block;
      vertical-align: top;
    }
  }

  .combi_info {
    -webkit-box-flex: 0;
    flex: 0 1 100%;
    margin-top: 5px;

    .subject,
    .desc {
      display: block;
      word-break: break-all;
    }

    .subject {
      font-size: 16px;
      font-weight: 500;
      color: #181818;
    }

    .desc {
      margin-top: 2px;
      font-size: 13px;
      color: #929292;
    }
  }
}

// 이용권 안에 이용권 리스트
.voucher-list-sub {
  > li {
    word-break: break-all;

    & + li {
      margin-top: 30px;
    }
  }

  .item {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0;

    .item-left {
      display: block;
      -webkit-box-flex: 0;
      flex: 0 1 auto;
      text-align: left;

      .subject {
        font-size: 16px;
        color: #181818;

        &__highlight {
          color: #3f3fff;
        }
      }
    }

    .item-right {
      -webkit-box-flex: 1;
      flex: 1 0 auto;
      margin-left: 10px;
    }
  }
}

// 이용권 상태 라벨
.voucher-label {
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #888;
  border: 1px solid #9c9c9c;
  border-radius: 15px;
  opacity: 0.6;
}

// 내 이용권(my)
// 이용권 정보 리스트 (my 이용권)
.voucher-info-list {
  li {
    position: relative;
    font-size: 12px;

    span {
      display: inline-block;
      line-height: 22px;

      &.th {
        min-width: 43px;
        max-width: 57px;
        margin-right: 10px;
        font-size: 12px;
        color: #999;
      }

      &.td {
        font-size: 12px;
        color: #555;

        a {
          color: inherit;
        }
      }

      .link-layer {
        position: absolute;
        top: 50%;
        right: -10px;
        padding: 0;
        margin: 0;
        transform: translateY(-50%);
      }
    }

    .text-option {
      display: inline-block;
      margin-left: 5px;
      font-size: 12px;
      vertical-align: middle;
    }

    .tail-msg {
      padding: 3px 0;
      color: #d83a4d;
    }
  }

  + .tail-msg {
    margin-top: 14px;
  }

  .btn-round {
    margin-left: 15px;
  }

  .btn-underline {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
    text-decoration: underline;
  }
}

.my-voucher-section {
  & + .my-voucher-section {
    margin-top: 40px;
  }

  .my-section-title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #333;

    a {
      &:hover {
        color: #333;
      }
    }

    .ico-arrow {
      display: inline-block;
      vertical-align: middle;
      transform: rotateX(180deg);
      @include retina-sprite($sp-icon-btn-my-ticket-arrow-up-group);
    }
  }

  // 사용완료된 이용권 열렸을 때
  &.open {
    .my-section-title {
      .ico-arrow {
        display: inline-block;
        vertical-align: middle;
        transform: rotateX(0);
        @include retina-sprite($sp-icon-btn-my-ticket-arrow-up-group);
      }
    }

    &.complete {
      .voucher-card {
        display: block;
      }
    }
  }

  // 사용완료된 이용권 section
  &.complete {
    .voucher-card {
      display: none;
    }
  }
}

// 하얀색 화살표달린 링크
.voucher-link-arrow {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  vertical-align: middle;

  &::after {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
    content: '';

    @include retina-sprite($sp-icon-btn-ticket-arrow-white-group);
  }

  // 검은색 화살표
  &.black {
    color: #181818;

    &::after {
      @include retina-sprite($sp-icon-btn-ticket-arrow-black-group);
    }
  }
}

.color-red {
  color: #e61313 !important;
}

.color-blue {
  color: #3f33ff !important;
}
