
          @import '@/assets/scss/common/color.scss';
        

@import '~@/assets/scss/common/helpers.mixins';

.volume {
  width: 100px;
  height: 3px;
  background: rgba(240, 240, 240, 0.2);
  border-radius: 1.5px;
  transition: height 0.15s ease-in;

  &__inner {
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 1.5px;
  }

  &__icon {
    color: rgba(255, 255, 255, 0.4);
  }

  &__click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &_area {
    @include respond-to(mediaquery-small) {
      display: none;
    }

    position: relative;
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;

    &:hover,
    &.hover {
      .volume {
        height: 6px;
        border-radius: 3px;
        will-change: height;

        &__inner {
          background: white;
          border-radius: 3px;
        }

        &__icon {
          color: white;
        }
      }
    }

    &__inactive {
      cursor: initial;

      &:hover {
        .volume {
          transform: initial;

          &__inner {
            background: #484848;
          }

          &__icon {
            color: #484848;
          }
        }
      }

      .volume {
        &__inner {
          background: #484848;
        }

        &__icon {
          color: #484848;
        }
      }
    }
  }
}
